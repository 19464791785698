import React, { useState, useEffect, memo, useRef } from 'react';
import '../Style/surveyForm.css';
import geolib from 'geolib';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet-async';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import Rating from '@mui/material/Rating';
import ReactGA from "react-ga4";
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpAlt from '@mui/icons-material/ThumbUpAlt';
import { AiFillStar } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import Form from 'react-bootstrap/Form';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import InfiniteScroll from 'react-infinite-scroller';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import validator from 'validator';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Tooltip from '@mui/material/Tooltip';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// import { useNavigate } from 'react-router-dom';
import { textTransform } from '@mui/system';
import logoImg from '../img/SurveySlack-Full-Logo-With-Text.png';
import { BASE_URL, API_ENDPOINTS } from '../api_folder/config';

const SurveyForm = (option) => {
  const { id } = useParams();

  const [responderId, setResponderId] = useState();

  const [responceID, setResponceID] = useState();

  useEffect(() => {
    function generateRandomId() {
      const randomNum = Math.floor(10000 + Math.random() * 90000);
      return randomNum.toString();
    }
    const responderId = generateRandomId();
    setResponceID(responderId);

    function generateRandomIdRes() {
      const randomNum = Math.floor(10000 + Math.random() * 90000);
      return randomNum.toString();
    }
    const responceID = generateRandomIdRes();
    setResponderId(responceID);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname }); // Ensure `location` is correctly referenced
  }, []); // Removed `location` dependency since it's not defined in this scope

  const [title,setTitle] = useState('');
  const [description,setDescription] = useState('');
  const [navigationStack, setNavigationStack] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [reqalertemail, setEmailAlert] = useState(false);
  const [logourl, setLogourl] = useState('');
  const [called, setCalled] = useState(false);
  const [isSurveyDetails, setSurveyDetails] = useState(false);
  const [showwelcome, setShowWelcome] = useState(false);
  const [isWelcometext, setWelcometext] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [isWelcomeVisible, setWelcomeVisible] = useState(false);
  const [showQuestion, setShowquestion] = useState(false);
  const [successscreen, setSuccessscreen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [blinkcolor, setBlinkcolor] = useState(true);
  const [checkclick, setAnsclick] = useState(false);
  const [selectMultipleImages, setSelectMultipleimg] = useState(false);
  const [selectedButton, setSelectedButton] = useState([]);
  const [dropdownval, setDropdownval] = React.useState('');
  const [filteredQuestionList, setFilterqueslist] = useState([]);
  const [slidervalue, setSlidervalue] = useState([]);
  const [slidervalue1, setSlidervalue1] = useState([]);
  const [slidervalue2, setSlidervalue2] = useState([]);
  const [ratinglength, setlengthRating] = useState();
  const [ratinglength1, setlengthRating1] = useState();
  const [ratinglabel, setRatingLabel] = useState();
  const [surveyDetails, setSurveydetails] = useState([]);
  const [contentStyle, setContentstyle] = useState([]);
  const [isrequiredemail, setReqEmail] = useState();
  const [isoptionalemail, setOptEmail] = useState(false);
  const [isWelcomesceen, setWelcomesceen] = useState(true);
  const [text, setlongAnswer] = useState([]);
  const [areaHeight, setAreaHeight] = useState('auto');
  const [backgroungImg, setBackgroundImg] = useState();
  const [value, setValue] = React.useState([]);
  const [hover, setHover] = React.useState(-1);
  const [emailid, setEmailid] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [name, setName] = useState('');
  const [isValidName, setIsValidName] = useState(true);
  const [reqalert, showReqAlert] = useState([]);
  const [isenterd, setEnterd] = useState(false);
  const [image, setImage] = useState([]);
  const [error, setError] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(null);

  // question list api
  const [queslist, setQueslist] = useState([]);
  const [selectedImages, setSelectedImg] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState(Array(filteredQuestionList.length).fill(false));
  const [secondFilterqueslist, setSecondFilterqueslist] = useState([]);
  const [isShortAnswerFilled, setIsShortAnswerFilled] = useState(false);
  const [validate, setValidate] = useState(false);
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const getid = pathname.split('/').pop();
  // const [iscompulsory,setcompulsory]=useState(false);
  useEffect(() => {
    let isActive = true;

    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?surveyid=${getid}`);

        if (response.ok) {
          const responseData = await response.json();
          console.log('Fetched data:', responseData.data);

          if (isActive) {
            // Check if component is still active
            setQueslist(responseData.data);
          }
        } else {
          console.error('Failed to fetch data: ', response.statusText);

          if (isActive) {
            // Check if component is still active
            setQueslist(false);
          }
        }
      } catch (error) {
        console.error('Error fetching question branching: ', error.message);

        if (isActive) {
          // Check if component is still active
          setQueslist(false);
        }
      }
    };

    fetchData();

    return () => {
      isActive = false; // Cleanup function to avoid setting state on an unmounted component
    };
  }, [getid]); // Add getid as a dependency to refetch data when it changes

  // console.log('currentQuestionIndex',emailid);
  // get surve id from url
  useEffect(() => {
    setFilterqueslist(queslist.filter((survey) => survey.surveyid === getid));

    const a = filteredQuestionList.filter((option) => option.question_type === 6);
    // const notApplicable = filteredQuestionList.filter((option) => option.question_type === '11');
    // console.log(notApplicable);
    const ratingdata = filteredQuestionList.filter((option) => option.question_type === 3);
    setlengthRating(ratingdata.map((data) => JSON.parse(data.answer)[0]));
    setlengthRating1(ratingdata.map((data) => JSON.parse(data.answer)[1]));
    setRatingLabel(ratingdata.map((data) => JSON.parse(data.answer)[3]));
    setSlidervalue(a.map((data) => JSON.parse(data.answer)[0]));
    setSlidervalue1(a.map((data) => JSON.parse(data.answer)[1]));
    setSlidervalue2(a.map((data) => JSON.parse(data.answer)[2]));
  }, [currentQuestionIndex, isWelcometext, showQuestion, isrequiredemail, isoptionalemail, surveyDetails]);

  const getSurveyid = localStorage.getItem('SURVEY_ID');

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`${BASE_URL}${API_ENDPOINTS.surveyEdit}?surveyid=${getid}`);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch survey details');
  //       }

  //       const responseData = await response.json();
  //       console.log('Raw JSON response:', responseData);

  //       const surveyDetails = responseData.surveyid;

  //       console.log('Survey Details:', surveyDetails);

  //       if (surveyDetails && typeof surveyDetails === 'object') {
  //         setSurveydetails(surveyDetails);
  //       } else {
  //         console.error('Unexpected survey details structure:', surveyDetails);
  //       }

  //     } catch (error) {
  //       console.error('Error fetching data:', error.message);

  //     }
  //   };

  //   fetchData();
  // }, [getid]);
  // Add `getid` to the dependency array to re-run the effect if it changes
 
  useEffect(() => {
    try {
      const endpoint = API_ENDPOINTS.surveyListGET;
      fetch(`${BASE_URL}${endpoint}`)
        .then((response) => response.json())
        .then((data) => {
          const filtersurvey = data.data.filter((survey) => survey.surveyid === parseInt(getid, 10));
          setSurveydetails(filtersurvey);
          setTitle(filtersurvey[0].survey_title);
          setDescription(filtersurvey[0].survey_description);
          console.log(filtersurvey, 'filtersurvey');
        });
    } catch (error) {
      console.log('noquestion');
    }
  }, [getid]);
  // ?
  useEffect(() => {
    const suvryimg = surveyDetails.map((data) => data.background);
    const getsurveyStyle = surveyDetails.map((data) => JSON.parse(data.fonts));
    const getsurveyDisplay = surveyDetails.map((data) => data.survey_display);
    console.log(getsurveyDisplay);
    if (getsurveyDisplay.length > 0) {
      setDisplayMode(getsurveyDisplay[0]);
    }

    const getorder = surveyDetails.map((data) => JSON.parse(data.question_order));
    const sortedArray = filteredQuestionList.sort((a, b) => {
      const aIndex = getorder[0].indexOf(a.questionid);
      const bIndex = getorder[0].indexOf(b.questionid);
      return aIndex - bIndex;
    });
    setNeworder(sortedArray);
    setContentstyle(getsurveyStyle);
    setBackgroundImg(suvryimg);
  }, [surveyDetails, showQuestion]);

  useEffect(() => {
    const fetchQuestionBranching = async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.QuestionBranching}?survey_id=${getid}`);
        if (response.ok) {
          const responseData = await response.json();
          console.log('Fetched data:', responseData.data);
          setQuestionBranchingData(responseData.data);
          // Assuming `responseData.data` is an array, set the first question ID or use logic to select the right one
          setCurrentQuestionId(responseData.data[0]?.question_id);
          setIsQuestionBranchingDataAvailable(true);
        }
      } catch (error) {
        console.error('Error fetching question branching: ', error.message);
        // Handle error here (e.g., show user a message)
        setIsQuestionBranchingDataAvailable(false);
      }
    };

    fetchQuestionBranching();
  }, [getid]);

  const [getNewOrder, setNeworder] = useState([]);
  const [displayMode, setDisplayMode] = useState('One Question at a Time');

  const SendFeedback = () => {
    startTimer();
    // setShowquestion(true);
    setShowWelcome(true);
    setOptEmail(true);
    const isAnyEmailRequired = surveyDetails.some((data) => data.email === 'Required');
    setReqEmail(isAnyEmailRequired);

    const isAnyEmailOptional = surveyDetails.some((data) => data.email === 'Optional');
    setOptEmail(isAnyEmailOptional);

    const hasOtherEmailType = surveyDetails.some((data) => data.email !== 'Required' && data.email !== 'Optional');
    if (hasOtherEmailType) {
      setShowquestion(true);
    }
  };

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);
  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
  };
  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
  };

  // seconds

  // useEffect(() => {
  //   const suvryimg = surveyDetails.map((data) => data.background);
  //   const time = surveyDetails.map((data) => data.survey_enddate);
  //   const suvryimgDate = surveyDetails.map((data) => {
  //     const utcDate = new Date(data.survey_enddate);
  //     return utcDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  //   });

  //   setBackgroundImg(suvryimg);
  //   console.log(time,suvryimgDate);

  //   const currentDate = new Date();
  //   const isExpired = surveyDetails.some((data) =>suvryimgDate < currentDate);
  //   console.log('survey is exp' ,isExpired);

  //   console.log(suvryimgDate);
  // }, [surveyDetails]);

  useEffect(() => {
    setWelcomeVisible(true);
    setTimeout(() => {
      setWelcometext(true);
    }, 1000);
  }, []);

  // const handleKeyPress = (event) => {
  //     if (event.key === 'Enter') {
  //       console.log('Enter key pressed. Toggling fullscreen.');
  //       handleNextQuestion()
  //     }
  //   };

  useEffect(() => {
    document.addEventListener('keydown', handelEnterkey);
  }, []);

  // useEffect(() => {
  //     showReqAlert(filteredQuestionList.map((option) => option.compulsory === '1'));
  // }, [currentQuestionIndex]);
  // console.log(reqalert)
  // useEffect(() => {
  //     showReqAlert(true)
  // }, [reqalert]);
  const [categoriesWithAnswers, setCategoriesWithAnswers] = useState([]);

  // const [categoriesWithAnswers, setCategoriesWithAnswers] = useState([]);
  const [arr, setArr] = useState([]);
  // const handelSetMatrixmcq = (rowid, answer, optionName, index ,questionId) => {
  //   const existingResponseIndex =  selectedResponses.findIndex((data)=>data.questionid===questionId)
  //   if (existingResponseIndex !== -1) {
  //     console.log("id found")
  //     setSelectedResponses((data) => {
  //       const updatedResponses = data.map((response, index) => {
  //               return {
  //                   ...response,
  //                   response_text: [...response.response_text, { 'key': 'value1' }]
  //               };

  //       });
  //       return updatedResponses;
  //   })

  //   } else {
  //     console.log("id not found")
  //     selectedResponses.push({
  //       response_text: [{ 'key': 'value' }],
  //       responseid: responceID,
  //       responderid: responderId,
  //       surveyid: id,
  //       questionid: questionId,
  //       respondent_email: 'test@gmail.com',
  //     });
  //   }
  // }
  const handleClicktoHomePage = () => {
    window.location.href = 'https://surveyslack.com/app';
  };

  // changed in column name and set for the multiple question
  const [responseText, setRtest] = useState([]);
  const [newResp, setNewResp] = useState([]);
  // const handelSetMatrixmcq = (rowid, answer, optionName, index, questionId, QuestionNumber, option) => {
  //   setSelectedResponses((prevResponses) => {
  //     const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);

  //     if (existingResponseIndex !== -1) {
  //       const updatedResponses = prevResponses.map((response) => {
  //         if (response.questionid === questionId) {
  //           const foundObject = answer.find((item) => item.id === rowid);
  //           responseText.push({ [foundObject.rowValue]: optionName[index].columnValue });
  //           const updatedResponses = [...selectedResponses];
  //           updatedResponses[existingResponseIndex].response_text = JSON.stringify(responseText);
  //           setSelectedResponses(updatedResponses);
  //         }
  //         return response;
  //       });

  //       return updatedResponses;
  //     }

  //     const foundObject = answer.find((item) => item.id === rowid);
  //     responseText.push({ [foundObject.rowValue]: optionName[index].columnValue });
  //     return [
  //       ...prevResponses,
  //       {
  //         response_text: JSON.stringify(responseText),
  //         responseid: responceID,
  //         responderid: responderId,
  //         surveyid: id,
  //         questionid: questionId,
  //         respondent_email: emailid || name || 'anonymous',
  //       },
  //     ];
  //   });
  // };
  // final
  // const handelSetMatrixmcq = (rowid, answer, optionName, index, questionId, QuestionNumber, option) => {
  //   setSelectedResponses((prevResponses) => {
  //     const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);
  //     const updatedResponses = [...prevResponses];
  //     let responseText = [];
  //     setIsAnsSeleted([]);
  //     setIsAnsSeletedforMatrixRadio([]);

  //     const foundObject = answer.find((item) => item.id === rowid);
  //     const newRowEntry = { [foundObject.rowValue]: optionName[index].columnValue };

  //     if (existingResponseIndex !== -1) {
  //       const existingResponse = JSON.parse(updatedResponses[existingResponseIndex].response_text);
  //       responseText = existingResponse.filter((entry) => !Object.keys(entry).includes(foundObject.rowValue));
  //     }

  //     responseText.push(newRowEntry);

  //     if (existingResponseIndex !== -1) {
  //       updatedResponses[existingResponseIndex].response_text = JSON.stringify(responseText);
  //     } else {
  //       updatedResponses.push({
  //         response_text: JSON.stringify(responseText),
  //         responseid: responceID,
  //         responderid: responderId,
  //         surveyid: id,
  //         questionid: questionId,
  //         respondent_email: emailid || name || 'anonymous',
  //       });
  //     }

  //     // Check if at least one value is selected and update isCompulsoryQuestionAnswered
  //     const answered = responseText.length > 0;
  //     setIsCompulsoryQuestionAnswered((prev) => {
  //       const updated = [...prev];
  //       updated[QuestionNumber] = answered;
  //       return updated;
  //     });

  //     // Enable the next button if the question is compulsory and answered
  //     if (option.compulsory === '1') {
  //       setIsShortAnswerFilled(answered);
  //     }

  //     return updatedResponses;
  //   });
  // };

  const [selectedRadio, setSelectedRadio] = useState({});
  const handleClearSelections = () => {
    // Clear selected responses
    setSelectedResponses([]);

    // Clear any validation alerts or indicators
    setIsAnsSeleted([]);
    setIsAnsSeletedforMatrixRadio([]);

    // Reset compulsory question status
    setIsCompulsoryQuestionAnswered((prev) => [...prev].fill(false));

    // Clear selected radio buttons
    setSelectedRadio({});
  };

  const handelSetMatrixmcq = (rowid, answer, optionName, index, questionId, QuestionNumber, option) => {
    setSelectedResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);
      const updatedResponses = [...prevResponses];
      let responseText = [];
      setIsAnsSeleted([]);
      setIsAnsSeletedforMatrixRadio([]);

      const foundObject = answer.find((item) => item.id === rowid);
      const newRowEntry = { [foundObject.rowValue]: optionName[index].columnValue };

      if (existingResponseIndex !== -1) {
        const existingResponse = JSON.parse(updatedResponses[existingResponseIndex].response_text);
        responseText = existingResponse.filter((entry) => !Object.keys(entry).includes(foundObject.rowValue));
      }

      // If newRowEntry is not present (indicating unselect), remove it from responseText
      if (!newRowEntry[foundObject.rowValue]) {
        responseText = responseText.filter((entry) => !Object.keys(entry).includes(foundObject.rowValue));
      } else {
        responseText.push(newRowEntry);
      }

      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].response_text = JSON.stringify(responseText);
      } else {
        updatedResponses.push({
          response_text: JSON.stringify(responseText),
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        });
      }

      // Check if at least one value is selected and update isCompulsoryQuestionAnswered
      const answered = responseText.length > 0;
      setIsCompulsoryQuestionAnswered((prev) => {
        const updated = [...prev];
        updated[QuestionNumber] = answered;
        return updated;
      });

      // Enable the next button if the question is compulsory and answered
      if (option.compulsory === 1) {
        setIsShortAnswerFilled(answered);
      }

      // Update selected radio state
      setSelectedRadio((prevSelected) => ({
        ...prevSelected,
        [questionId]: {
          ...prevSelected[questionId],
          [rowid]: newRowEntry[foundObject.rowValue] ? index : null, // Update with index or null for unselect
        },
      }));

      return updatedResponses;
    });
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleClearSelectionsCheckBox = () => {
    setSelectedResponses([]);
    setIsAnsSeleted([]);
    setIsCompulsoryQuestionAnswered((prev) => prev.map(() => false));
    setSelectedCheckboxes([]); // Clear selected checkboxes state
  };
  const SelectMatrixCheckbox = (rowid, answer, optionName, index, questionId, QuestionNumber) => {
    setSelectedResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);
      setIsAnsSeleted([]);

      const foundObjectcheckbox = answer.find((item) => item.id === rowid);
      const newRowValue = foundObjectcheckbox.rowValue;
      const newColumnValue = optionName[index].columnValue;

      let responseText = [];
      if (existingResponseIndex !== -1) {
        responseText = JSON.parse(prevResponses[existingResponseIndex].response_text || '[]');
      }

      const existingRowIndex = responseText.findIndex(
        (item) => item[newRowValue] && item[newRowValue].includes(newColumnValue)
      );

      if (existingRowIndex !== -1) {
        // Deselect checkbox
        responseText[existingRowIndex][newRowValue] = responseText[existingRowIndex][newRowValue].filter(
          (value) => value !== newColumnValue
        );
        if (responseText[existingRowIndex][newRowValue].length === 0) {
          responseText.splice(existingRowIndex, 1);
        }
        setSelectedCheckboxes((prev) => prev.filter((item) => item !== `${rowid}-${index}`)); // Remove from selectedCheckboxes state
      } else {
        // Select checkbox
        responseText.push({ [newRowValue]: [newColumnValue] });
        setSelectedCheckboxes((prev) => [...prev, `${rowid}-${index}`]); // Add to selectedCheckboxes state
      }

      const updatedResponseText = JSON.stringify(responseText);

      if (existingResponseIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingResponseIndex].response_text = updatedResponseText;
        return updatedResponses;
      }

      // Add new response
      return [
        ...prevResponses,
        {
          response_text: updatedResponseText,
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        },
      ];
    });

    // Update the isCompulsoryQuestionAnswered state for the current question
    setIsCompulsoryQuestionAnswered((prev) => {
      const updated = [...prev];
      updated[QuestionNumber] = true; // Set to true after a selection is made
      return updated;
    });
  };

  const [isAnser, setIsanswer] = useState(false);
  const [matrixMcq, setMatrixMcq] = useState(false);
  const [martixCheckbox, setMatrixCheckbox] = useState(false);
  const [isAnser1, setIsanswer1] = useState(true);
  const [isAnser2, setIsanswer2] = useState(true);
  const [isAnser3, setIsanswer3] = useState(false);
  const [isAnser4, setIsanswer4] = useState(false);
  const [isAnser5, setIsanswer5] = useState(false);
  const [isAnser6, setIsanswer6] = useState(false);
  const [isAnser7, setIsanswer7] = useState(false);
  const [isAnser8, setIsanswer8] = useState(false);
  const [isAnser9, setIsanswer9] = useState(false);
  const [isAnser10, setIsanswer10] = useState(false);
  const [isAnser11, setIsanswer11] = useState(false);
  // const [isAnser9, setIsanswer9] = useState(false);
  const [slidervalueAnswer, setSlidervalueAnswer] = useState('');
  const [shortansvalue, setShortAnsvalue] = useState('');
  const [isCompulsoryQuestionAnswered, setIsCompulsoryQuestionAnswered] = useState([]);
  const [isAnswerSelected, setIsAnswerSelected] = useState([]);

  function isAnswerfunction() {
    const array = getNewOrder.map((data) => data.question_type);
    // const targetQuestionType = "1";
    const targetQuestionType = currentQuestionIndex + 1;
    const targetIndex = array.indexOf(targetQuestionType);
    // setOkButtonClicked((preClicks)=>{
    const newClick = [...okButtonClicked];
    if (newClick[targetIndex] === true) {
      console.log(`The ok Button Clicked value for question type ${targetQuestionType} is true.`);
    } else {
      setShortAnsvalue('');
      setlongAnswer('');
    }
    // })
  }
  const [iscompulery, setCompulsry] = useState([]);

  const handelpostRes = async (e) => {
    e.preventDefault();
    const lastQuestionIndex = filteredQuestionList.length - 1;
    const lastQuestion = getNewOrder[lastQuestionIndex];

    // Flag to track if there are unanswered compulsory questions
    let hasUnansweredCompulsoryQuestions = false;

    // Clear all error messages before checking for unanswered compulsory questions
    clearAllErrorMessages();

    // Iterate through questions to check if any compulsory question is unanswered
    for (let i = 0; i < getNewOrder.length; i += 1) {
      const question = getNewOrder[i];
      const questionId = question.questionid;

      // Check if the question is compulsory
      if (question.compulsory === 1) {
        // Check if the question is answered
        const isAnswered = selectedResponses.some((response) => response.questionid === questionId);
        if (!isAnswered) {
          hasUnansweredCompulsoryQuestions = true;
          setErrorMessageForQuestionType(question.question_type);
          break; // No need to check further if an unanswered compulsory question is found
        }
      }
    }

    // If there are unanswered compulsory questions, return without posting the response
    if (hasUnansweredCompulsoryQuestions) {
      return;
    }

    stopTimer();

    try {
      // Post otherres data
      const endpoint = API_ENDPOINTS.responderdata;
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(otherres),
      });

      if (response.status === 200) {
        console.log('Survey Res. Data posted successfully');
        console.log(otherres);
      }
    } catch (err) {
      console.log(err);
      // Handle error
    }

    try {
      // Post selectedResponses data
      const convertData = JSON.stringify(selectedResponses);
      const endpoint = API_ENDPOINTS.saveRes;
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: convertData,
      });

      if (response.status === 200) {
        console.log('Survey Res. Data posted successfully');
        console.log(convertData);
        setSuccessscreen(true);
      }
    } catch (err) {
      console.log(err);
      // Handle error
    }
  };

  // Function to clear all error messages
  const clearAllErrorMessages = () => {
    setIsAnsSeleted([]);
    setIsAnsSeletedLongAns([]);
    setIsAnsSeletedShortAns([]);
    setIsAnsSeletedrating([]);
    setIsAnsSeletedDropdown([]);
    setIsAnsSeletedLinear([]);
    setIsAnsSeletedRangeSlider([]);
    setIsAnsSeletedDateTime([]);
    setIsAnsSeletedforfile([]);
    setIsAnsSeletedImageChoice([]);
    setIsAnsSeletedforMatrixRadio([]);
    setIsAnsSeletedmultipleChoice([]);
    // Add more state clearing if necessary for other question types
  };

  // Function to set error message based on question type
  const setErrorMessageForQuestionType = (questionType) => {
    switch (questionType) {
      case '1':
        setIsAnsSeletedLongAns([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '2':
        setIsAnsSeletedShortAns([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '3':
        setIsAnsSeletedrating([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '4':
        setIsAnsSeletedDropdown([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '5':
        setIsAnsSeletedLinear([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '6':
        setIsAnsSeletedRangeSlider([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '7':
        setIsAnsSeletedDateTime([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '8':
        setIsAnsSeletedforfile([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '9':
        setIsAnsSeletedImageChoice([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '10':
        setIsAnsSeletedforMatrixRadio([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '11':
        setIsAnsSeleted([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      case '12':
        setIsAnsSeletedmultipleChoice([{ alerttext1: 'Oops!', alerttext2: 'Please answer all compulsory questions' }]);
        break;
      // Add cases for other question types here
      default:
        break;
    }
  };

  const handleNextQuestionBranching = (e) => {
    e.preventDefault();

    if (!getNewOrder || !Array.isArray(getNewOrder)) {
      console.error('getNewOrder is not properly defined or not an array');
      return false;
    }

    const currentQuestion = getNewOrder[currentQuestionIndex];

    if (!currentQuestion) {
      console.error('Current question not found in getNewOrder');
      return false;
    }

    console.log('Current Question:', currentQuestion);

    if (questionBranchingData.length > 0) {
      const matchingQuestion = questionBranchingData.find(
        (question) => question.question_id.toString() === currentQuestion.questionid.toString()
      );
      console.log('Matching Question:', matchingQuestion);

      if (matchingQuestion) {
        const { action: nextQuestionAction, action_value: nextQuestionActionValue } = matchingQuestion;
        console.log('Next Question Action:', nextQuestionAction);
        console.log('Next Question Action Value:', nextQuestionActionValue);

        if (nextQuestionAction === 'go to') {
          const nextQuestionIndex = getNewOrder.findIndex(
            (question) => question.questionid.toString() === nextQuestionActionValue.toString()
          );
          console.log('Next Question Index:', nextQuestionIndex);
          console.log('getNewOrder Contents:', getNewOrder);

          if (nextQuestionIndex !== -1) {
            setNavigationStack([...navigationStack, currentQuestionIndex]);
            setCurrentQuestionIndex(nextQuestionIndex);
            console.log('Navigating to next question index:', nextQuestionIndex);
            return true; // Branching handled
          }

          console.error('Next question not found in getNewOrder');
          return false;
        }

        if (nextQuestionAction === 'skip') {
          const questionIndexToSkip = getNewOrder.findIndex(
            (question) => question.questionid.toString() === nextQuestionActionValue.toString()
          );
          if (questionIndexToSkip !== -1) {
            const newQuestionIndex = questionIndexToSkip + 1;
            if (newQuestionIndex < getNewOrder.length) {
              setNavigationStack([...navigationStack, currentQuestionIndex]);
              setCurrentQuestionIndex(newQuestionIndex);
              console.log('Skipping to next question index:', newQuestionIndex);
            } else {
              setSuccessscreen(true);
              console.log('Setting success screen');
            }
            return true; // Branching handled
          }

          console.error('Question to skip not found in getNewOrder');
          return false;
        }

        if (nextQuestionAction === 'End survey') {
          setSuccessscreen(true);
          console.log('Ending survey and showing success screen');
          return true; // Branching handled
        }

        console.error('Invalid action');
        return false;
      }

      console.error('Matching question not found in questionBranchingData');
      return false;
    }

    console.log('Question branching data is not yet available');

    // No branching handled
    // Proceed to the next question or finish if it's the last one
    if (currentQuestionIndex < filteredQuestionList.length - 1) {
      setNavigationStack([...navigationStack, currentQuestionIndex]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setSuccessscreen(true);
    }
    return false; // Indicate no branching was handled
  };

  // const handleNextQuestionBranching = (e) => {
  //   e.preventDefault();

  //   if (!getNewOrder || !Array.isArray(getNewOrder)) {
  //     console.error('getNewOrder is not properly defined or not an array');
  //     return false;
  //   }

  //   const currentQuestion = getNewOrder[currentQuestionIndex];

  //   if (questionBranchingData.length > 0) {
  //     const matchingQuestion = questionBranchingData.find((question) => question.question_id.toString() === currentQuestion.questionid.toString());
  //     console.log("Matching Question:", matchingQuestion);
  //     if (matchingQuestion) {
  //       const { action: nextQuestionAction, action_value: nextQuestionActionValue } = matchingQuestion;
  //       console.log("Next Question Action:", nextQuestionAction);
  //       console.log("Next Question Action Value:", nextQuestionActionValue);

  //       if (nextQuestionAction === 'go to') {
  //         const nextQuestionIndex = getNewOrder.findIndex((question) => question.questionid.toString() === nextQuestionActionValue);
  //         if (nextQuestionIndex !== -1) {
  //           setNavigationStack([...navigationStack, currentQuestionIndex]);
  //           setCurrentQuestionIndex(nextQuestionIndex);
  //           console.log("Setting next question index to:", nextQuestionIndex);
  //           return true; // Branching handled
  //         }
  //         console.error('Next question not found');
  //         return false;
  //       }

  //       if (nextQuestionAction === 'skip') {
  //         const questionIndexToSkip = getNewOrder.findIndex((question) => question.questionid.toString() === nextQuestionActionValue);
  //         if (questionIndexToSkip !== -1) {
  //           const newQuestionIndex = questionIndexToSkip + 1;
  //           if (newQuestionIndex < getNewOrder.length) {
  //             setNavigationStack([...navigationStack, currentQuestionIndex]);
  //             setCurrentQuestionIndex(newQuestionIndex);
  //             console.log("Setting next question index to:", newQuestionIndex);
  //           } else {
  //             setSuccessscreen(true);
  //             console.log("Setting success screen");
  //           }
  //           return true; // Branching handled
  //         }
  //         console.error('Question to skip not found');
  //         return false;
  //       }

  //       if (nextQuestionAction === 'End survey') {
  //         setSuccessscreen(true);
  //         console.log("Setting success screen");
  //         return true; // Branching handled
  //       }

  //       console.error('Invalid action');
  //       return false;
  //     }

  //     console.error('Matching question not found in questionBranchingData');
  //     return false;
  //   }

  //   console.log('Question branching data is not yet available');

  //   // No branching handled
  //   // Proceed to the next question or finish if it's the last one
  //   if (currentQuestionIndex < filteredQuestionList.length - 1) {
  //     setNavigationStack([...navigationStack, currentQuestionIndex]);
  //     setCurrentQuestionIndex(currentQuestionIndex + 1);
  //   } else {
  //     setSuccessscreen(true);
  //   }
  //   return false; // Indicate no branching was handled
  // };

  const handleNextQuestion = (e) => {
    e.preventDefault(); // Prevent default form submission
    setRtest([]);
    setFadeIn(false);
    const currentQuestion = getNewOrder[currentQuestionIndex];

    // Check if compulsory question is answered
    if (currentQuestion.compulsory === 1 && !isCompulsoryQuestionAnswered[currentQuestionIndex]) {
      setIsAnsSeleted([{ alerttext1: 'Oops !', alerttext2: 'Please answer the compulsory question' }]);
      console.log('Error message set:', isAnsSeleted);
      return;
    }

    // Clear any previous error messages
    setIsAnsSeleted([]);
    console.log('Error message cleared:', isAnsSeleted);

    setSurveyDetails(false);
    isAnswerfunction();

    // Proceed to the next question or finish if it's the last one
    if (currentQuestionIndex < filteredQuestionList.length - 1) {
      // Push the current question index to the navigation stack
      setNavigationStack([...navigationStack, currentQuestionIndex]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setSuccessscreen(true);
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();
    setRtest([]);
    setSurveyDetails(false);
    isAnswerfunction();
    setFadeIn(false);

    if (isenterd === true) {
      showReqAlert(false);
    }

    if (navigationStack.length > 0) {
      const prevIndex = navigationStack.pop();
      setNavigationStack([...navigationStack]);
      setCurrentQuestionIndex(prevIndex);
      console.log('Navigating to previous question:', prevIndex);
    } else if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      console.log('Navigating to previous sequential question');
    }
  };

  // const questioncssanimate = {
  //     animation: `fadeIn${fadeIn ? 'FromTop' : 'FromBottom'} 0.5s ease`,
  // }

  const ReqAlert = ({ validator }) => (
    <>
      {validator.map((data, index) => (
        <Alert key={index} className="requiredalert mt-3" severity="error">
          <strong>{data.alerttext1}</strong>
          {data.alerttext2}
        </Alert>
      ))}
    </>
  );
  ReqAlert.propTypes = {
    validator: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      })
    ),
  };

  const checkEmail = [{ alerttext1: '', alerttext2: 'Please enter valid email id ' }];
  const isExpireSurvey = [{ alerttext1: '', alerttext2: 'Survey is Expired' }];
  const [isAnsSeleted, setIsAnsSeleted] = useState([]);
  const [isAnsSeletedShortAns, setIsAnsSeletedShortAns] = useState([]);
  const [isAnsSeletedLongAns, setIsAnsSeletedLongAns] = useState([]);
  const [isAnsSeletedmultipleChoice, setIsAnsSeletedmultipleChoice] = useState([]);
  const [isAnsSeletedDropdown, setIsAnsSeletedDropdown] = useState([]);
  const [isAnsSeletedrating, setIsAnsSeletedrating] = useState([]);
  const [isAnsSeletedLinear, setIsAnsSeletedLinear] = useState([]);
  const [isAnsSeletedRangeSlider, setIsAnsSeletedRangeSlider] = useState([]);
  const [isAnsSeletedDateTime, setIsAnsSeletedDateTime] = useState([]);
  const [isAnsSeletedImageChoice, setIsAnsSeletedImageChoice] = useState([]);
  const [isAnsSeletedforMatrixRadio, setIsAnsSeletedforMatrixRadio] = useState([]);
  const [questionBranchingData, setQuestionBranchingData] = useState([]);
  const [isAnsSeletedforfile, setIsAnsSeletedforfile] = useState([]);
  const [isQuestionBranchingDataAvailable, setIsQuestionBranchingDataAvailable] = useState(false);

  const CustomButton = ({ btname }) => (
    <div className=" text-start p-0 mt-3">
      {btname &&
        btname.map((item, index) => (
          <Button
            disabled={item.isdisable}
            style={styleObject}
            key={index}
            onClick={item.btnfunction}
            className="btnclass"
            variant="contained"
          >
            {item.text}
            <span>&nbsp;{item.icon}</span>
          </Button>
        ))}
    </div>
  );

  const hadelCheckmail = () => {
    const isvalid = validator.isEmail(emailid);
    if (!isvalid) {
      setEmailAlert(true);
    } else {
      setEmailAlert(false);
      setTimeout(() => {
        setShowquestion(true);
        setReqEmail(false);
      }, 500);
      setEmailAlert(false);
    }
  };
  const hadelCheckname = () => {
    setShowquestion(true);
    setOptEmail(false);
    // setTimeout(() => {
    //   setShowquestion(true);
    //   setReqEmail(false);
    // }, 500);
  };

  const [isanssubmit, setAnsSubmit] = useState();

  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [okButtonClicked, setOkButtonClicked] = useState(Array(filteredQuestionList.length));

  const handelSubmitAnswer = () => {
    setOkButtonClicked((prevClicks) => {
      const newClicks = [...prevClicks];
      newClicks[currentQuestionIndex] = true;
      return newClicks;
    });
    // Handle next question only if displayMode is empty
    if (displayMode === 'One Question at time') {
      handleNextQuestion();
    }

    setAnsSubmit(true);
  };

  const handelWelcomeScreewn = (e) => {
    e.preventDefault();
    setSurveyDetails(true);
    setWelcomesceen(false);
  };

  useEffect(() => {
    console.log('New Clicks:', okButtonClicked);
  }, [okButtonClicked]);

  const okbtn = [{ btnfunction: handelSubmitAnswer, text: 'ok', icon: <CheckIcon /> }];
  const nextBtn = [{ btnfunction: handelWelcomeScreewn, text: 'Next', icon: <CheckIcon /> }];
  const SubmitEmailid = [{ btnfunction: hadelCheckmail, text: 'Submit', icon: <CheckIcon /> }];
  const Submitname = [{ btnfunction: hadelCheckname, text: 'Submit', icon: <CheckIcon /> }];

  CustomButton.propTypes = {
    btname: PropTypes.arrayOf(
      PropTypes.shape({
        btnfunction: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
      })
    ),
  };

  const nextbtn = [{ btnfunction: handleNextQuestion, text: 'Next' }];
  const sendbtn = [{ btnfunction: handelpostRes, text: 'Finish' }];
  const prevbtn = [
    {
      btnfunction: handlePrev,
      text: 'Prev',
      isdisable: currentQuestionIndex === 0,
    },
  ];

  const click = { color: 'white', border: ' 2px solid white' };
  const noclick = { color: contentStyle?.[0]?.[1] || 'loading....' };

  const handelselectanswer = (questionIndex, answerIndex) => {
    showReqAlert(false);
    setIsanswer2(true);
    setBlinkcolor(true);
    setSelectedAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = answerIndex;
      return newAnswers;
    });
    setTimeout(() => {
      setBlinkcolor(false);
    }, 1500);
  };

  // linear scale code
  const getButtonBackgroundColor = (buttonNumber) => (selectedButton === buttonNumber ? '#ffffff' : '#0000004a');
  const coloronclick = (buttonNumber) => (selectedButton === buttonNumber ? 'black' : contentStyle?.[0]?.[1]);

  const handelLinearScale = (buttonNumber, QuestionNumber) => {
    showReqAlert(false);
    setIsanswer1(false);
    setBlinkcolor(true);
    setSelectedButton((cloneArray) => {
      const newitem = [...cloneArray];
      newitem[QuestionNumber] = buttonNumber;
      return newitem;
    });

    setTimeout(() => {
      setBlinkcolor(false);
    }, 1500);
  };
  const linearScale = (data, QuestionNumber) => {
    const buttons = [];
    // eslint-disable-next-line no-plusplus
    for (let i = data[0]; i <= data[1]; i++) {
      buttons.push(
        <button
          type="button"
          key={i}
          style={{
            backgroundColor: getButtonBackgroundColor(i),
            border: '2px solid black',
            padding: '5px 25px',
            color: coloronclick(i),
          }}
          className={`scale-button2 ${selectedButton === i ? `${blinkcolor ? 'optionBlinkcss' : ''} selected` : ''}`}
          onClick={() => handelLinearScale(i, QuestionNumber)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  // range slider code
  const handleSliderChange = (value, QuestionNumber, questionId) => {
    setCalled(true);
    setSlidervalueAnswer((prevValues) => ({
      ...prevValues,
      [QuestionNumber]: value,
    }));
    setIsAnsSeleted([]);
    setIsAnsSeletedRangeSlider([]);

    setSelectedResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      const existingResponseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].response_text = value.toString(); // Update response text with selected value
      } else {
        updatedResponses.push({
          response_text: value.toString(), // Set response text with selected value
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        });
      }

      return updatedResponses;
    });

    setIsCompulsoryQuestionAnswered((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = true;
      return newArr;
    });

    showReqAlert(false);

    setSelectedResponses((prevResponses) => {
      const currentQuestionBranching = questionBranchingData.find(
        (q) => q.question_id.toString() === questionId.toString()
      );

      if (currentQuestionBranching) {
        try {
          const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
          const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
          const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

          // Get the selected answer value from the latest responses
          const selectedAnswer =
            prevResponses.find((response) => response.questionid === questionId)?.response_text || '';

          console.log('Selected Answer:', selectedAnswer); // Log selected answer

          // Initialize validity check
          let isValid = false;

          // Apply conditions based on logical operators
          switch (JSON.stringify(logicalOperators)) {
            case '[""]': {
              const [text1] = answerTexts; // Single text
              const condition1 = answerConditions[0];

              // Check conditions based on the selected answer
              isValid =
                (condition1 === 'Is' && selectedAnswer === text1) ||
                (condition1 === 'Is not' && selectedAnswer !== text1);
              break;
            }

            case '["","OR"]': {
              const [text1, text2] = answerTexts; // Two texts
              const [condition1, condition2] = answerConditions;

              // Check conditions based on the selected answer
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswer === text1) ||
                (condition1 === 'Is not' && selectedAnswer !== text1);
              const isSecondConditionValid =
                (condition2 === 'Is' && selectedAnswer === text2) ||
                (condition2 === 'Is not' && selectedAnswer !== text2);

              isValid = isFirstConditionValid || isSecondConditionValid;
              break;
            }

            case '["","OR","OR"]': {
              const [text1, text2, text3] = answerTexts; // Three texts
              const [condition1, condition2, condition3] = answerConditions;

              // Check conditions based on the selected answer
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswer === text1) ||
                (condition1 === 'Is not' && selectedAnswer !== text1);
              const isSecondConditionValid =
                (condition2 === 'Is' && selectedAnswer === text2) ||
                (condition2 === 'Is not' && selectedAnswer !== text2);
              const isThirdConditionValid =
                (condition3 === 'Is' && selectedAnswer === text3) ||
                (condition3 === 'Is not' && selectedAnswer !== text3);

              isValid = isFirstConditionValid || isSecondConditionValid || isThirdConditionValid;
              break;
            }

            default:
              isValid = false; // Default to false if no case matches
          }

          // Log the validation status before setting it
          console.log('Validation Status:', isValid);

          // Update validate state
          setValidate(isValid);

          // Trigger the action values if the condition is met
          if (isValid) {
            console.log('Triggering action values for question', questionId);
            // Add your logic to handle the action here
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      } else {
        console.log('No branching data found for question ID:', questionId);
      }

      return prevResponses;
    });
  };

  // rating code
  const labels =
    ratinglabel && ratinglabel.length > 0
      ? ratinglabel.reduce((acc, labelArray, index) => {
          labelArray.forEach((label, subIndex) => {
            acc[index + subIndex + 1] = label;
          });
          return acc;
        }, {})
      : {};

  // dropdown code
  const [isCompulsoryDropdownAnswered, setIsCompulsoryDropdownAnswered] = useState([]);

  // const handelChangedropdown = (QuestionNumber, selectedvalue, questionId) => {
  //   setDropdownval((prevAnswer) => {
  //     const newAnswer = [...prevAnswer];
  //     newAnswer[QuestionNumber] = selectedvalue; // Update selected value
  //     return newAnswer;
  //   });
  //   showReqAlert(false);
  //   setIsAnsSeleted([]);
  //   setIsAnsSeletedDropdown([]);
  //   // Update the isCompulsoryQuestionAnswered state for the current question
  //   setIsCompulsoryQuestionAnswered((prev) => {
  //     const updated = [...prev];
  //     updated[QuestionNumber] = selectedvalue !== 'None'; // Set to true if a value other than "None" is selected
  //     return updated;
  //   });

  //   setSelectedResponses((prevResponses) => {
  //     const updatedResponses = [...prevResponses];
  //     const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

  //     const newResponse = {
  //       response_text: selectedvalue,
  //       responseid: responceID,
  //       responderid: responderId,
  //       surveyid: id,
  //       questionid: questionId,
  //       respondent_email: emailid || name || 'anonymous',
  //     };

  //     if (responseIndex !== -1) {
  //       // Update existing response
  //       updatedResponses[responseIndex] = newResponse;
  //     } else {
  //       // Add new response
  //       updatedResponses.push(newResponse);
  //     }

  //     return updatedResponses;

  //   });

  // };
  // const handelChangedropdown = (QuestionNumber, selectedvalue, questionId) => {
  //   // Update dropdown values
  //   setDropdownval((prevAnswer) => {
  //     const newAnswer = [...prevAnswer];
  //     newAnswer[QuestionNumber] = selectedvalue; // Update selected value
  //     return newAnswer;
  //   });

  //   // Clear alert and selection states
  //   showReqAlert(false);
  //   setIsAnsSeleted([]);
  //   setIsAnsSeletedDropdown([]);

  //   // Update the isCompulsoryQuestionAnswered state
  //   setIsCompulsoryQuestionAnswered((prev) => {
  //     const updated = [...prev];
  //     updated[QuestionNumber] = selectedvalue !== 'None'; // True if a value other than "None" is selected
  //     return updated;
  //   });

  //   // Update the selected responses
  //   setSelectedResponses((prevResponses) => {
  //     const updatedResponses = [...prevResponses];
  //     const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

  //     const newResponse = {
  //       response_text: JSON.stringify([selectedvalue]), // Ensure this is an array
  //       responseid: responceID,
  //       responderid: responderId,
  //       surveyid: id,
  //       questionid: questionId,
  //       respondent_email: emailid || name || 'anonymous',
  //     };

  //     if (responseIndex !== -1) {
  //       // Update existing response
  //       updatedResponses[responseIndex] = newResponse;
  //     } else {
  //       // Add new response
  //       updatedResponses.push(newResponse);
  //     }

  //     console.log("Updated Responses:", updatedResponses); // Log updated responses

  //     return updatedResponses;
  //   });

  //   // Validate based on questionBranchingData
  //   // Use a callback function to ensure the latest state is used
  //   setSelectedResponses((newResponse) => {
  //     const currentQuestionBranching = questionBranchingData.find(q => q.question_id === questionId);

  //     if (currentQuestionBranching) {
  //       const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
  //       const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
  //       const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

  //       // Get selected answers from the latest responses
  //       const selectedAnswers = JSON.parse(newResponse.find(response => response.questionid === questionId)?.response_text || '[]');
  //       console.log("Selected Answers:", selectedAnswers); // Log selected answers

  //       // Evaluate conditions
  //       let isValid = false; // Default to false and then check conditions

  //       // Apply conditions based on logical operators
  //       switch (JSON.stringify(logicalOperators)) {
  //         case '["","OR"]': {
  //           const [text1, text2] = answerTexts; // Two texts
  //           const condition1 = answerConditions[0];
  //           const condition2 = answerConditions[1];

  //           // Check conditions based on the selected answers
  //           const isFirstConditionValid = (condition1 === "Is" && selectedAnswers.includes(text1))
  //             || (condition1 === "Is not" && !selectedAnswers.includes(text1));
  //           const isSecondConditionValid = (condition2 === "Is" && selectedAnswers.includes(text2))
  //             || (condition2 === "Is not" && !selectedAnswers.includes(text2));

  //           // Valid if any condition is valid
  //           isValid = isFirstConditionValid || isSecondConditionValid;

  //           console.log("Condition 1 Valid:", isFirstConditionValid);
  //           console.log("Condition 2 Valid:", isSecondConditionValid);
  //           console.log("Overall Valid:", isValid);

  //           break;
  //         }

  //         case '["","OR","OR"]': {
  //           const [text1, text2,text3] = answerTexts; // Two texts
  //           const condition1 = answerConditions[0];
  //           const condition2 = answerConditions[1];
  //           const condition3 = answerConditions[2];

  //           // Check conditions based on the selected answers
  //           const isFirstConditionValid = (condition1 === "Is" && selectedAnswers.includes(text1))
  //             || (condition1 === "Is not" && !selectedAnswers.includes(text1));
  //           const isSecondConditionValid = (condition2 === "Is" && selectedAnswers.includes(text2))
  //             || (condition2 === "Is not" && !selectedAnswers.includes(text2));
  //           const isThirdConditionValid =(condition3 === "Is" && selectedAnswers.includes(text3))
  //           || (condition3 === "Is not" && !selectedAnswers.includes(text3));

  //           // Valid if any condition is valid
  //           isValid = isFirstConditionValid || isSecondConditionValid || isThirdConditionValid;

  //           console.log("Condition 1 Valid:", isFirstConditionValid);
  //           console.log("Condition 2 Valid:", isSecondConditionValid);
  //           console.log("Condition 3 Valid:", isThirdConditionValid);
  //           console.log("Overall Valid:", isValid);

  //           break;
  //         }

  //         case '[""]': {
  //           const [text1] = answerTexts; // Two texts
  //           const condition1 = answerConditions[0];

  //           // Check conditions based on the selected answers
  //           const isFirstConditionValid = (condition1 === "Is" && selectedAnswers.includes(text1))
  //             || (condition1 === "Is not" && !selectedAnswers.includes(text1));

  //           // Valid if any condition is valid
  //           isValid = isFirstConditionValid ;

  //           console.log("Condition 1 Valid:", isFirstConditionValid);
  //           console.log("Overall Valid:", isValid);

  //           break;
  //         }
  //         // Handle other cases if needed
  //         default:
  //           isValid = false; // Default to false if no case matches
  //       }

  //       setValidate(isValid);
  //       console.log("setValidate", isValid);

  //       // Trigger the action values if the condition is met
  //       if (isValid) {
  //         console.log("Triggering action values for question", questionId);
  //         // Add your logic to handle the action here
  //       }
  //     }
  //      // Return the state as is for setSelectedResponses
  //   });
  // };

  const handelChangedropdown = (QuestionNumber, selectedvalue, questionId) => {
    setCalled(true);
    // Update dropdown values
    setDropdownval((prevAnswer) => {
      const newAnswer = [...prevAnswer];
      newAnswer[QuestionNumber] = selectedvalue; // Update selected value
      return newAnswer;
    });

    // Clear alert and selection states
    showReqAlert(false);
    setIsAnsSeleted([]);
    setIsAnsSeletedDropdown([]);

    // Update the isCompulsoryQuestionAnswered state
    setIsCompulsoryQuestionAnswered((prev) => {
      const updated = [...prev];
      updated[QuestionNumber] = selectedvalue !== 'None'; // True if a value other than "None" is selected
      return updated;
    });

    // Update the selected responses
    setSelectedResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

      const newResponse = {
        response_text: JSON.stringify([selectedvalue]), // Ensure this is an array
        responseid: responceID,
        responderid: responderId,
        surveyid: id,
        questionid: questionId,
        respondent_email: emailid || name || 'anonymous',
      };

      if (responseIndex !== -1) {
        // Update existing response
        updatedResponses[responseIndex] = newResponse;
      } else {
        // Add new response
        updatedResponses.push(newResponse);
      }

      console.log('Updated Responses:', updatedResponses); // Log updated responses

      return updatedResponses;
    });

    // Validate based on questionBranchingData
    setSelectedResponses((prevResponses) => {
      const currentQuestionBranching = questionBranchingData.find(
        (q) => q.question_id.toString() === questionId.toString()
      );

      if (currentQuestionBranching) {
        try {
          const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
          const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
          const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

          // Get selected answers from the latest responses
          const selectedAnswers = JSON.parse(
            prevResponses.find((response) => response.questionid === questionId)?.response_text || '[]'
          );
          console.log('Selected Answers:', selectedAnswers); // Log selected answers

          // Initialize validity check
          let isValid = false;

          // Apply conditions based on logical operators
          switch (JSON.stringify(logicalOperators)) {
            case '[""]': {
              const [text1] = answerTexts; // Single text
              const condition1 = answerConditions[0];

              // Check conditions based on the selected answers
              isValid =
                (condition1 === 'Is' && selectedAnswers.includes(text1)) ||
                (condition1 === 'Is not' && !selectedAnswers.includes(text1));
              break;
            }

            case '["","OR"]': {
              const [text1, text2] = answerTexts; // Two texts
              const [condition1, condition2] = answerConditions;

              // Check conditions based on the selected answers
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswers.includes(text1)) ||
                (condition1 === 'Is not' && !selectedAnswers.includes(text1));
              const isSecondConditionValid =
                (condition2 === 'Is' && selectedAnswers.includes(text2)) ||
                (condition2 === 'Is not' && !selectedAnswers.includes(text2));

              isValid = isFirstConditionValid || isSecondConditionValid;
              break;
            }

            case '["","OR","OR"]': {
              const [text1, text2, text3] = answerTexts; // Three texts
              const [condition1, condition2, condition3] = answerConditions;

              // Check conditions based on the selected answers
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswers.includes(text1)) ||
                (condition1 === 'Is not' && !selectedAnswers.includes(text1));
              const isSecondConditionValid =
                (condition2 === 'Is' && selectedAnswers.includes(text2)) ||
                (condition2 === 'Is not' && !selectedAnswers.includes(text2));
              const isThirdConditionValid =
                (condition3 === 'Is' && selectedAnswers.includes(text3)) ||
                (condition3 === 'Is not' && !selectedAnswers.includes(text3));

              isValid = isFirstConditionValid || isSecondConditionValid || isThirdConditionValid;
              break;
            }

            default:
              isValid = false; // Default to false if no case matches
          }

          // Log the validation status before setting it
          console.log('Validation Status:', isValid);

          // Update validate state
          setValidate(isValid);

          // Trigger the action values if the condition is met
          if (isValid) {
            console.log('Triggering action values for question', questionId);
            // Add your logic to handle the action here
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      } else {
        console.log('No branching data found for question ID:', questionId);
      }

      return prevResponses;
    });
  };

  const handelEnterkey = (e) => {
    if (e.key === 'Enter') {
      handleNextQuestion();
    }
  };

  // slider code
  const handelLongAnswer = (event, QuestionNumber, questionId) => {
    const inputvalue = event.target.value; // Get the current input value
    const trimmedValue = inputvalue.trim(); // Trim whitespace

    setlongAnswer((answerArray) => {
      const newArry = [...answerArray];
      newArry[QuestionNumber] = inputvalue; // Update the answer array
      return newArry;
    });

    // Adjust the height based on content or reset to initial height if empty
    if (trimmedValue === '') {
      setAreaHeight('48px'); // Reset to initial height when empty
    } else {
      setAreaHeight(`${event.target.scrollHeight}px`); // Adjust height based on content
    }

    setIsAnsSeleted([]); // Reset selected answers
    setIsAnsSeletedLongAns([]); // Reset long answer selections

    // Check if the input value is not empty (not only spaces)
    const isFilled = trimmedValue !== '';

    // Update the compulsory question answered state
    setIsCompulsoryQuestionAnswered((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = isFilled; // Update the filled state
      return newArr;
    });

    // Update or add the response to the selectedResponses array
    const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);

    if (existingResponseIndex !== -1) {
      const updatedResponses = [...selectedResponses];
      updatedResponses[existingResponseIndex].response_text = inputvalue; // Update existing response
      setSelectedResponses(updatedResponses);
    } else {
      setSelectedResponses((prevResponses) => [
        ...prevResponses,
        {
          response_text: inputvalue,
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        },
      ]);
    }

    setTimeout(() => {
      setSelectedResponses((prevResponses) => {
        const currentQuestionBranching = questionBranchingData.find(
          (q) => q.question_id.toString() === questionId.toString()
        );
        console.log('Current Question Branching Data:', currentQuestionBranching);

        if (currentQuestionBranching) {
          try {
            const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
            const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
            const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);
            console.log('Parsed Conditions, Texts, and Operators:', answerConditions, answerTexts, logicalOperators);

            // Get selected answers from the latest responses
            const selectedResponse = prevResponses.find((response) => response.questionid === questionId);
            const selectedAnswer = selectedResponse ? selectedResponse.response_text : '';
            const selectedAnswers = selectedAnswer.split(' ').map((answer) => answer.toLowerCase());
            console.log('Selected Answers (Lowercase):', selectedAnswers);

            // Initialize validity check
            let isValid = false;

            // Iterate over answer conditions
            answerConditions.forEach((condition, index) => {
              if (index % 2 === 0) {
                // Condition type
                const operatorIndex = Math.floor(index / 2);
                const answerText = answerTexts[operatorIndex] ? answerTexts[operatorIndex].toLowerCase() : '';
                const exactText = answerTexts[operatorIndex];
                const currentCondition = answerConditions[index];
                const nextCondition = answerConditions[index + 1];

                switch (currentCondition) {
                  case 'Is':
                    if (nextCondition === 'Contains' && selectedAnswers.includes(answerText)) {
                      isValid = true;
                    } else if (nextCondition === 'Exactly' && selectedAnswer.includes(exactText)) {
                      isValid = true;
                    } else if (nextCondition === 'Is empty' && trimmedValue === '') {
                      isValid = true;
                    }
                    break;
                  case 'Is not':
                    if (nextCondition === 'Contains' && !selectedAnswers.includes(answerText)) {
                      isValid = true;
                    } else if (nextCondition === 'Exactly' && !selectedAnswer.includes(exactText)) {
                      isValid = true;
                    } else if (nextCondition === 'Is empty' && trimmedValue !== '') {
                      isValid = true;
                    }
                    break;
                  default:
                    // Handle other cases if necessary
                    break;
                }
              }
            });

            console.log('Condition Valid:', isValid);
            setValidate(isValid);

            // Trigger the action values if the condition is met
            if (isValid) {
              console.log('Triggering action values for question', questionId);
              // Add your logic to handle the action here
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else {
          console.log('No branching data found for question ID:', questionId);
        }
        // Return the state as is for setSelectedResponses
        return prevResponses;
      });
    }, 0);
  };

  const checkIsEmptyCondition = (questionId, callback) => {
    if (called) return; // Prevent multiple calls
    setCalled(true);

    console.log('Checking empty condition for question ID:', questionId);
    const currentQuestionBranching = questionBranchingData.find(
      (q) => q.question_id.toString() === questionId.toString()
    );

    if (currentQuestionBranching) {
      try {
        const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
        console.log('Parsed answer conditions:', answerConditions);

        if (answerConditions[0] === 'Is' && answerConditions[1] === 'Is empty') {
          const selectedResponse = selectedResponses.find((response) => response.questionid === questionId);
          const selectedAnswer = selectedResponse ? selectedResponse.response_text : '';
          console.log('Selected Answer:', selectedAnswer);

          const isValid = selectedAnswer.trim() === '' || selectedAnswer.trim() === null;
          setValidate(isValid);

          if (callback) {
            callback(isValid);
          }
        } else {
          setValidate(false);
          if (callback) {
            callback(false);
          }
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
        setValidate(false);
        if (callback) {
          callback(false);
        }
      }
    } else {
      console.error('No branching data found for question ID:', questionId);
      setValidate(false);
      if (callback) {
        callback(false);
      }
    }
  };

  const handelShortAnswer = (event, QuestionNumber, questionId) => {
    const inputvalue = event.target.value; // Preserve spaces
    const trimmedValue = inputvalue.trim(); // For validation
    setCalled(true);
    setIsanswer6(false);

    // Update short answer value with preserved spaces
    setShortAnsvalue((shortarry) => {
      const newArry = [...shortarry];
      newArry[QuestionNumber] = inputvalue; // Update array with input value
      return newArry;
    });

    // Check if the trimmed input value is not empty
    const isFilled = trimmedValue !== '';
    setIsShortAnswerFilled(isFilled);
    setIsAnsSeleted([]);
    setIsAnsSeletedShortAns([]);

    // Update compulsory question answered state
    setIsCompulsoryQuestionAnswered((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = isFilled;
      return newArr;
    });

    // Update or add the response to the selectedResponses array
    setSelectedResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);

      if (existingResponseIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingResponseIndex].response_text = inputvalue;
        return updatedResponses;
      }

      return [
        ...prevResponses,
        {
          response_text: inputvalue,
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        },
      ];
    });

    // Validate based on the question branching data
    setSelectedResponses((prevResponses) => {
      const currentQuestionBranching = questionBranchingData.find(
        (q) => q.question_id.toString() === questionId.toString()
      );

      console.log('Current Question Branching Data:', currentQuestionBranching);

      if (currentQuestionBranching) {
        try {
          const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
          const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
          const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);
          console.log('Parsed Conditions, Texts, and Operators:', answerConditions, answerTexts, logicalOperators);

          // Get selected answers from the latest responses
          const selectedResponse = prevResponses.find((response) => response.questionid === questionId);
          const selectedAnswer = selectedResponse ? selectedResponse.response_text : '';
          const selectedAnswers = selectedAnswer.split(' ').map((answer) => answer.toLowerCase());
          console.log('Selected Answers (Lowercase):', selectedAnswers);

          // Initialize validity check
          let isValid = false;

          // Iterate over answer conditions
          answerConditions.forEach((condition, index) => {
            if (index % 2 === 0) {
              // Condition type
              const operatorIndex = Math.floor(index / 2);
              const answerText = answerTexts[operatorIndex] ? answerTexts[operatorIndex].toLowerCase() : '';
              const exactText = answerTexts[operatorIndex];
              const currentCondition = answerConditions[index];
              const nextCondition = answerConditions[index + 1];

              switch (currentCondition) {
                case 'Is':
                  if (nextCondition === 'Contains' && selectedAnswers.includes(answerText)) {
                    isValid = true;
                  } else if (nextCondition === 'Exactly' && selectedAnswer.includes(exactText)) {
                    isValid = true;
                  } else if (nextCondition === 'Is empty' && trimmedValue === '') {
                    isValid = true;
                  }
                  break;
                case 'Is not':
                  if (nextCondition === 'Contains' && !selectedAnswers.includes(answerText)) {
                    isValid = true;
                  } else if (nextCondition === 'Exactly' && !selectedAnswer.includes(exactText)) {
                    isValid = true;
                  } else if (nextCondition === 'Is empty' && trimmedValue !== '') {
                    isValid = true;
                  }
                  break;
                default:
                  // Handle other cases if necessary
                  break;
              }
            }
          });

          console.log('Condition Valid:', isValid);
          setValidate(isValid);

          // Trigger the action values if the condition is met
          if (isValid) {
            console.log('Triggering action values for question', questionId);
            // Add your logic to handle the action here
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      } else {
        console.log('No branching data found for question ID:', questionId);
      }

      return prevResponses;
    });
  };

  const handelEnterEmail = (e) => {
    const getmailid = e.target.value;
    setEmailid(getmailid);
    showReqAlert(false);
  };

  const handleEnterName = (e) => {
    const enteredName = e.target.value;
    setName(enteredName);
    // Validate name
    setIsValidName(validateName(enteredName));
  };

  const validateName = (name) => {
    // Basic validation: Name should not be empty and should contain alphabets only
    return /^[a-zA-Z]+$/.test(name.trim());
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isImgSelected, setIsImgSelected] = useState(false);

  // img uploader code for file upload question  start
  const handleImageUpload = async (event, QuestionNumber, questionId) => {
    setIsAnsSeleted([]);
    setIsAnsSeletedforfile([]);

    const file = event.target.files[0];

    if (!file) {
      setError('No file selected.');
      return;
    }

    // File type validation
    if (!['image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
      setError('Invalid file type. Please upload a PNG, JPG, JPEG, or GIF file.');
      return;
    }

    setError(null);

    // Create a preview URL for displaying the image to the user
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);

    // Prepare form data to send to the server
    const formData = new FormData();
    formData.append('responseImgFile', file);
    formData.append('surveyid', id);
    formData.append('userid', localStorage.getItem('uid'));

    try {
      const endpoint = API_ENDPOINTS.answerImagePOST;
      const res = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        const responseJson = await res.json();

        // Update state with the response from the server
        setSelectedResponses((prevResponses) => [
          ...prevResponses,
          {
            response_text: responseJson ? responseJson.logo : '',
            responseid: responceID,
            responderid: responderId,
            surveyid: id,
            questionid: questionId,
            respondent_email: emailid || name || 'anonymous',
          },
        ]);

        // Update state to indicate that the image was uploaded successfully
        setUploadedImages((prev) => ({
          ...prev,
          [QuestionNumber]: true,
        }));

        // Mark the question as answered
        setIsCompulsoryQuestionAnswered((prev) => {
          const newArr = [...prev];
          newArr[QuestionNumber] = true;
          return newArr;
        });
      } else {
        // Handle server errors
        console.error('Failed to upload image:', res.status);
        setError('Failed to upload image. Please try again later.');
      }
    } catch (error) {
      // Handle fetch or other runtime errors
      console.error('Error posting image:', error);
      setError('Failed to upload image. Please try again later.');
    }
  };

  const handleFileChange = async (event, QuestionNumber, questionId) => {
    setIsAnsSeleted([]);
    setIsAnsSeletedforfile([]);
    const uploadedFile = event.target.files[0];
    if (
      uploadedFile.type !== 'application/pdf' &&
      uploadedFile.type !== 'application/msword' &&
      uploadedFile.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation' &&
      uploadedFile.type !== 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' &&
      uploadedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      uploadedFile.type !== 'text/plain'
    ) {
      setFileTypeError('Invalid file type. Please upload a PDF, DOC, PPT, PPTX, DOCX, or TXT file.');
      setSelectedFile(null);
      setUploadedFiles((prev) => ({
        ...prev,
        [QuestionNumber]: false,
      }));
      setIsCompulsoryQuestionAnswered((prev) => {
        const newArr = [...prev];
        newArr[QuestionNumber] = false;
        return newArr;
      });
    } else {
      setFileTypeError(null);
      setSelectedFile(uploadedFile);

      const uid = localStorage.getItem('uid');
      const formData = new FormData();
      formData.append('documentFile', uploadedFile);
      formData.append('surveyid', id);
      formData.append('userid', uid);

      try {
        const endpoint = API_ENDPOINTS.answerImagePOST;
        const res = await fetch(`${BASE_URL}${endpoint}`, {
          method: 'POST',
          body: formData,
        });
        if (res.status === 200) {
          const responseJson = await res.json();
          setSelectedResponses((prevResponses) => [
            ...prevResponses,
            {
              response_text: responseJson ? responseJson.document : '',
              responseid: responceID,
              responderid: responderId,
              surveyid: id,
              questionid: questionId,
              respondent_email: emailid || name || 'anonymous',
            },
          ]);
          setUploadedFiles((prev) => ({
            ...prev,
            [QuestionNumber]: true,
          }));
          setIsCompulsoryQuestionAnswered((prev) => {
            const newArr = [...prev];
            newArr[QuestionNumber] = true;
            return newArr;
          });
        } else {
          console.error('Error posting doc:', res.status);
        }
      } catch (error) {
        console.error('Error posting doc:', error);
      }
    }
  };

  const surveyScreenAnimate = {
    animation: `fadeIn${fadeIn ? 'FromTop' : 'FromBottom'} 0.5s ease`,
  };

  const lightenColor = (color, percent) => {
    // Function to parse hex color to RGB components
    const hexToRGB = (hex) => {
      return {
        R: parseInt(hex.slice(1, 3), 16),
        G: parseInt(hex.slice(3, 5), 16),
        B: parseInt(hex.slice(5, 7), 16),
      };
    };

    // Function to convert RGB components back to hex color
    const RGBToHex = (R, G, B) => {
      // Convert each component to a two-digit hexadecimal string
      const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      };

      // Convert RGB components to hex format
      const hexR = componentToHex(R);
      const hexG = componentToHex(G);
      const hexB = componentToHex(B);

      // Combine components into a hex color string
      return `#${hexR}${hexG}${hexB}`;
    };

    // Parse hex color to RGB components
    const { R, G, B } = hexToRGB(color);

    // Function to lighten each RGB component by percent
    const lightenComponent = (comp, percent) => {
      return Math.min(255, comp * (1 + percent / 100));
    };

    // Lighten RGB components
    const newR = lightenComponent(R, percent);
    const newG = lightenComponent(G, percent);
    const newB = lightenComponent(B, percent);

    // Convert RGB back to hex color format
    return RGBToHex(newR, newG, newB);
  };

  const styleObject = {
    fontFamily: contentStyle?.[0]?.[0] || 'inherit', // Fallback to 'inherit' if contentStyle is not defined
    color: contentStyle?.[0]?.[1] || 'black',
    backgroundColor: contentStyle?.[0]?.[2] || 'white', // Fallback to 'black' if contentStyle is not defined
  };

  const lighterBackgroundColor = lightenColor(styleObject.backgroundColor, 20);
  const borderColor = contentStyle?.[0]?.[1] || 'black'; 
  const styledComponent = {
    ...styleObject,
    backgroundColor: lighterBackgroundColor,
  };

  const questioncss = {
    ...surveyScreenAnimate, // Include animation styles if needed
    margin: '10px 0',
    fontSize: '26px',
    textAlign: 'start',
    fontFamily: styleObject.fontFamily,
    // color: styleObject.color,
  };

  // img uploader code for file upload question end
  const fetchSurveyList = async () => {
    try {
      const endpoint = API_ENDPOINTS.surveyEdit;
      const response = fetch(`${BASE_URL}${endpoint}?surveyid=${getid}`)
        .then((response) => response.json())
        .then((data) => {
          setLogourl(data.surveyid.status);
        });
      if (response.status === 200) {
        console.log('survey get successfully', response);
      }
    } catch (error) {
      console.error('Error fetching survey list:', error);
    }
  };

  useEffect(() => {
    fetchSurveyList();
  }, []);

  // second form code
  const surveyMainFormStyle = {
    backgroundColor: 'transparent',
    borderRadius: '20px',
    maxWidth: '900px',
  };

  // if single  select
  const handleOptionClick = (optionIndex) => {
    setIsImgSelected(true);
    setIsanswer11(false);
    setSelectedImg(selectedImages + 1);
    setTimeout(() => {
      setIsImgSelected(false);
    }, 1000);

    // If the clicked option is already selected, do nothing
    if (selectedOptions.includes(optionIndex)) {
      return;
    }
    // Clear previously selected option
    setSelectedOptions([optionIndex]);
  };

  // const [ratingvalue, setRatingValue] = useState(Array(option.length).fill(-1));

  const [ratingvalue, setRatingValue] = useState(Array(option.length).fill(-1));

  const [islabelclick, setLabelclick] = useState([]);
  const [selectedResponses, setSelectedResponses] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedImages, setUploadedImages] = useState({});
  // const [ratingValue, setRatingValue] = useState(Array(option.length).fill(-1));

  const handelRating = (QuestionNumber, data, questionId, index) => {
    setCalled(true);
    setLabelclick((newvalue) => {
      const newArry = [...newvalue];
      newArry[QuestionNumber] = data;
      return newArry;
    });

    setIsAnsSeleted([]);
    setIsAnsSeletedrating([]);

    setRatingValue((newvalue) => {
      const newArry = [...newvalue];
      newArry[QuestionNumber] = index;
      return newArry;
    });

    setIsCompulsoryQuestionAnswered((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = data !== '';
      return newArr;
    });

    setIsAnswerSelected((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = data !== '';
      return newArr;
    });

    setSelectedResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

      const newResponse = {
        response_text: JSON.stringify([data]), // Ensure this is an array
        responseid: responceID,
        responderid: responderId,
        surveyid: id,
        questionid: questionId,
        respondent_email: emailid || name || 'anonymous',
      };

      if (responseIndex !== -1) {
        updatedResponses[responseIndex] = newResponse;
      } else {
        updatedResponses.push(newResponse);
      }

      return updatedResponses;
    });

    setTimeout(() => {
      setSelectedResponses((newResponse) => {
        const currentQuestionBranching = questionBranchingData.find(
          (q) => q.question_id.toString() === questionId.toString()
        );

        if (currentQuestionBranching) {
          const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
          const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
          const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

          // Get selected answers from the latest responses
          const selectedResponse = newResponse.find((response) => response.questionid === questionId);
          const selectedAnswers = selectedResponse ? JSON.parse(selectedResponse.response_text) : [];
          console.log('Selected Answers:', selectedAnswers); // Log selected answers

          // Evaluate conditions
          let isValid = false; // Default to false and then check conditions

          // Apply conditions based on logical operators
          switch (JSON.stringify(logicalOperators)) {
            case '["","OR"]': {
              const [text1, text2] = answerTexts; // Two texts
              const condition1 = answerConditions[0];
              const condition2 = answerConditions[1];

              // Check conditions based on the selected answers
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswers.includes(text1)) ||
                (condition1 === 'Is not' && !selectedAnswers.includes(text1));
              const isSecondConditionValid =
                (condition2 === 'Is' && selectedAnswers.includes(text2)) ||
                (condition2 === 'Is not' && !selectedAnswers.includes(text2));

              // Valid if any condition is valid
              isValid = isFirstConditionValid || isSecondConditionValid;

              console.log('Condition 1 Valid:', isFirstConditionValid);
              console.log('Condition 2 Valid:', isSecondConditionValid);
              console.log('Overall Valid:', isValid);

              break;
            }

            case '["","OR","OR"]': {
              const [text1, text2, text3] = answerTexts; // Three texts
              const condition1 = answerConditions[0];
              const condition2 = answerConditions[1];
              const condition3 = answerConditions[2];

              // Check conditions based on the selected answers
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswers.includes(text1)) ||
                (condition1 === 'Is not' && !selectedAnswers.includes(text1));
              const isSecondConditionValid =
                (condition2 === 'Is' && selectedAnswers.includes(text2)) ||
                (condition2 === 'Is not' && !selectedAnswers.includes(text2));
              const isThirdConditionValid =
                (condition3 === 'Is' && selectedAnswers.includes(text3)) ||
                (condition3 === 'Is not' && !selectedAnswers.includes(text3));

              // Valid if any condition is valid
              isValid = isFirstConditionValid || isSecondConditionValid || isThirdConditionValid;

              console.log('Condition 1 Valid:', isFirstConditionValid);
              console.log('Condition 2 Valid:', isSecondConditionValid);
              console.log('Condition 3 Valid:', isThirdConditionValid);
              console.log('Overall Valid:', isValid);

              break;
            }

            case '[""]': {
              const [text1] = answerTexts; // One text
              const condition1 = answerConditions[0];

              // Check conditions based on the selected answers
              const isFirstConditionValid =
                (condition1 === 'Is' && selectedAnswers.includes(text1)) ||
                (condition1 === 'Is not' && !selectedAnswers.includes(text1));

              // Valid if the condition is valid
              isValid = isFirstConditionValid;

              console.log('Condition 1 Valid:', isFirstConditionValid);
              console.log('Overall Valid:', isValid);

              break;
            }
            // Handle other cases if needed
            default:
              isValid = false; // Default to false if no case matches
          }

          setValidate(isValid);
          console.log('setValidate', isValid);

          // Trigger the action values if the condition is met
          if (isValid) {
            console.log('Triggering action values for question', questionId);
            // Add your logic to handle the action here
          }
        }

        // Return the state as is for setSelectedResponses
        return newResponse;
      });
    }, 0); // Delay to ensure state update has taken effect
  };

  function handelGetLinerdata(QuestionNumber, value, questionId) {
    setCalled(true);
    setSelectedResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);

      const newResponse = {
        response_text: value,
        responseid: responceID,
        responderid: responderId,
        surveyid: id,
        questionid: questionId,
        respondent_email: emailid || name || 'anonymous',
      };

      const updatedResponses = [...prevResponses];
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex] = newResponse;
      } else {
        updatedResponses.push(newResponse);
      }

      setIsCompulsoryQuestionAnswered((prev) => {
        const newArr = [...prev];
        newArr[QuestionNumber] = true;
        return newArr;
      });

      return updatedResponses;
    });

    setTimeout(() => {
      setSelectedResponses((prevResponses) => {
        const currentQuestionBranching = questionBranchingData.find(
          (q) => q.question_id.toString() === questionId.toString()
        );

        if (currentQuestionBranching) {
          const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions || '[]');
          const answerTexts = JSON.parse(currentQuestionBranching.answer_texts || '[]');
          const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators || '[]');

          const selectedResponse = prevResponses.find((response) => response.questionid === questionId);

          // Ensure selectedAnswers is always an array
          const selectedAnswers = Array.isArray(selectedResponse?.response_text)
            ? [...selectedResponse.response_text]
            : [selectedResponse?.response_text].filter(Boolean);

          console.log('Selected Answers:', selectedAnswers);

          let isValid = false;

          // Helper function to check conditions
          const checkCondition = (condition, value) =>
            condition === 'Is' ? selectedAnswers.includes(value) : !selectedAnswers.includes(value);

          switch (JSON.stringify(logicalOperators)) {
            case '["","OR"]': {
              const [text1, text2] = answerTexts;
              const condition1 = answerConditions[0];
              const condition2 = answerConditions[1];

              if (!logicalOperators.includes('OR')) {
                isValid = checkCondition(condition1, text1) && checkCondition(condition2, text2);
              } else {
                isValid = checkCondition(condition1, text1) || checkCondition(condition2, text2);
              }

              console.log('Condition 1 Valid:', checkCondition(condition1, text1));
              console.log('Condition 2 Valid:', checkCondition(condition2, text2));
              console.log('Overall Valid:', isValid);

              break;
            }

            case '["","OR","OR"]': {
              const [text1, text2, text3] = answerTexts;
              const condition1 = answerConditions[0];
              const condition2 = answerConditions[1];
              const condition3 = answerConditions[2];

              const isFirstConditionValid = checkCondition(condition1, text1);
              const isSecondConditionValid = checkCondition(condition2, text2);
              const isThirdConditionValid = checkCondition(condition3, text3);

              isValid = isFirstConditionValid || isSecondConditionValid || isThirdConditionValid;

              console.log('Condition 1 Valid:', isFirstConditionValid);
              console.log('Condition 2 Valid:', isSecondConditionValid);
              console.log('Condition 3 Valid:', isThirdConditionValid);
              console.log('Overall Valid:', isValid);

              break;
            }

            case '[""]': {
              const [text1] = answerTexts;
              const condition1 = answerConditions[0];

              const isFirstConditionValid = checkCondition(condition1, text1);

              isValid = isFirstConditionValid;

              console.log('Condition 1 Valid:', isFirstConditionValid);
              console.log('Overall Valid:', isValid);

              break;
            }

            default:
              isValid = false;
          }

          setValidate(isValid);
          console.log('setValidate', isValid);

          if (isValid) {
            console.log('Triggering action values for question', questionId);
            // Add your logic to handle the action here
          }
        }

        return prevResponses;
      });
    }, 0);
  }

  // function handelGetLinerdata(QuestionNumber, value, questionId) {
  //   setSelectedResponses((prevResponses) => {
  //     // Find the existing response index for the given questionId
  //     const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);
  //     setIsAnsSeleted([]);
  //     setIsAnsSeletedLinear([]);
  //     // Create a new response object
  //     const newResponse = {
  //       response_text: value,
  //       responseid: responceID,
  //       responderid: responderId,
  //       surveyid: id,
  //       questionid: questionId,
  //       respondent_email: emailid || name || 'anonymous',
  //     };

  //     // If response already exists, update it, otherwise add new response
  //     const updatedResponses = [...prevResponses];
  //     if (existingResponseIndex !== -1) {
  //       updatedResponses[existingResponseIndex] = newResponse;
  //     } else {
  //       updatedResponses.push(newResponse);
  //     }

  //     // Update the compulsory question answered state
  //     setIsCompulsoryQuestionAnswered((prev) => {
  //       const newArr = [...prev];
  //       newArr[QuestionNumber] = true;
  //       return newArr;
  //     });

  //     return updatedResponses;
  //   });

  // }

  const [seltedtype, setSelectedType] = useState([]);
  const [isMultipleselect, setIsChecked] = useState(Array.from({ length: 5 }, () => false));

  // function handelGetMcqValue(QuestionNumber, answer, questionId, optionIndex, multipleAnswer) {
  //   setCalled(true);
  //   const newIsMultipleselect = { ...isMultipleselect };

  //   if (multipleAnswer === '1') {
  //     // Checkbox: Toggle the presence of the answer in the response array
  //     newIsMultipleselect[questionId] = newIsMultipleselect[questionId] || [];
  //     newIsMultipleselect[questionId][optionIndex] = !newIsMultipleselect[questionId][optionIndex];
  //     setIsChecked(newIsMultipleselect);

  //     // Update the compulsory question answered state
  //     const isAnswered = newIsMultipleselect[questionId].some((selected) => selected);
  //     setIsCompulsoryQuestionAnswered((prev) => {
  //       const newArr = [...prev];
  //       newArr[QuestionNumber] = isAnswered;
  //       return newArr;
  //     });

  //     const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);

  //     if (existingResponseIndex !== -1) {
  //       const updatedResponses = [...selectedResponses];
  //       let updatedResponseText = JSON.parse(updatedResponses[existingResponseIndex].response_text);

  //       if (newIsMultipleselect[questionId][optionIndex]) {
  //         if (!updatedResponseText.includes(answer)) {
  //           updatedResponseText.push(answer);
  //         }
  //       } else {
  //         updatedResponseText = updatedResponseText.filter((value) => value !== answer);
  //       }

  //       updatedResponses[existingResponseIndex].response_text = JSON.stringify(updatedResponseText);
  //       setSelectedResponses(updatedResponses);
  //     } else {
  //       const newResponse = {
  //         response_text: JSON.stringify([answer]),
  //         responseid: responceID,
  //         responderid: responderId,
  //         surveyid: id,
  //         questionid: questionId,
  //         respondent_email: emailid || name || 'anonymous',
  //       };
  //       setSelectedResponses((prevResponses) => [...prevResponses, newResponse]);
  //     }
  //   } else {
  //     // Radio: Set the selected answer as the only value in response_text
  //     newIsMultipleselect[questionId] = new Array(newIsMultipleselect[questionId]?.length || 0).fill(false);
  //     newIsMultipleselect[questionId][optionIndex] = true;
  //     setIsChecked(newIsMultipleselect);

  //     const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);

  //     if (existingResponseIndex !== -1) {
  //       const updatedResponses = [...selectedResponses];
  //       updatedResponses[existingResponseIndex].response_text = JSON.stringify([answer]);
  //       setSelectedResponses(updatedResponses);
  //     } else {
  //       const newResponse = {
  //         response_text: JSON.stringify([answer]),
  //         responseid: responceID,
  //         responderid: responderId,
  //         surveyid: id,
  //         questionid: questionId,
  //         respondent_email: emailid || name || 'anonymous',
  //       };
  //       setSelectedResponses((prevResponses) => [...prevResponses, newResponse]);
  //     }

  //     // Update the compulsory question answered state
  //     setIsCompulsoryQuestionAnswered((prev) => {
  //       const newArr = [...prev];
  //       newArr[QuestionNumber] = true;
  //       return newArr;
  //     });
  //   }

  //   // Clear error message for the current question
  //   setIsAnsSeleted([]);
  //   setIsAnsSeletedmultipleChoice([]);

  //   // Validate based on questionBranchingData
  //   const currentQuestionBranching = questionBranchingData.find(q => q.question_id === questionId);

  //   if (currentQuestionBranching) {
  //     const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
  //     const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
  //     const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

  //     const selectedAnswers = JSON.parse(selectedResponses.find(response => response.questionid === questionId)?.response_text || '[]');

  //     // Evaluate conditions
  //     let isValid = true; // Default to true and then check conditions

  //     // Apply conditions for all logical operators combinations
  //     switch (JSON.stringify(logicalOperators)) {
  //       case '["","AND"]': {
  //         const [text1, text2] = answerTexts; // Two texts
  //         const condition1 = answerConditions[0];
  //         const condition2 = answerConditions[1];

  //         const isFirstConditionValid = (condition1 === "Is not" && !selectedAnswers.includes(text1)) ||
  //                                       (condition1 === "Is" && selectedAnswers.includes(text1));
  //         const isSecondConditionValid = (condition2 === "Is not" && !selectedAnswers.includes(text2)) ||
  //                                        (condition2 === "Is" && selectedAnswers.includes(text2));

  //         isValid = isFirstConditionValid && isSecondConditionValid;
  //         break;
  //       }
  //       case '["","OR"]': {
  //         const [text1, text2] = answerTexts; // Two texts
  //         const condition1 = answerConditions[0];
  //         const condition2 = answerConditions[1];

  //         const isFirstConditionValid = (condition1 === "Is not" && !selectedAnswers.includes(text1)) ||
  //                                       (condition1 === "Is" && selectedAnswers.includes(text1));
  //         const isSecondConditionValid = (condition2 === "Is not" && !selectedAnswers.includes(text2)) ||
  //                                        (condition2 === "Is" && selectedAnswers.includes(text2));

  //         isValid = isFirstConditionValid || isSecondConditionValid;
  //         break;
  //       }
  //       case '["","OR","AND"]': {
  //         const [orText1, orText2, andText] = answerTexts; // First two texts for OR, third text for AND
  //         const condition1 = answerConditions[0];
  //         const condition2 = answerConditions[1];
  //         const andCondition = answerConditions[2];

  //         const isOrValid = [orText1, orText2].some(text =>
  //           (condition1 === "Is not" && !selectedAnswers.includes(text)) ||
  //           (condition1 === "Is" && selectedAnswers.includes(text))
  //         );
  //         const isAndValid = (andCondition === "Is not" && !selectedAnswers.includes(andText)) ||
  //                             (andCondition === "Is" && selectedAnswers.includes(andText));

  //         isValid = isOrValid && isAndValid;
  //         break;
  //       }
  //       case '["","AND","OR"]': {
  //         const [andText1, andText2] = answerTexts; // First two texts for AND
  //         const orText = answerTexts[2];            // Third text for OR

  //         const condition1 = answerConditions[0];
  //         const condition2 = answerConditions[1];
  //         const orCondition = answerConditions[2];

  //         const isAndValid = [andText1, andText2].every(text =>
  //           (condition1 === "Is not" && !selectedAnswers.includes(text)) ||
  //           (condition1 === "Is" && selectedAnswers.includes(text))
  //         );
  //         const isOrValid = (orCondition === "Is not" && !selectedAnswers.includes(orText)) ||
  //                           (orCondition === "Is" && selectedAnswers.includes(orText));

  //         isValid = isAndValid && isOrValid;
  //         break;
  //       }
  //       case '["","OR","OR"]': {
  //         const [orText1, orText2, orText3] = answerTexts; // All three texts for OR
  //         const condition1 = answerConditions[0];
  //         const condition2 = answerConditions[1];
  //         const condition3 = answerConditions[2];

  //         const isFirstOrValid = (condition1 === "Is not" && !selectedAnswers.includes(orText1)) ||
  //                                (condition1 === "Is" && selectedAnswers.includes(orText1));
  //         const isSecondOrValid = (condition2 === "Is not" && !selectedAnswers.includes(orText2)) ||
  //                                 (condition2 === "Is" && selectedAnswers.includes(orText2));
  //         const isThirdOrValid = (condition3 === "Is not" && !selectedAnswers.includes(orText3)) ||
  //                                (condition3 === "Is" && selectedAnswers.includes(orText3));

  //         isValid = isFirstOrValid || isSecondOrValid || isThirdOrValid;
  //         break;
  //       }
  //       case '["","AND","AND"]': {
  //         const [andText1, andText2, andText3] = answerTexts; // All three texts for AND
  //         const condition1 = answerConditions[0];
  //         const condition2 = answerConditions[1];
  //         const condition3 = answerConditions[2];

  //         const isFirstAndValid = (condition1 === "Is not" && !selectedAnswers.includes(andText1)) ||
  //                                 (condition1 === "Is" && selectedAnswers.includes(andText1));
  //         const isSecondAndValid = (condition2 === "Is not" && !selectedAnswers.includes(andText2)) ||
  //                                  (condition2 === "Is" && selectedAnswers.includes(andText2));
  //         const isThirdAndValid = (condition3 === "Is not" && !selectedAnswers.includes(andText3)) ||
  //                                 (condition3 === "Is" && selectedAnswers.includes(andText3));

  //         isValid = isFirstAndValid && isSecondAndValid && isThirdAndValid;
  //         break;
  //       }
  //       default:
  //         isValid = false; // Default to false if no case matches
  //     }

  //     // Set the validate state based on the result
  //     setValidate(isValid);
  //     console.log("setValidate", isValid);

  //     // Trigger the action values if the condition is met
  //     if (isValid) {
  //       // Code to go to the action values question
  //       console.log("Triggering action values for question", questionId);
  //       // Add your logic to handle the action here
  //     }
  //   }
  // }

  function handelGetMcqValue(QuestionNumber, answer, questionId, optionIndex, multipleAnswer) {
    setCalled(true);
    console.log(
      'handelGetMcqValue called with params:',
      QuestionNumber,
      answer,
      questionId,
      optionIndex,
      multipleAnswer
    );
    const newIsMultipleselect = { ...isMultipleselect };

    if (multipleAnswer === '1') {
      // Checkbox: Toggle the presence of the answer in the response array
      newIsMultipleselect[questionId] = newIsMultipleselect[questionId] || [];
      newIsMultipleselect[questionId][optionIndex] = !newIsMultipleselect[questionId][optionIndex];
      setIsChecked(newIsMultipleselect);

      // Update the compulsory question answered state
      const isAnswered = newIsMultipleselect[questionId].some((selected) => selected);
      setIsCompulsoryQuestionAnswered((prev) => {
        const newArr = [...prev];
        newArr[QuestionNumber] = isAnswered;
        return newArr;
      });

      const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);

      if (existingResponseIndex !== -1) {
        const updatedResponses = [...selectedResponses];
        let updatedResponseText = JSON.parse(updatedResponses[existingResponseIndex].response_text);

        if (newIsMultipleselect[questionId][optionIndex]) {
          if (!updatedResponseText.includes(answer)) {
            updatedResponseText.push(answer);
          }
        } else {
          updatedResponseText = updatedResponseText.filter((value) => value !== answer);
        }

        updatedResponses[existingResponseIndex].response_text = JSON.stringify(updatedResponseText);
        setSelectedResponses(updatedResponses);
      } else {
        const newResponse = {
          response_text: JSON.stringify([answer]),
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        };
        setSelectedResponses((prevResponses) => [...prevResponses, newResponse]);
      }
    } else {
      // Radio: Set the selected answer as the only value in response_text
      newIsMultipleselect[questionId] = new Array(newIsMultipleselect[questionId]?.length || 0).fill(false);
      newIsMultipleselect[questionId][optionIndex] = true;
      setIsChecked(newIsMultipleselect);

      const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);

      if (existingResponseIndex !== -1) {
        const updatedResponses = [...selectedResponses];
        updatedResponses[existingResponseIndex].response_text = JSON.stringify([answer]);
        setSelectedResponses(updatedResponses);
      } else {
        const newResponse = {
          response_text: JSON.stringify([answer]),
          responseid: responceID,
          responderid: responderId,
          surveyid: id,
          questionid: questionId,
          respondent_email: emailid || name || 'anonymous',
        };
        setSelectedResponses((prevResponses) => [...prevResponses, newResponse]);
      }

      // Update the compulsory question answered state
      setIsCompulsoryQuestionAnswered((prev) => {
        const newArr = [...prev];
        newArr[QuestionNumber] = true;
        return newArr;
      });
    }

    // Clear error message for the current question
    setIsAnsSeleted([]);
    setIsAnsSeletedmultipleChoice([]);
    setSelectedResponses((prevResponses) => {
      // Validate based on questionBranchingData
      console.log('Selected Responses before setting branching:', prevResponses);

      // Retrieve current question branching
      const currentQuestionBranching = questionBranchingData.find((q) => q.question_id === parseInt(questionId, 10));

      // Log the retrieved branching data
      console.log('Current Question Branching:', currentQuestionBranching);
      if (currentQuestionBranching) {
        const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
        const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
        const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

        const selectedAnswers = JSON.parse(
          prevResponses.find((response) => response.questionid === questionId)?.response_text || '[]'
        );

        console.log('Conditions:', answerConditions);
        console.log('Answer Texts:', answerTexts);
        console.log('Logical Operators:', logicalOperators);
        console.log('Selected Answers:', selectedAnswers);

        let isValid = false;

        // Apply conditions for all logical operators combinations
        switch (JSON.stringify(logicalOperators)) {
          case '[""]': {
            const [text1] = answerTexts;
            const condition1 = answerConditions[0];

            const isFirstConditionValid =
              (condition1 === 'Is not' && !selectedAnswers.includes(text1)) ||
              (condition1 === 'Is' && selectedAnswers.includes(text1));

            isValid = isFirstConditionValid;
            break;
          }

          case '["","AND"]': {
            const [text1, text2] = answerTexts;
            const condition1 = answerConditions[0];
            const condition2 = answerConditions[1];

            const isFirstConditionValid =
              (condition1 === 'Is not' && !selectedAnswers.includes(text1)) ||
              (condition1 === 'Is' && selectedAnswers.includes(text1));
            const isSecondConditionValid =
              (condition2 === 'Is not' && !selectedAnswers.includes(text2)) ||
              (condition2 === 'Is' && selectedAnswers.includes(text2));

            isValid = isFirstConditionValid && isSecondConditionValid;
            break;
          }
          case '["","OR"]': {
            const [text1, text2] = answerTexts; // Two texts
            const condition1 = answerConditions[0];
            const condition2 = answerConditions[1];

            const isFirstConditionValid =
              (condition1 === 'Is not' && !selectedAnswers.includes(text1)) ||
              (condition1 === 'Is' && selectedAnswers.includes(text1));
            const isSecondConditionValid =
              (condition2 === 'Is not' && !selectedAnswers.includes(text2)) ||
              (condition2 === 'Is' && selectedAnswers.includes(text2));

            isValid = isFirstConditionValid || isSecondConditionValid;
            break;
          }
          case '["","OR","AND"]': {
            const [orText1, orText2, andText] = answerTexts; // First two texts for OR, third text for AND
            const condition1 = answerConditions[0];
            const condition2 = answerConditions[1];
            const andCondition = answerConditions[2];

            const isOrValid = [orText1, orText2].some(
              (text) =>
                (condition1 === 'Is not' && !selectedAnswers.includes(text)) ||
                (condition1 === 'Is' && selectedAnswers.includes(text))
            );
            const isAndValid =
              (andCondition === 'Is not' && !selectedAnswers.includes(andText)) ||
              (andCondition === 'Is' && selectedAnswers.includes(andText));

            isValid = isOrValid && isAndValid;
            break;
          }
          case '["","AND","OR"]': {
            const [andText1, andText2] = answerTexts; // First two texts for AND
            const orText = answerTexts[2]; // Third text for OR

            const condition1 = answerConditions[0];
            const condition2 = answerConditions[1];
            const orCondition = answerConditions[2];

            const isAndValid = [andText1, andText2].every(
              (text) =>
                (condition1 === 'Is not' && !selectedAnswers.includes(text)) ||
                (condition1 === 'Is' && selectedAnswers.includes(text))
            );
            const isOrValid =
              (orCondition === 'Is not' && !selectedAnswers.includes(orText)) ||
              (orCondition === 'Is' && selectedAnswers.includes(orText));

            isValid = isAndValid && isOrValid;
            break;
          }
          case '["","OR","OR"]': {
            const [orText1, orText2, orText3] = answerTexts; // All three texts for OR
            const condition1 = answerConditions[0];
            const condition2 = answerConditions[1];
            const condition3 = answerConditions[2];

            const isFirstOrValid =
              (condition1 === 'Is not' && !selectedAnswers.includes(orText1)) ||
              (condition1 === 'Is' && selectedAnswers.includes(orText1));
            const isSecondOrValid =
              (condition2 === 'Is not' && !selectedAnswers.includes(orText2)) ||
              (condition2 === 'Is' && selectedAnswers.includes(orText2));
            const isThirdOrValid =
              (condition3 === 'Is not' && !selectedAnswers.includes(orText3)) ||
              (condition3 === 'Is' && selectedAnswers.includes(orText3));

            isValid = isFirstOrValid || isSecondOrValid || isThirdOrValid;
            break;
          }
          case '["","AND","AND"]': {
            const [andText1, andText2, andText3] = answerTexts; // All three texts for AND
            const condition1 = answerConditions[0];
            const condition2 = answerConditions[1];
            const condition3 = answerConditions[2];

            const isFirstAndValid =
              (condition1 === 'Is not' && !selectedAnswers.includes(andText1)) ||
              (condition1 === 'Is' && selectedAnswers.includes(andText1));
            const isSecondAndValid =
              (condition2 === 'Is not' && !selectedAnswers.includes(andText2)) ||
              (condition2 === 'Is' && selectedAnswers.includes(andText2));
            const isThirdAndValid =
              (condition3 === 'Is not' && !selectedAnswers.includes(andText3)) ||
              (condition3 === 'Is' && selectedAnswers.includes(andText3));

            isValid = isFirstAndValid && isSecondAndValid && isThirdAndValid;
            break;
          }
          default:
            isValid = false; // If none of the above cases match, consider invalid
            break;
        }

        // Set the validate state based on the isValid flag
        setValidate(isValid);
        console.log(isValid, 'isValid');
        return prevResponses; // Invert the validity flag for validation state
      }
      return prevResponses;
    });
  }

  // function handelGetMcqValue(QuestionNumber, answer, questionId, optionIndex, multipleAnswer) {
  //   setCalled(true);
  //   console.log("handelGetMcqValue called with params:", QuestionNumber, answer, questionId, optionIndex, multipleAnswer);

  //   const newIsMultipleselect = { ...isMultipleselect };

  //   if (multipleAnswer === '1') {
  //     // Handle Checkbox (multiple answers allowed)
  //     newIsMultipleselect[questionId] = newIsMultipleselect[questionId] || [];
  //     newIsMultipleselect[questionId][optionIndex] = !newIsMultipleselect[questionId][optionIndex];
  //     setIsChecked(newIsMultipleselect);

  //     // Update compulsory question answered state
  //     const isAnswered = newIsMultipleselect[questionId].some((selected) => selected);
  //     setIsCompulsoryQuestionAnswered((prev) => {
  //       const newArr = [...prev];
  //       newArr[QuestionNumber] = isAnswered;
  //       return newArr;
  //     });

  //     // Update selected responses
  //     const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);
  //     if (existingResponseIndex !== -1) {
  //       const updatedResponses = [...selectedResponses];
  //       let updatedResponseText = JSON.parse(updatedResponses[existingResponseIndex].response_text);

  //       if (newIsMultipleselect[questionId][optionIndex]) {
  //         if (!updatedResponseText.includes(answer)) {
  //           updatedResponseText.push(answer);
  //         }
  //       } else {
  //         updatedResponseText = updatedResponseText.filter((value) => value !== answer);
  //       }

  //       updatedResponses[existingResponseIndex].response_text = JSON.stringify(updatedResponseText);
  //       setSelectedResponses(updatedResponses);
  //     } else {
  //       const newResponse = {
  //         response_text: JSON.stringify([answer]),
  //         responseid: responceID,
  //         responderid: responderId,
  //         surveyid: id,
  //         questionid: questionId,
  //         respondent_email: emailid || name || 'anonymous',
  //       };
  //       setSelectedResponses((prevResponses) => [...prevResponses, newResponse]);
  //     }
  //   } else {
  //     // Handle Radio (single answer allowed)
  //     newIsMultipleselect[questionId] = new Array(newIsMultipleselect[questionId]?.length || 0).fill(false);
  //     newIsMultipleselect[questionId][optionIndex] = true;
  //     setIsChecked(newIsMultipleselect);

  //     // Update selected responses
  //     const existingResponseIndex = selectedResponses.findIndex((response) => response.questionid === questionId);
  //     if (existingResponseIndex !== -1) {
  //       const updatedResponses = [...selectedResponses];
  //       updatedResponses[existingResponseIndex].response_text = JSON.stringify([answer]);
  //       setSelectedResponses(updatedResponses);
  //     } else {
  //       const newResponse = {
  //         response_text: JSON.stringify([answer]),
  //         responseid: responceID,
  //         responderid: responderId,
  //         surveyid: id,
  //         questionid: questionId,
  //         respondent_email: emailid || name || 'anonymous',
  //       };
  //       setSelectedResponses((prevResponses) => [...prevResponses, newResponse]);
  //     }

  //     // Update compulsory question answered state
  //     setIsCompulsoryQuestionAnswered((prev) => {
  //       const newArr = [...prev];
  //       newArr[QuestionNumber] = true;
  //       return newArr;
  //     });
  //   }

  //   // Clear error messages
  //   setIsAnsSeleted([]);
  //   setIsAnsSeletedmultipleChoice([]);

  //   // Validate based on question branching data
  //   setSelectedResponses((prevResponses) => {
  //     // Log the state before updating
  //     console.log("Selected Responses before setting branching:", prevResponses);

  //     // Retrieve current question branching
  //     const currentQuestionBranching = questionBranchingData.find(q => q.question_id.toString() === questionId.toString());

  //     // Log the retrieved branching data
  //     console.log("Current Question Branching:", currentQuestionBranching);

  //     if (currentQuestionBranching) {
  //       const answerConditions = JSON.parse(currentQuestionBranching.answer_conditions);
  //       const answerTexts = JSON.parse(currentQuestionBranching.answer_texts);
  //       const logicalOperators = JSON.parse(currentQuestionBranching.logical_operators);

  //       // Log the parsed values
  //       console.log("Answer Conditions:", answerConditions);
  //       console.log("Answer Texts:", answerTexts);
  //       console.log("Logical Operators:", logicalOperators);

  //       // Get selected answers from the latest responses
  //       const selectedResponse = prevResponses.find(response => response.questionid === questionId);
  //       const selectedAnswers = selectedResponse ? JSON.parse(selectedResponse.response_text) : [];
  //       console.log("Selected Answers:", selectedAnswers);

  //       // Evaluate conditions
  //       let isValid = false;

  //       switch (JSON.stringify(logicalOperators)) {
  //         case '["","OR"]': {
  //           const [text1, text2] = answerTexts;
  //           const condition1 = answerConditions[0];
  //           const condition2 = answerConditions[1];

  //           const isFirstConditionValid = (condition1 === "Is" && selectedAnswers.includes(text1))
  //             || (condition1 === "Is not" && !selectedAnswers.includes(text1));
  //           const isSecondConditionValid = (condition2 === "Is" && selectedAnswers.includes(text2))
  //             || (condition2 === "Is not" && !selectedAnswers.includes(text2));

  //           isValid = isFirstConditionValid || isSecondConditionValid;

  //           console.log("Condition 1 Valid:", isFirstConditionValid);
  //           console.log("Condition 2 Valid:", isSecondConditionValid);
  //           console.log("Overall Valid:", isValid);

  //           break;
  //         }

  //         case '["","OR","OR"]': {
  //           const [text1, text2, text3] = answerTexts;
  //           const condition1 = answerConditions[0];
  //           const condition2 = answerConditions[1];
  //           const condition3 = answerConditions[2];

  //           const isFirstConditionValid = (condition1 === "Is" && selectedAnswers.includes(text1))
  //             || (condition1 === "Is not" && !selectedAnswers.includes(text1));
  //           const isSecondConditionValid = (condition2 === "Is" && selectedAnswers.includes(text2))
  //             || (condition2 === "Is not" && !selectedAnswers.includes(text2));
  //           const isThirdConditionValid = (condition3 === "Is" && selectedAnswers.includes(text3))
  //             || (condition3 === "Is not" && !selectedAnswers.includes(text3));

  //           isValid = isFirstConditionValid || isSecondConditionValid || isThirdConditionValid;

  //           console.log("Condition 1 Valid:", isFirstConditionValid);
  //           console.log("Condition 2 Valid:", isSecondConditionValid);
  //           console.log("Condition 3 Valid:", isThirdConditionValid);
  //           console.log("Overall Valid:", isValid);

  //           break;
  //         }

  //         case '[""]': {
  //           const [text1] = answerTexts;
  //           const condition1 = answerConditions[0];

  //           const isFirstConditionValid = (condition1 === "Is" && selectedAnswers.includes(text1))
  //             || (condition1 === "Is not" && !selectedAnswers.includes(text1));

  //           isValid = isFirstConditionValid;

  //           console.log("Condition 1 Valid:", isFirstConditionValid);
  //           console.log("Overall Valid:", isValid);

  //           break;
  //         }
  //         // Handle other cases if needed
  //         default:
  //           isValid = false;
  //       }

  //       setValidate(isValid);
  //       console.log("setValidate", isValid);

  //       // Trigger the action values if the condition is met
  //       if (isValid) {
  //         console.log("Triggering action values for question", questionId);
  //         // Add your logic to handle the action here
  //       }
  //     } else {
  //       console.warn(`No branching data found for question ID: ${questionId}`);
  //     }

  //     // Return the state as is for setSelectedResponses
  //     return prevResponses;
  //   });
  // }

  function handelImgSelect(QuestionNumber, selectedImageUrl, questionId, isMultiple) {
    setIsAnsSeleted([]);
    setIsAnsSeletedImageChoice([]);
    // Update the selected responses and set isCompulsoryQuestionAnswered based on the selected images
    setSelectedResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex((response) => response.questionid === questionId);
      const newResponse = {
        responseid: responceID,
        responderid: responderId,
        surveyid: id,
        questionid: questionId,
        respondent_email: emailid || name || 'anonymous',
      };

      if (isMultiple) {
        // Handle checkbox (multiple answers)
        const existingResponse = existingResponseIndex !== -1 ? prevResponses[existingResponseIndex] : null;
        const selectedImageUrls = existingResponse ? existingResponse.response_text.split(',') : [];

        const selectedIndex = selectedImageUrls.indexOf(selectedImageUrl);
        if (selectedIndex === -1) {
          selectedImageUrls.push(selectedImageUrl);
        } else {
          selectedImageUrls.splice(selectedIndex, 1);
        }
        newResponse.response_text = selectedImageUrls.join(',');
      } else {
        // Handle radio button (single answer)
        newResponse.response_text = selectedImageUrl;
      }

      const updatedResponses = [...prevResponses];
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex] = newResponse;
      } else {
        updatedResponses.push(newResponse);
      }

      // Check if any image is selected to determine if the question is answered
      const answered = isMultiple ? newResponse.response_text !== '' : true;
      setIsCompulsoryQuestionAnswered((prev) => {
        const updated = [...prev];
        updated[QuestionNumber] = answered;
        return updated;
      });

      return updatedResponses;
    });
  }

  // const dateSelect = (QuestionNumber, date, questionId) => {
  //   const suvryimgDate = () => {
  //     const utcDate = new Date(date);
  //     return utcDate.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' });
  //   };
  //   const convertedDate = suvryimgDate();

  //   // Update the selected date state
  //   setSelectedDate((prev) => ({
  //     ...prev,
  //     [QuestionNumber]: convertedDate,
  //   }));

  //   // Check if a time is already selected
  //   const time = selectedTime[QuestionNumber];

  //   const updatedResponses = [...selectedResponses];
  //   const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

  //   if (responseIndex !== -1) {
  //     updatedResponses[responseIndex].response_text = time ? `${convertedDate}, ${time}` : convertedDate;
  //   } else {
  //     updatedResponses.push({
  //       response_text: time ? `${convertedDate}, ${time}` : convertedDate,
  //       responseid: responceID,
  //       responderid: responderId,
  //       surveyid: id,
  //       questionid: questionId,
  //       respondent_email: emailid || name || 'anonymous',
  //     });
  //   }

  //   setSelectedResponses(updatedResponses);

  //   // Check if both date and time are selected to mark the question as answered
  //   const isAnswered = !!convertedDate || !!time;
  //   setIsCompulsoryQuestionAnswered((prev) => {
  //     const newArr = [...prev];
  //     newArr[QuestionNumber] = isAnswered;
  //     return newArr;
  //   });
  // };

  // const selectTime = (QuestionNumber, time, questionId) => {
  //   const suvryimgTime = () => {
  //     const utcTime = new Date(time);
  //     return utcTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
  //   };
  //   const convertedTime = suvryimgTime();
  //   setIsAnsSeleted([]);

  //   // Update the selected time state
  //   setSelectedTime((prev) => ({
  //     ...prev,
  //     [QuestionNumber]: convertedTime,
  //   }));

  //   // Check if a date is already selected
  //   const date = selectedDate[QuestionNumber];

  //   const updatedResponses = [...selectedResponses];
  //   const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

  //   if (responseIndex !== -1) {
  //     updatedResponses[responseIndex].response_text = date ? `${date}, ${convertedTime}` : convertedTime;
  //   } else {
  //     updatedResponses.push({
  //       response_text: date ? `${date}, ${convertedTime}` : convertedTime,
  //       responseid: responceID,
  //       responderid: responderId,
  //       surveyid: id,
  //       questionid: questionId,
  //       respondent_email: emailid || name || 'anonymous',
  //     });
  //   }

  //   setSelectedResponses(updatedResponses);

  //   // Check if both date and time are selected to mark the question as answered
  //   const isAnswered = !!date && !!convertedTime;
  //   setIsCompulsoryQuestionAnswered((prev) => {
  //     const newArr = [...prev];
  //     newArr[QuestionNumber] = isAnswered;
  //     return newArr;
  //   });
  // };
  const dateSelect = (QuestionNumber, date, questionId) => {
    const suvryimgDate = () => {
      const utcDate = new Date(date);
      return utcDate.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' });
    };
    const convertedDate = suvryimgDate();

    // Update the selected date state
    setSelectedDate((prev) => ({
      ...prev,
      [QuestionNumber]: convertedDate,
    }));

    // Check if a time is already selected
    const time = selectedTime[QuestionNumber];

    const updatedResponses = [...selectedResponses];
    const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

    if (responseIndex !== -1) {
      updatedResponses[responseIndex].response_text = time ? `${convertedDate}, ${time}` : convertedDate;
    } else {
      updatedResponses.push({
        response_text: time ? `${convertedDate}, ${time}` : convertedDate,
        responseid: responceID,
        responderid: responderId,
        surveyid: id,
        questionid: questionId,
        respondent_email: emailid || name || 'anonymous',
      });
    }

    setSelectedResponses(updatedResponses);

    // Check if either date or time is selected to mark the question as answered
    const isAnswered = !!convertedDate || !!time;
    setIsCompulsoryQuestionAnswered((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = isAnswered;
      return newArr;
    });

    // Remove the error message if either date or time is selected
    if (isAnswered) {
      setIsAnsSeleted([]);
      setIsAnsSeletedDateTime([]);
    }
  };

  const selectTime = (QuestionNumber, time, questionId) => {
    const suvryimgTime = () => {
      const utcTime = new Date(time);
      return utcTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
    };
    const convertedTime = suvryimgTime();

    // Update the selected time state
    setSelectedTime((prev) => ({
      ...prev,
      [QuestionNumber]: convertedTime,
    }));

    // Check if a date is already selected
    const date = selectedDate[QuestionNumber];

    const updatedResponses = [...selectedResponses];
    const responseIndex = updatedResponses.findIndex((response) => response.questionid === questionId);

    if (responseIndex !== -1) {
      updatedResponses[responseIndex].response_text = date ? `${date}, ${convertedTime}` : convertedTime;
    } else {
      updatedResponses.push({
        response_text: date ? `${date}, ${convertedTime}` : convertedTime,
        responseid: responceID,
        responderid: responderId,
        surveyid: id,
        questionid: questionId,
        respondent_email: emailid || name || 'anonymous',
      });
    }

    setSelectedResponses(updatedResponses);

    // Check if either date or time is selected to mark the question as answered
    const isAnswered = !!date || !!convertedTime;
    setIsCompulsoryQuestionAnswered((prev) => {
      const newArr = [...prev];
      newArr[QuestionNumber] = isAnswered;
      return newArr;
    });

    // Remove the error message if either date or time is selected
    if (isAnswered) {
      setIsAnsSeleted([]);
      setIsAnsSeletedDateTime([]);
    }
  };

  // get os
  function getOperatingSystem() {
    const platform = navigator.platform.toLowerCase();
    const userAgent = navigator.userAgent.toLowerCase();

    if (platform.includes('win')) {
      const windowsVersion = /windows\snt\s([\d.]+)/.exec(userAgent);
      return windowsVersion ? `Windows ${windowsVersion[1]}` : 'Windows';
    }
    if (platform.includes('mac')) {
      const macVersion = /mac\sos\sx\s([\d._]+)/.exec(userAgent);
      return macVersion ? `Mac OS X ${macVersion[1].replace(/_/g, '.')}` : 'Mac';
    }
    if (platform.includes('linux')) {
      return 'Linux';
    }
    return 'Unknown';
  }

  // get browser
  function getBrowser() {
    const userAgent = navigator.userAgent;

    // Detect Chrome
    if (userAgent.includes('Chrome')) {
      return 'Chrome';
    }
    // Detect Firefox
    if (userAgent.includes('Firefox')) {
      return 'Firefox';
    }
    // Detect Safari
    if (userAgent.includes('Safari')) {
      return 'Safari';
    }
    // Detect Edge
    if (userAgent.includes('Edg')) {
      return 'Edge';
    }

    return 'Unknown';
  }
  // location
  const [responderlocation, setresponderLocation] = useState('Loading...');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error('Failed to fetch location data');
        }

        const data = await response.json();
        const { city, country_name: country } = data; // Using property shorthand
        setresponderLocation(`${city}, ${country}`);
      } catch (error) {
        console.error("Error getting user's location:", error);
        setresponderLocation('Unknown');
      }
    };

    fetchData();
  }, []);
  // email

  const otherres = {
    responderid: responderId,
    surveyid: id,
    questionid: '123',
    answer: 'rewq',
    responderemail: emailid || name || 'anonymous',
    time_spent: seconds,
    referrer: 'www.surveyslack.com',
    OS: getOperatingSystem(),
    browser: getBrowser(),
    location: responderlocation,
  };

  // const endpoint1 = API_ENDPOINTS.responderdata;
  // const response = await fetch(`${BASE_URL}${endpoint1}`, {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(otherres),
  // });

  return (
    <>
    
    <Helmet>
    <title>{title}</title>
    <meta
      name="description"
      content={description}
    />
    <meta
      name="keywords"
      content="SurveySlack Login, Access SurveySlack, Online Survey Platform Login, online surveys, survey tool, feedback platform"
    />
    <meta name="robots" content="index, follow" />
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="language" content="English" />

    <meta property="og:url" content="https://app.surveyslack.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="SurveySlack Login | Secure Access to Your Surveys" />
    <meta
      property="og:description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta property="og:image" content="https://surveyslack.com/wp-content/uploads/2024/07/Featured-Image.jpg" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="app.surveyslack.com" />
    <meta property="twitter:url" content="https://app.surveyslack.com/" />
    <meta name="twitter:title" content="SurveySlack Login | Secure Access to Your Surveys" />
    <meta
      name="twitter:description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta
      name="twitter:image"
      content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v4/Survey%20Slack/Streamline%20your%20survey%20creation%20process%20with%20ease./https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2F56188dc2-e3c3-4ce5-a8b1-1323953e37b9.jpg%3Ftoken%3DhOY-wLL-tV2Wb6eqlpzb3hUOqYMZbXQ3az2flBDqaSs%26height%3D800%26width%3D1200%26expires%3D33251249770/og.png"
    />
  </Helmet>
    <div className="h-100" style={styleObject}>
      <div className="surveyform-bg " style={{ backgroundImage: `url(${backgroungImg})` }}>
        {isWelcomesceen && surveyDetails.some((data) => data.welcome_msg) && (
          <div style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
            <CustomButton btname={nextBtn} />
          </div>
        )}
        {contentStyle === undefined
          ? 'loading....'
          : contentStyle.map((fontstyle, index) => (
              <div
                key={index}
                className="d-flex  h-100 align-items-center justify-content-center flex-column text-center row"
                style={{ overflowY: 'auto', marginTop: '30px', marginBottom: '30px' }}
              >
                {isWelcomesceen && surveyDetails.some((data) => data.welcome_msg) ? (
                  <div
                    className="col-12 col-md-12 col-lg-6 col-xl-5 col-xxl-4 align-items-start"
                    style={{
                      ...questioncss,
                      zIndex: '9',
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {surveyDetails.map((data, index) => (
                      <div key={index} className="landscapepreview">
                        {data.welcome_img && (
                          <div className="image-container animateimg">
                            <img alt="img" src={data.welcome_img} />
                          </div>
                        )}
                        {data.welcome_msg && (
                          <h3 className="text-center mt-4 welcomeMSG" style={styleObject}>
                            {data.welcome_msg}
                          </h3>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  // Directly render feedback page content here
                  surveyDetails.map((data, index) => (
                    <div
                      style={{
                        ...surveyScreenAnimate,
                      }}
                      key={index}
                      className={
                        showwelcome
                          ? 'welcomescreen '
                          : 'col-12 col-md-12  col-lg-8 col-xl-8 col-xxl-8 landscapepreviewforwelcomescreen'
                      }
                    >
                      <div
                        className="image-container"
                        style={{
                          margin: '56px auto 56px',
                          transition: 'object-position 0.3s ease 0s',
                          pointerEvents: 'auto',
                        }}
                      >
                        <img
                          className={isWelcomeVisible ? 'animateimg' : ''}
                          alt=""
                          src={data.logo}
                          style={{ opacity: 1 }}
                        />
                      </div>
                      <div
                        style={{
                          color: 'white',
                          display: 'block',
                          transition: 'transform 0.8s ease',
                          opacity: isWelcometext ? 1 : 0,
                          transform: isWelcometext ? 'translateY(-16%)' : 'translateY(0)',
                        }}
                        className="landscapepreviewfordivwelcomescreen"
                      >
                        <h1>
                          <span style={styleObject} className="surveytutle">
                            {data.survey_title}
                          </span>
                        </h1>
                        <p
                          style={{
                            ...styleObject,
                            fontSize: '22px',
                            margin: '0px auto ',
                            lineHeight: '34px',
                          }}
                          className = "cust-text"
                        >
                          {data.survey_description}
                        </p>
                        <div className="d-none d-sm-flex responsivebtn-main   align-items-center justify-content-center mt-3 mb-3">
                          {data.survey_enddate && new Date(data.survey_enddate) < new Date() ? (
                            <ReqAlert validator={isExpireSurvey} alertIndex={currentQuestionIndex} />
                          ) : (
                            <>
                              <Button
                                style={styleObject}
                                className="btnclass"
                                onClick={SendFeedback}
                                size="large"
                                variant="contained"
                              >
                                Give Feedback
                              </Button>
                              <div className="ms-2 d-none">
                                Press <strong>Enter ↵</strong>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="   d-flex d-sm-none responsivebtn-sub align-items-center justify-content-center  ">
                          {data.survey_enddate && new Date(data.survey_enddate) < new Date() ? (
                            <ReqAlert validator={isExpireSurvey} alertIndex={currentQuestionIndex} />
                          ) : (
                            <>
                              <Button
                                style={styleObject}
                                className="btnclass"
                                onClick={SendFeedback}
                                size="large"
                                variant="contained"
                              >
                                Give Feedback
                              </Button>
                              <div className="ms-2 d-none">
                                Press <strong>Enter ↵</strong>
                              </div>
                            </>
                          )}
                        </div>
                    </div>
                  ))
                )}

                {isrequiredemail && (
                  <div
                    className=" col-12 col-md-12 col-lg-6 col-xl-5 col-xxl-4 align-items-start"
                    style={{
                      ...questioncss,
                      ...styleObject,
                      zIndex: '9',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Form.Label htmlFor="inputPassword5">Enter Your Email-id</Form.Label>
                    <Form.Control
                      value={emailid}
                      onChange={handelEnterEmail}
                      type="email"
                      aria-describedby="passwordHelpBlock"
                    />
                    <CustomButton btname={SubmitEmailid} />

                    {reqalertemail && <ReqAlert validator={checkEmail} />}
                  </div>
                )}
                {isoptionalemail && (
                  <div
                    className=" col-12 col-md-12 col-lg-6 col-xl-5 col-xxl-4 align-items-start"
                    style={{
                      ...questioncss,
                      ...styleObject,
                      zIndex: '9',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Form.Label htmlFor="inputPassword5">Enter Your Full Name</Form.Label>
                    <Form.Control onChange={handleEnterName} type="email" aria-describedby="passwordHelpBlock" />
                    <CustomButton btname={Submitname} />
                  </div>
                )}
                {!successscreen && showQuestion && (
                  <div
                    className={`question-container  hello col-12 col-md-9 ${
                      currentQuestionIndex === 9 || currentQuestionIndex === 10
                        ? 'col-lg-9 col-xl-9 col-xxl-9'
                        : 'col-lg-9 col-xl-9 col-xxl-9'
                    } `}
                  >
                    {
                      <div
                        id="container"
                        className="d-flex userside-listing flex-column align-items-start w-100 scrollable-container"
                      >
                        {getNewOrder.map((option, QuestionNumber) => (
                          <>
                            {' '}
                            {displayMode === 'One Question at time' ? (
                              <div style={styleObject} className="w-100">
                                {/* multiple choice  */}
                                {option.question_type === 12 && (
                                  <div
                                    id={option.questionid}
                                    key={QuestionNumber}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4">
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div
                                      className="mb-4 row d-flex flex-column align-items-start"
                                      style={{
                                        listStyle: 'none',
                                        margin: '0',
                                        padding: '0',
                                      }}
                                    >
                                      {JSON.parse(option.answer).map((answer, ansIndex) => (
                                        <div
                                          key={ansIndex}
                                          className="position-relative  my-1 px-0"
                                          style={{ ...styleObject }}
                                        >
                                          <Form.Check
                                            className="optionradiobtn "
                                            style={{
                                              width: '95%',
                                             '--button-color': borderColor,

                                            }}
                                            inline
                                            checked={
                                              isMultipleselect[option.questionid] &&
                                              isMultipleselect[option.questionid][ansIndex]
                                            }
                                            onChange={() => {
                                              handelGetMcqValue(
                                                QuestionNumber,
                                                answer,
                                                option.questionid,
                                                ansIndex,
                                                option.multiple_answer
                                              );
                                            }}
                                            name={`group1-${QuestionNumber}`}
                                            type={option.multiple_answer === '1' ? 'checkbox' : 'radio'}
                                          />

                                          <div style={{ height: '2.5em', display: 'flex', alignItems: 'center' }}>
                                            <Chip
                                              className="optionnumber mx-2"
                                              label={String.fromCharCode(65 + ansIndex)}
                                            />{' '}
                                            {answer}
                                          </div>
                                        </div>
                                      ))}
                                      {/* <CustomButton btname={okbtn} /> */}
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  </div>
                                )}

                                {/* file upload code  */}
                                {option.question_type === 8 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div>
                                      {JSON.parse(option.answer)[0] === 'Image' ? (
                                        <form className="d-flex">
                                          <input
                                            type="file"
                                            id="imageInput"
                                            onChange={(event) =>
                                              handleImageUpload(event, QuestionNumber, option.questionid)
                                            }
                                            accept="image/*"
                                          />
                                          {error && <div style={{ color: 'red' }}>{error}</div>}
                                          {
                                            previewUrl ? (
                                              <img
                                                style={{
                                                  maxHeight: '200px',
                                                  maxWidth: '200px',
                                                  display: 'block', // Ensure the image is displayed
                                                }}
                                                src={previewUrl}
                                                alt="Preview"
                                              />
                                            ) : null /* Render nothing when previewUrl is null */
                                          }
                                        </form>
                                      ) : (
                                        <form>
                                          <input
                                            style={{ height: '50px' }}
                                            type="file"
                                            id="fileInput"
                                            onChange={(event) =>
                                              handleFileChange(event, QuestionNumber, option.questionid)
                                            }
                                            accept=".pdf,.doc,.docx,.txt,.ppt,.pptx"
                                          />
                                          {/* {selectedFile && selectedFile.name} */}
                                          {fileTypeError && <div style={{ color: 'red' }}>{fileTypeError}</div>}
                                        </form>
                                      )}
                                    </div>
                                    <div
                                      className="d-flex flex-column align-items-start"
                                      style={{ listStyle: 'none', margin: '0', padding: '0', fontSize: '12px' }}
                                    >
                                      {/* <CustomButton btname={okbtn} /> */}
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  </div>
                                )}

                                {/* linear scale code  */}
                                {option.question_type === 5 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div className="d-flex w-100 flex-column align-items-start">
                                      <div className="d-flex w-100 flex-row flex-wrap justify-content-center lineargap ">
                                        {[...Array(10)].map((_, index) => {
                                          const value = index + 1;
                                          return (
                                            JSON.parse(option.answer)[1] >= value && (
                                              <div
                                                key={value}
                                                className="position-relative my-1"
                                                style={{ margin: '5px' }}
                                              >
                                                <Form.Check
                                                  className="optionradiobtn "
                                                  style={{    '--button-color': borderColor,}}
                                                  inline
                                                  onChange={() =>
                                                    handelGetLinerdata(QuestionNumber, value, option.questionid)
                                                  }
                                                  name={`group1-${QuestionNumber}`}
                                                  type="radio"
                                                />
                                                <div
                                                
                                                  style={{
                                                
                                                    height: '2.5em',
                                                    width: '2em',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  {value}
                                                </div>
                                              </div>
                                            )
                                          );
                                        })}
                                      </div>
                                      <div style={{ fontSize: '16px' }} className="w-100  mt-2 mb-3 textlinear">
                                        <span>1→Miserable</span>
                                        <span>10→Delighted</span>
                                      </div>
                                    </div>
                                    {/* <CustomButton btname={okbtn} /> */}
                                    {isAnsSeleted.length > 0 && (
                                      <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                    )}
                                  </div>
                                )}

                                {option.question_type === 4 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                    key={QuestionNumber}
                                  >
                                    <h3 className="mb-4">
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 1 ? '*' : ''}
                                    </h3>
                                    <FormControl
                                      className="mb-3 ms-0 "
                                      style={{ borderRadius: '10px', width: '100%' }}
                                      sx={{ m: 1, minWidth: 150 }}
                                      size="large"
                                    >
                                      <Select
                                        style={{
                                          color: contentStyle?.[0]?.[1],
                                          fontFamily: contentStyle?.[0]?.[0],
                                        }}
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={dropdownval[QuestionNumber] || 'None'} // Set default value to 'None'
                                        onChange={(event) =>
                                          handelChangedropdown(QuestionNumber, event.target.value, option.questionid)
                                        }
                                      >
                                        {/* Default "None" option */}
                                        {/* <MenuItem
                                      style={{
                                        color: contentStyle?.[0]?.[1],
                                        fontFamily: contentStyle?.[0]?.[0],
                                      }}
                                      value="None"
                                    >
                                      None
                                    </MenuItem> */}

                                        {/* Render other options from JSON.parse(option.answer) */}
                                        {JSON.parse(option.answer).map((answer, index) => (
                                          <MenuItem
                                            style={{
                                              color: contentStyle?.[0]?.[1],
                                              fontFamily: contentStyle?.[0]?.[0],
                                            }}
                                            key={index}
                                            value={answer}
                                          >
                                            {answer}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    {isAnsSeleted.length > 0 && (
                                      <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                    )}
                                  </div>
                                )}

                                {/* rating code  */}

                                {option.question_type === 3 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1} → {option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <Box className="d-flex flex-column align-items-start">
                                      <div className="rating-container  d-flex w-100 justify-content-center flex-row align-items-center">
                                        {option.answer &&
                                          JSON.parse(option.answer)[3].map((label, index) => (
                                            <div className="position-relative   rating-item" key={index}>
                                              <IconButton
                                                onClick={() =>
                                                  handelRating(QuestionNumber, label, option.questionid, index)
                                                }
                                                className="labelbtn "
                                                aria-label="rate"
                                                size="large"
                                                style={{ color: styleObject.color }}
                                                name={`ratingButton${option.questionid}`}
                                              >
                                                {index <= (ratingvalue[QuestionNumber] ?? -1) ? (
                                                  JSON.parse(option.answer)[1] === '1' ? (
                                                    <ThumbUpAlt
                                                      style={{ color: 'inherit', opacity: 1 }}
                                                      fontSize="inherit"
                                                    />
                                                  ) : (
                                                    <AiFillStar
                                                      style={{ color: 'inherit', opacity: 1 }}
                                                      fontSize="inherit"
                                                    />
                                                  )
                                                ) : JSON.parse(option.answer)[1] === '1' ? (
                                                  <ThumbUpAlt
                                                    style={{ color: 'inherit', opacity: 0.2 }}
                                                    fontSize="inherit"
                                                  />
                                                ) : (
                                                  <AiFillStar
                                                    style={{ color: 'inherit', opacity: 0.2 }}
                                                    fontSize="inherit"
                                                  />
                                                )}
                                              </IconButton>
                                              {label && (
                                                <div style={{ color: 'inherit' }} className="labels">
                                                  {label}
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </Box>
                                  </div>
                                )}

                                {option.question_type === 6 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div style={{ margin: '10px' }}>
                                      <Slider
                                        aria-label="Temperature"
                                        valueLabelDisplay="auto"
                                        // Remove step prop to allow free selection of values
                                        onChange={(e, value) =>
                                          handleSliderChange(value, QuestionNumber, option.questionid)
                                        } // Update event handler
                                        min={Number(JSON.parse(option.answer)[0])}
                                        max={Number(JSON.parse(option.answer)[2])}
                                        style={{
                                          color: contentStyle?.[0]?.[1],
                                          height: '10px',
                                          width: '97%',
                                          marginLeft: '1.4%',
                                        }}
                                      />
                                      <div key={QuestionNumber} className="w-100 d-flex flex-column align-items-start">
                                        <div className=" d-flex justify-content-between mb-3 range-wrapper-value" style={{width:'98%',marginLeft:'1.4%'}}>
                                          {JSON.parse(option.answer).map((value, index) => (
                                            <span
                                              key={index}

                                              className={index === 1 ? 'range-sub-value' : ''}

                                              style={{
                                                color: contentStyle?.[0]?.[1],
                                                fontSize: '32px',
                                               
                                                textAlign: index === 0 ? 'left' : index === JSON.parse(option.answer).length - 1 ? 'right' : 'center',  
                                              }}
                                            >
                                              {value}
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                      {/* <CustomButton btname={okbtn} /> */}
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  </div>
                                )}

                                {/* short anser code  */}
                                {option.question_type === 2 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div>
                                      <div
                                        className="w-100 d-flex flex-column align-items-start"
                                        style={{ ...styleObject, cursor: 'pointer' }}
                                      >
                                        <Form.Control
                                          autoFocus // Add autoFocus here
                                          value={shortansvalue[QuestionNumber]}
                                          className="inputfeilds mb-4 focused"
                                          placeholder="Type your Short answer here .."
                                          maxLength={50}
                                          onChange={(e) => handelShortAnswer(e, QuestionNumber, option.questionid)}
                                        />
                                      </div>
                                      <p className="character-count text-end">
                                        {`${(shortansvalue[QuestionNumber] || '').length}/${50}`}
                                      </p>
                                      {/* <CustomButton btname={okbtn} disabled={!isShortAnswerFilled} /> */}
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  </div>
                                )}

                                {/* long-answer-code */}
                                {option.question_type === 1 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div>
                                      <div className="w-100 d-flex flex-column align-items-start">
                                        <Form.Control
                                          autoFocus
                                          maxLength={250}
                                          className="inputfeilds mb-4"
                                          placeholder="Type your answer here .."
                                          as="textarea"
                                          rows={1}
                                          value={text[QuestionNumber] || ''}
                                          onChange={(e) => handelLongAnswer(e, QuestionNumber, option.questionid)}
                                          style={{ ...styleObject, height: areaHeight, textTransform: 'none' }}
                                        />
                                      </div>
                                      <p className="character-count text-end">
                                        {`${(text[QuestionNumber] || '').length}/${250}`}
                                      </p>
                                      {/* <CustomButton btname={okbtn} /> */}
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  </div>
                                )}
                                {option.question_type === 9 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div>
                                      <div
                                        className="mb-5 d-flex flex-row align-items-start"
                                        style={{
                                          listStyle: 'none',
                                          margin: '0',
                                          padding: '0',
                                          gap: '30px',
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {JSON.parse(option.answer).map((getData, ansIndex) => (
                                          <div key={ansIndex} className="position-relative my-1">
                                            <Form.Check
                                              style={{
                                                height: '110px',
                                                aspectRatio: '1/1',
                                                textAlign: 'center',
                                                backgroundColor: '#ffffff24',
                                                position: 'relative',
                                                margin: '8px',
                                                width: 'unset',
                                                backgroundImage: `url(${getData.getImages})`,
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                borderRadius: '5px',
                                                backgroundRepeat: 'no-repeat',
                                              }}
                                              className="optionradiobtn img-selection-question"
                                              inline
                                              name={`group1-${QuestionNumber}`}
                                              type={option.multiple_answer === '1' ? 'checkbox' : 'radio'}
                                              onChange={(e) =>
                                                handelImgSelect(
                                                  QuestionNumber,
                                                  JSON.parse(option.answer)[ansIndex].getImages,
                                                  option.questionid,
                                                  option.multiple_answer === '1'
                                                )
                                              }
                                            />
                                            <Chip
                                              style={{
                                                position: 'absolute',
                                                bottom: '-35px',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                              }}
                                              className="optionnumber"
                                              label={getData.label ? getData.label : String.fromCharCode(65 + ansIndex)}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  </div>
                                )}

                                {/* Select Date & Time  */}
                                {option.question_type === 7 && (
                                  <div
                                    id={option.questionid}
                                    style={questioncss}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div style={{ gap: '15px' }} className="timeselect row">
                                      {JSON.parse(option.answer)[0] === 'true' && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <DemoContainer components={['DatePicker']}>
                                            Date
                                            <DatePicker
                                              onChange={(e) => dateSelect(QuestionNumber, e, option.questionid)}
                                              // format={JSON.parse(option.answer)[2]}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      )}
                                      {JSON.parse(option.answer)[1] === 'true' && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <DemoContainer components={['DatePicker']}>
                                            Time
                                            <MobileTimePicker
                                              onChange={(e) => selectTime(QuestionNumber, e, option.questionid)}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      )}
                                    </div>
                                    {isAnsSeleted.length > 0 && (
                                      <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                    )}
                                  </div>
                                )}

                                {/* matrix radio */}
                                {option.question_type === 10 && (
                                  <div
                                    id={option.questionid}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                    style={{
                                      ...questioncss,
                                      borderRadius: '10px',
                                      padding: '10px',
                                    }}
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 1 ? '*' : ''}
                                    </h3>
                                    <div className="table-responsive" style={{ styledComponent, marginBottom: '70px' }}>
                                      <table
                                        className="matrix-table"
                                        style={{ fontSize: '16px', color: contentStyle?.[0]?.[1] }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              className="sticky-row"
                                              style={{
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 3,
                                                backgroundColor: styleObject.backgroundColor,
                                              }}
                                            >
                                              &nbsp;
                                            </th>
                                            {JSON.parse(option.answer)[1].map((input) => (
                                              <th key={input.id} className="sticky-column">
                                                <div>{input.columnvalue}</div>
                                              </th>
                                            ))}
                                            {JSON.parse(option.answer)[2] === true && (
                                              <th className="sticky-header">N/A</th>
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {JSON.parse(option.answer)[0].map((input, rowIndex) => (
                                            <tr key={input.id} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
                                              <td
                                                className="sticky-row"
                                                style={{
                                                  position: 'sticky',
                                                  left: 0,
                                                  zIndex: 3,
                                                  backgroundColor: styleObject.backgroundColor,
                                                }}
                                              >
                                                {input.rowvalue}
                                              </td>
                                              {JSON.parse(option.answer)[1].map((getcolumn, index) => (
                                                <td key={getcolumn.id}>
                                                  <Form.Check
                                                    inline
                                                    onChange={() =>
                                                      handelSetMatrixmcq(
                                                        input.id,
                                                        JSON.parse(option.answer)[0].map((input) => ({
                                                          rowValue: input.rowvalue,
                                                          id: input.id,
                                                        })),
                                                        JSON.parse(option.answer)[1].map((input) => ({
                                                          columnValue: input.columnvalue,
                                                        })),
                                                        index,
                                                        option.questionid,
                                                        QuestionNumber,
                                                        'columnValue'
                                                      )
                                                    }
                                                    value={matrixMcq}
                                                    name={`group_row_${option.questionid}${input.id}`}
                                                    type="radio"
                                                    checked={selectedRadio[option.questionid]?.[input.id] === index}
                                                  />
                                                </td>
                                              ))}
                                              {JSON.parse(option.answer)[2] === true && (
                                                <td>
                                                  <Form.Check type="radio" />
                                                </td>
                                              )}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                      <div
                                        style={{
                                          textAlign: 'start',
                                          marginTop: '10px',
                                          marginBottom: '40px',
                                          position: 'sticky',
                                          width: '100%',
                                        }}
                                      >
                                        <span
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            styleObject,
                                            fontSize: 'small',
                                            cursor: 'pointer',
                                            position: 'sticky',
                                            left: '10px',
                                            zIndex: 3,
                                          }}
                                          onClick={handleClearSelections}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                              handleClearSelections();
                                            }
                                          }}
                                        >
                                          Clear All
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {/* {option.question_type === '10' && (
  <div
    id={option.questionid}
    className={QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
    style={{
      ...questioncss,
      backgroundColor: '#f0ffff4d',
      borderRadius: '10px',
      padding: '10px',
    }}
  >
    <h3 className="mb-4" key={QuestionNumber}>
      {QuestionNumber + 1}→{option.question_text}
      {option.compulsory === '0' || option.compulsory === null ? '' : '*'}
    </h3>
    <div className="table-responsive" style={{marginBottom:'70px'}}>
      <table className="matrix-table striped-table" style={{
                                          fontSize: '16px',
                                          color: contentStyle?.[0]?.[1],
                                        }}>
        <thead>
          <tr>
             
            {JSON.parse(option.answer)[1].map((input) => (
              <th key={input.id} className="sticky-column">
                <div>{input.columnvalue}</div>
              </th>
            ))}
            {JSON.parse(option.answer)[2] === true && (
              <th className="sticky-header">
                N/A
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {JSON.parse(option.answer)[0].map((input, rowIndex) => (
            <tr key={input.id} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td className="sticky-row">{input.rowvalue}</td>
              {JSON.parse(option.answer)[1].map((getcolumn) => (
                <td key={getcolumn.id}>
                  <Form.Check
                    inline
                    onChange={() =>
                      handelSetMatrixmcq(
                        input.id,
                        JSON.parse(option.answer)[0].map((input) => ({
                          rowValue: input.rowvalue,
                          id: input.id,
                        })),
                        JSON.parse(option.answer)[1].map((input) => ({
                          columnValue: input.columnvalue,
                        })),
                        index,
                        option.questionid,
                        QuestionNumber,
                        'columnValue'
                      )
                    }
                    value={matrixMcq}
                    name={`group_row_${option.questionid}${input.id}`}
                    type="radio"
                  />
                </td>
              ))}
              {JSON.parse(option.answer)[2] === true && (
                <td>
                  <Form.Check type="radio" />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {isAnsSeleted.length > 0 && <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />}
    </div>
  </div>
)} */}

                                {/* matrix checkbox */}
                                {/* {option.question_type === '11' && (
                                  <div
                                    id={option.questionid}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                    style={{
                                      ...questioncss,
                                      backgroundColor: '#f0ffff4d',
                                      borderRadius: '10px',
                                      padding: '10px',
                                    }}
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === '0' || option.compulsory === null ? '' : '*'}
                                    </h3>
                                    <div className="table-responsive">
                                      <Table responsive style={{ fontSize: '16px', color: contentStyle?.[0]?.[1] }}>
                                        <thead>
                                          <tr>
                                            <th style={{ border: 'none' }}>&nbsp;</th>
                                            {JSON.parse(option.answer)[1].map((input) => (
                                              <th
                                                key={input.id}
                                                style={{
                                                  fontWeight: '300',
                                                  verticalAlign: 'middle',
                                                  textAlign: 'center',
                                                  border: 'none',
                                                  maxWidth: '180px',
                                                  width: '180px',
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {input.checkboxcolumn}
                                              </th>
                                            ))}
                                            {JSON.parse(option.answer)[2] === true && (
                                              <th
                                                key="1"
                                                style={{
                                                  textAlign: 'center',
                                                  border: 'none',
                                                  maxWidthwidth: '300px',
                                                  width: '200px',
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                N/A
                                              </th>
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {JSON.parse(option.answer)[0].map((input) => (
                                            <tr key={input.id}>
                                              <th
                                                style={{
                                                  fontWeight: '300',
                                                  border: 'none',
                                                  verticalAlign: 'middle',
                                                  textAlign: 'start',
                                                  textTransform: 'capitalize',
                                                  maxWidth: '250px',
                                                  width: '250px',
                                                }}
                                              >
                                                {input.checkboxrow}
                                              </th>
                                              {JSON.parse(option.answer)[1].map((getcolumn, index) => (
                                                <td
                                                  key={getcolumn.id}
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidth: '180px',
                                                    width: '180px',
                                                  }}
                                                >
                                                  {' '}
                                        
                                                  <Form.Check
                                                    inline
                                                  
                                                    onChange={() =>
                                                      SelectMatrixCheckbox(
                                                        input.id,
                                                        JSON.parse(option.answer)[0].map((input) => ({
                                                          rowValue: input.checkboxrow,
                                                          id: input.id,
                                                        })),
                                                        JSON.parse(option.answer)[1].map((input) => ({
                                                          columnValue: input.checkboxcolumn,
                                                        })),
                                                        index,
                                                        option.questionid,
                                                        QuestionNumber
                                                      )
                                                    }
                                                    value={martixCheckbox}
                                                    name={`group_row_${option.questionid}${input.id}`}
                                                    type="checkbox"
                                                  />
                                                </td>
                                              ))}
                                              {JSON.parse(option.answer)[2] === true && (
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidthwidth: '300px',
                                                    width: '200px',
                                                  }}
                                                  key="1"
                                                >
                                                  {' '}
                                                  <Form.Check type="checkbox" />
                                                </td>
                                              )}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                     
                                      {isAnsSeleted.length > 0 &&  (
    <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
  )}
                                    </div>
                                  </div>
                                )} */}

                                {option.question_type === 11 && (
                                  <div
                                    id={option.questionid}
                                    className={
                                      QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                    }
                                    style={{
                                      ...questioncss,
                                      // backgroundColor: '#f0ffff4d',
                                      borderRadius: '10px',
                                      padding: '10px',
                                    }}
                                  >
                                    <h3 className="mb-4" key={QuestionNumber}>
                                      {QuestionNumber + 1}→{option.question_text}
                                      {option.compulsory === 0 ? '*' : ''}
                                    </h3>
                                    <div className="table-responsive" style={{ styledComponent, marginBottom: '70px' }}>
                                      <table
                                        className="matrix-table "
                                        style={{ fontSize: '16px', color: contentStyle?.[0]?.[1] }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              className="sticky-row"
                                              style={{
                                                position: 'sticky',
                                                left: 0,
                                                zIndex: 3,
                                                backgroundColor: styleObject.backgroundColor,
                                              }}
                                            >
                                              &nbsp;
                                            </th>
                                            {JSON.parse(option.answer)[1].map((input) => (
                                              <th key={input.id} className="sticky-column">
                                                {input.checkboxcolumn}
                                              </th>
                                            ))}
                                            {JSON.parse(option.answer)[2] === true && (
                                              <th className="sticky-header">N/A</th>
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {JSON.parse(option.answer)[0].map((input, rowIndex) => (
                                            <tr key={input.id} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
                                              <td
                                                className="sticky-row"
                                                style={{
                                                  position: 'sticky',
                                                  left: 0,
                                                  zIndex: 3,
                                                  backgroundColor: styleObject.backgroundColor,
                                                }}
                                              >
                                                {input.checkboxrow}
                                              </td>
                                              {JSON.parse(option.answer)[1].map((getcolumn, index) => (
                                                <td key={getcolumn.id}>
                                                  <Form.Check
                                                    inline
                                                    onChange={() =>
                                                      SelectMatrixCheckbox(
                                                        input.id,
                                                        JSON.parse(option.answer)[0].map((input) => ({
                                                          rowValue: input.checkboxrow,
                                                          id: input.id,
                                                        })),
                                                        JSON.parse(option.answer)[1].map((input) => ({
                                                          columnValue: input.checkboxcolumn,
                                                        })),
                                                        index,
                                                        option.questionid,
                                                        QuestionNumber
                                                      )
                                                    }
                                                    checked={selectedCheckboxes.includes(`${input.id}-${index}`)} // Check if checkbox is selected
                                                    name={`group_row_${option.questionid}${input.id}`}
                                                    type="checkbox"
                                                  />
                                                </td>
                                              ))}
                                              {JSON.parse(option.answer)[2] === true && (
                                                <td>
                                                  <Form.Check type="checkbox" />
                                                </td>
                                              )}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {isAnsSeleted.length > 0 && (
                                        <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                      )}
                                      {/* <div style={{ textAlign: 'start', marginTop: '10px', marginBottom: '40px', position: 'sticky', top: '0', zIndex: '3', backgroundColor: '#ffffff', padding: '10px' }}>
  <button type="button" style={{ color: 'green', fontSize: 'medium', border: 'none', background: 'none', cursor: 'pointer' }} onClick={handleClearSelectionsCheckBox}>
    Clear All
  </button>
</div> */}
                                      <div
                                        style={{
                                          textAlign: 'start',
                                          marginTop: '10px',
                                          marginBottom: '40px',
                                          position: 'sticky',
                                          width: '100%',
                                        }}
                                      >
                                        <span
                                          role="button" // Adding role="button" to make it accessible as a button
                                          tabIndex={0} // Adding tabIndex={0} to make it focusable
                                          style={{
                                            styleObject,
                                            fontSize: 'small',
                                            cursor: 'pointer',
                                            position: 'sticky',
                                            left: '10px',
                                            zIndex: 3,
                                          }}
                                          onClick={handleClearSelectionsCheckBox}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                              handleClearSelectionsCheckBox();
                                            }
                                          }}
                                        >
                                          Clear All
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div style={styleObject} className="w-100">
                                <div key={QuestionNumber} style={styleObject} className="w-100 mb-4">
                                  <h3 className="mb-4">
                                    {QuestionNumber + 1}. {option.question_text}
                                    {option.compulsory === 0 || option.compulsory === null ? '' : ' *'}
                                  </h3>

                                  {/* Multiple Choice */}
                                  {option.question_type === 12 && (
                                    <div id={option.questionid} style={questioncss}>
                                      <div
                                        className="mb-4 row d-flex flex-column align-items-start"
                                        style={{
                                          listStyle: 'none',
                                          margin: '0',
                                          padding: '0',
                                        }}
                                      >
                                        {JSON.parse(option.answer).map((answer, ansIndex) => (
                                          <div key={ansIndex} className="position-relative my-1 px-0">
                                            <Form.Check
                                              className="optionradiobtn"
                                              style={{ width: '95%' }}
                                              inline
                                              checked={
                                                isMultipleselect[option.questionid] &&
                                                isMultipleselect[option.questionid][ansIndex]
                                              }
                                              onChange={() => {
                                                handelGetMcqValue(
                                                  index,
                                                  answer,
                                                  option.questionid,
                                                  ansIndex,
                                                  option.multiple_answer
                                                );
                                              }}
                                              name={`group1-${index}`}
                                              type={option.multiple_answer === '1' ? 'checkbox' : 'radio'}
                                            />
                                            <div style={{ height: '2.5em', display: 'flex', alignItems: 'center' }}>
                                              <Chip
                                                className="optionnumber mx-2"
                                                label={String.fromCharCode(65 + ansIndex)}
                                              />{' '}
                                              {answer}
                                            </div>
                                          </div>
                                        ))}
                                        {isAnsSeletedmultipleChoice.length > 0 && (
                                          <ReqAlert
                                            validator={isAnsSeletedmultipleChoice}
                                            alertIndex={currentQuestionIndex}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {/* short answer */}
                                  {option.question_type === 2 && (
                                    <div id={option.questionid} style={questioncss}>
                                      <div className="w-100 d-flex flex-column align-items-start">
                                        <Form.Control
                                          style={{ ...styleObject, textTransform: 'none' }}
                                          value={shortansvalue[option.questionid]}
                                          className="inputfeilds mb-4 focused"
                                          placeholder="Type your Short answer here .."
                                          maxLength={50}
                                          onChange={(e) => handelShortAnswer(e, index, option.questionid)}
                                          autoFocus
                                        />
                                      </div>
                                      {isAnsSeletedShortAns.length > 0 && (
                                        <ReqAlert validator={isAnsSeletedShortAns} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  )}

                                  {/* Long Answer */}
                                  {option.question_type === 1 && (
                                    <div id={option.questionid} style={questioncss}>
                                      <div className="w-100 d-flex flex-column align-items-start">
                                        <Form.Control
                                          maxLength={250}
                                          className="inputfeilds mb-4"
                                          placeholder="Type your answer here .."
                                          as="textarea"
                                          rows={1}
                                          value={text[option.questionid] || ''}
                                          onChange={(e) => handelLongAnswer(e, index, option.questionid)}
                                          style={{ ...styleObject, height: areaHeight, textTransform: 'none' }}
                                          autoFocus
                                          minRows={2}
                                          maxRows={4}
                                        />
                                      </div>
                                      {isAnsSeletedLongAns.length > 0 && (
                                        <ReqAlert validator={isAnsSeletedLongAns} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  )}

                                  {/* dropdown code */}
                                  {option.question_type === 4 && (
                                    <div id={option.questionid} style={questioncss}>
                                      <FormControl
                                        className="mb-3 ms-0"
                                        style={{ borderRadius: '10px', width: '90%' }}
                                        sx={{ m: 1, minWidth: 150 }}
                                        size="large"
                                      >
                                        <Select
                                          style={{
                                            color: contentStyle?.[0]?.[1],
                                            fontFamily: contentStyle?.[0]?.[0],
                                          }}
                                          labelId="demo-select-small-label"
                                          id="demo-select-small"
                                          value={dropdownval[index] || 'None'} // Set default value to 'None'
                                          onChange={(event) =>
                                            handelChangedropdown(index, event.target.value, option.questionid)
                                          }
                                        >
                                          <MenuItem
                                            style={{
                                              color: contentStyle?.[0]?.[1],
                                              fontFamily: contentStyle?.[0]?.[0],
                                            }}
                                            value="None"
                                          >
                                            None
                                          </MenuItem>
                                          {JSON.parse(option.answer).map((answer, ansIndex) => (
                                            <MenuItem
                                              style={{
                                                color: contentStyle?.[0]?.[1],
                                                fontFamily: contentStyle?.[0]?.[0],
                                              }}
                                              key={ansIndex}
                                              value={answer}
                                            >
                                              {answer}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      {isAnsSeletedDropdown.length > 0 && (
                                        <ReqAlert validator={isAnsSeletedDropdown} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  )}

                                  {/* rating code */}
                                  {option.question_type === 3 && (
                                    <div
                                      id={option.questionid}
                                      style={questioncss}
                                      className={
                                        QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                      }
                                    >
                                      <h3 className="mb-4" key={QuestionNumber}>
                                        {QuestionNumber + 1} → {option.question_text}
                                        {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                      </h3>
                                      <Box className="d-flex flex-column align-items-start">
                                        <div className="rating-container d-flex w-100 justify-content-center flex-row align-items-center">
                                          {option.answer &&
                                            JSON.parse(option.answer)[3].map((label, index) => (
                                              <div className="position-relative rating-item" key={index}>
                                                <IconButton
                                                  onClick={() =>
                                                    handelRating(QuestionNumber, label, option.questionid, index)
                                                  }
                                                  className="labelbtn"
                                                  aria-label="rate"
                                                  size="large"
                                                  name={`ratingButton${option.questionid}`}
                                                >
                                                  {index <= (ratingvalue[QuestionNumber] ?? -1) ? (
                                                    JSON.parse(option.answer)[1] === '1' ? (
                                                      <ThumbUpAlt
                                                        style={{ color: 'inherit', opacity: 1 }}
                                                        fontSize="inherit"
                                                      />
                                                    ) : (
                                                      <AiFillStar
                                                        style={{ color: 'inherit', opacity: 1 }}
                                                        fontSize="inherit"
                                                      />
                                                    )
                                                  ) : JSON.parse(option.answer)[1] === '1' ? (
                                                    <ThumbUpAlt
                                                      style={{ color: 'inherit', opacity: 0.5 }}
                                                      fontSize="inherit"
                                                    />
                                                  ) : (
                                                    <AiFillStar
                                                      style={{ color: 'inherit', opacity: 0.5 }}
                                                      fontSize="inherit"
                                                    />
                                                  )}
                                                </IconButton>
                                                {label && (
                                                  <div style={{ color: 'inherit' }} className="labels">
                                                    {label}
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                        </div>
                                        {isAnsSeleted.length > 0 && (
                                          <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                        )}
                                      </Box>
                                    </div>
                                  )}

                                  {/* linear scale code  */}
                                  {option.question_type === 5 && (
                                    <div
                                      id={option.questionid}
                                      style={questioncss}
                                      className={index === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
                                    >
                                      <div className="d-flex w-100 flex-column align-items-start">
                                        <div className="d-flex w-100 flex-row flex-wrap justify-content-center gap-2">
                                          {[...Array(10)].map((_, index) => {
                                            const value = index + 1;
                                            return (
                                              JSON.parse(option.answer)[1] >= value && (
                                                <div key={value} className="position-relative my-1">
                                                  <Form.Check
                                                    className="optionradiobtn"
                                                    inline
                                                    onChange={() =>
                                                      handelGetLinerdata(QuestionNumber, value, option.questionid)
                                                    }
                                                    name={`group1-${QuestionNumber}`}
                                                    type="radio"
                                                  />
                                                  <div className="linearvalues">{value}</div>
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                        <div
                                          style={{ fontSize: '16px' }}
                                          className="w-100 d-flex justify-content-between mt-2 mb-3"
                                        >
                                          <span>Miserable</span>
                                          <span>Delighted</span>
                                        </div>
                                      </div>
                                      {/* <CustomButton btname={okbtn} /> */}
                                      {isAnsSeletedLinear.length > 0 && (
                                        <ReqAlert validator={isAnsSeletedLinear} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  )}

                                  {/* Slider Code */}
                                  {option.question_type === 6 && (
                                    <div
                                      id={option.questionid}
                                      style={questioncss}
                                      className={index === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
                                    >
                                      <div>
                                        <Slider
                                          aria-label="Temperature"
                                          valueLabelDisplay="auto"
                                          // Remove step prop to allow free selection of values
                                          onChange={(e, value) => handleSliderChange(value, index, option.questionid)} // Update event handler
                                          min={Number(JSON.parse(option.answer)[0])}
                                          max={Number(JSON.parse(option.answer)[2])}
                                          style={{ color: '#3d3f40', height: '10px' }}
                                        />
                                        <div key={index} className="w-100 d-flex flex-column align-items-start">
                                          <div className="w-100 d-flex justify-content-between mb-3">
                                            {JSON.parse(option.answer).map((value, valueIndex) => (
                                              <span
                                                key={valueIndex}
                                                style={{
                                                  color: contentStyle?.[0]?.[1],
                                                  fontSize: '32px',
                                                }}
                                              >
                                                {value}
                                              </span>
                                            ))}
                                          </div>
                                        </div>
                                        {/* <CustomButton btname={okbtn} /> */}
                                        {isAnsSeletedRangeSlider.length > 0 && (
                                          <ReqAlert
                                            validator={isAnsSeletedRangeSlider}
                                            alertIndex={currentQuestionIndex}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {/* file upload code  */}
                                  {option.question_type === 8 && (
                                    <div
                                      id={option.questionid}
                                      style={questioncss}
                                      className={
                                        QuestionNumber === currentQuestionIndex ? 'showquestion' : 'hidequestion'
                                      }
                                    >
                                      <h3 className="mb-4" key={QuestionNumber}>
                                        {QuestionNumber + 1}→{option.question_text}
                                        {option.compulsory === 0 || option.compulsory === null ? '' : '*'}
                                      </h3>
                                      <div>
                                        {JSON.parse(option.answer)[0] === 'Image' ? (
                                          <form className="d-flex">
                                            <input
                                              type="file"
                                              id="imageInput"
                                              onChange={(event) =>
                                                handleImageUpload(event, QuestionNumber, option.questionid)
                                              }
                                              accept="image/*"
                                            />
                                            {error && <div style={{ color: 'red' }}>{error}</div>}
                                            {
                                              previewUrl ? (
                                                <img
                                                  style={{
                                                    maxHeight: '200px',
                                                    maxWidth: '200px',
                                                    display: 'block', // Ensure the image is displayed
                                                  }}
                                                  src={previewUrl}
                                                  alt="Preview"
                                                />
                                              ) : null /* Render nothing when previewUrl is null */
                                            }
                                          </form>
                                        ) : (
                                          <form>
                                            <input
                                              style={{ height: '50px' }}
                                              type="file"
                                              id="fileInput"
                                              onChange={(event) =>
                                                handleFileChange(event, QuestionNumber, option.questionid)
                                              }
                                              accept=".pdf,.doc,.docx,.txt,.ppt,.pptx"
                                            />
                                            {/* {selectedFile && selectedFile.name} */}
                                            {fileTypeError && <div style={{ color: 'red' }}>{fileTypeError}</div>}
                                          </form>
                                        )}
                                      </div>
                                      <div
                                        className="d-flex flex-column align-items-start"
                                        style={{ listStyle: 'none', margin: '0', padding: '0', fontSize: '12px' }}
                                      >
                                        {/* <CustomButton btname={okbtn} /> */}
                                        {isAnsSeleted.length > 0 && (
                                          <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {/* date & time */}
                                  {option.question_type === 7 && (
                                    <div
                                      id={option.questionid}
                                      style={questioncss}
                                      className={index === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
                                    >
                                      <div style={{ gap: '15px' }} className="timeselect row">
                                        {JSON.parse(option.answer)[0] === 'true' && (
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                              Date
                                              <DatePicker
                                                onChange={(e) => dateSelect(QuestionNumber, e, option.questionid)}
                                                format={JSON.parse(option.answer)[2]}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        )}
                                        {JSON.parse(option.answer)[1] === 'true' && (
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                              Time
                                              <MobileTimePicker
                                                onChange={(e) => selectTime(QuestionNumber, e, option.questionid)}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                        )}
                                      </div>
                                      {isAnsSeletedDateTime.length > 0 && (
                                        <ReqAlert validator={isAnsSeletedDateTime} alertIndex={currentQuestionIndex} />
                                      )}
                                    </div>
                                  )}

                                  {/* Image Choice */}

                                  {option.question_type === 9 && (
                                    <div
                                      id={option.questionid}
                                      style={questioncss}
                                      className={index === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
                                    >
                                      <div>
                                        <div
                                          className="mb-5 d-flex flex-row align-items-start"
                                          style={{
                                            listStyle: 'none',
                                            margin: '0',
                                            padding: '0',
                                            gap: '30px',
                                            flexWrap: 'wrap',
                                          }}
                                        >
                                          {JSON.parse(option.answer).map((getData, ansIndex) => (
                                            <div key={ansIndex} className="position-relative my-1">
                                              <Form.Check
                                                style={{
                                                  height: '110px',
                                                  aspectRatio: '1/1',
                                                  textAlign: 'center',
                                                  backgroundColor: '#ffffff24',
                                                  position: 'relative',
                                                  margin: '8px',
                                                  width: 'unset',
                                                  backgroundImage: `url(${getData.getImages})`,
                                                  backgroundSize: 'contain',
                                                  backgroundPosition: 'center',
                                                  borderRadius: '5px',
                                                  backgroundRepeat: 'no-repeat',
                                                }}
                                                className="optionradiobtn img-selection-question"
                                                inline
                                                name={`group1-${QuestionNumber}`}
                                                type={option.multiple_answer === '1' ? 'checkbox' : 'radio'}
                                                onChange={(e) =>
                                                  handelImgSelect(
                                                    QuestionNumber,
                                                    JSON.parse(option.answer)[ansIndex].getImages,
                                                    option.questionid,
                                                    option.multiple_answer === '1'
                                                  )
                                                }
                                              />
                                              <Chip
                                                style={{
                                                  position: 'absolute',
                                                  bottom: '-35px',
                                                  left: '50%',
                                                  transform: 'translate(-50%, -50%)',
                                                }}
                                                className="optionnumber"
                                                label={String.fromCharCode(65 + ansIndex)}
                                              />
                                            </div>
                                          ))}
                                        </div>
                                        {/* <CustomButton btname={okbtn} /> */}
                                        {isAnsSeletedImageChoice.length > 0 && (
                                          <ReqAlert
                                            validator={isAnsSeletedImageChoice}
                                            alertIndex={currentQuestionIndex}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {/* matrix radio */}
                                  {option.question_type === 10 && (
                                    <div
                                      id={option.questionid}
                                      className={index === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
                                      style={{
                                        ...questioncss,
                                        backgroundColor: '#f0ffff4d',
                                        borderRadius: '10px',
                                        padding: '10px',
                                      }}
                                    >
                                      <div className="table-responsive" style={{ marginBottom: '70px' }}>
                                        <Table
                                          responsive
                                          style={{
                                            fontSize: '16px',
                                            color: contentStyle?.[0]?.[1],
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th style={{ border: 'none' }}>&nbsp;</th>
                                              {JSON.parse(option.answer)[1].map((input, index) => (
                                                <th
                                                  key={input.id}
                                                  style={{
                                                    fontWeight: '300',
                                                    verticalAlign: 'middle',
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidth: '180px',
                                                    width: '180px',
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {input.columnvalue}
                                                </th>
                                              ))}
                                              {JSON.parse(option.answer)[2] === true && (
                                                <th
                                                  key="1"
                                                  style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidthwidth: '300px',
                                                    width: '200px',
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  N/A
                                                </th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {JSON.parse(option.answer)[0].map((input) => (
                                              <tr key={input.id}>
                                                <th
                                                  style={{
                                                    fontWeight: '300',
                                                    border: 'none',
                                                    verticalAlign: 'middle',
                                                    textAlign: 'start',
                                                    textTransform: 'capitalize',
                                                    maxWidth: '250px',
                                                    width: '250px',
                                                  }}
                                                >
                                                  {input.rowvalue}
                                                </th>

                                                {JSON.parse(option.answer)[1].map((getcolumn, index) => (
                                                  <td
                                                    key={getcolumn.id}
                                                    style={{
                                                      verticalAlign: 'middle',
                                                      textAlign: 'center',
                                                      border: 'none',
                                                      maxWidth: '180px',
                                                      width: '180px',
                                                    }}
                                                  >
                                                    {/* {input.id} */}
                                                    <Form.Check
                                                      inline
                                                      onChange={() =>
                                                        handelSetMatrixmcq(
                                                          input.id,
                                                          JSON.parse(option.answer)[0].map((input) => ({
                                                            rowValue: input.rowvalue,
                                                            id: input.id,
                                                          })),
                                                          JSON.parse(option.answer)[1].map((input) => ({
                                                            columnValue: input.columnvalue,
                                                          })),
                                                          index,
                                                          option.questionid,
                                                          QuestionNumber,
                                                          'columnValue'
                                                        )
                                                      }
                                                      value={matrixMcq}
                                                      name={`group_row_${option.questionid}${input.id}`}
                                                      type="radio"
                                                    />
                                                  </td>
                                                ))}
                                                {JSON.parse(option.answer)[2] === true && (
                                                  <td
                                                    style={{
                                                      textAlign: 'center',
                                                      border: 'none',
                                                      maxWidthwidth: '300px',
                                                      width: '200px',
                                                    }}
                                                    key="1"
                                                  >
                                                    {' '}
                                                    <Form.Check type="radio" />
                                                  </td>
                                                )}
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                        {/* <CustomButton btname={okbtn} /> */}
                                        {isAnsSeletedforMatrixRadio.length > 0 && (
                                          <ReqAlert
                                            validator={isAnsSeletedforMatrixRadio}
                                            alertIndex={currentQuestionIndex}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {/* matrix checkbox */}
                                  {option.question_type === 11 && (
                                    <div
                                      id={option.questionid}
                                      className={index === currentQuestionIndex ? 'showquestion' : 'hidequestion'}
                                      style={{
                                        ...questioncss,
                                        backgroundColor: '#f0ffff4d',
                                        borderRadius: '10px',
                                        padding: '10px',
                                      }}
                                    >
                                      <div className="table-responsive" style={{ marginBottom: '70px' }}>
                                        <Table responsive style={{ fontSize: '16px', color: contentStyle?.[0]?.[1] }}>
                                          <thead>
                                            <tr>
                                              <th style={{ border: 'none' }}>&nbsp;</th>
                                              {JSON.parse(option.answer)[1].map((input) => (
                                                <th
                                                  key={input.id}
                                                  style={{
                                                    fontWeight: '300',
                                                    verticalAlign: 'middle',
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidth: '180px',
                                                    width: '180px',
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {input.checkboxcolumn}
                                                </th>
                                              ))}
                                              {JSON.parse(option.answer)[2] === true && (
                                                <th
                                                  key="1"
                                                  style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    maxWidthwidth: '300px',
                                                    width: '200px',
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  N/A
                                                </th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {JSON.parse(option.answer)[0].map((input) => (
                                              <tr key={input.id}>
                                                <th
                                                  style={{
                                                    fontWeight: '300',
                                                    border: 'none',
                                                    verticalAlign: 'middle',
                                                    textAlign: 'start',
                                                    textTransform: 'capitalize',
                                                    maxWidth: '250px',
                                                    width: '250px',
                                                  }}
                                                >
                                                  {input.checkboxrow}
                                                </th>
                                                {JSON.parse(option.answer)[1].map((getcolumn, index) => (
                                                  <td
                                                    key={getcolumn.id}
                                                    style={{
                                                      verticalAlign: 'middle',
                                                      textAlign: 'center',
                                                      border: 'none',
                                                      maxWidth: '180px',
                                                      width: '180px',
                                                    }}
                                                  >
                                                    {' '}
                                                    {/* {input.id} */}
                                                    <Form.Check
                                                      inline
                                                      // onChange={SelectMatrixCheckbox}
                                                      // value={martixCheckbox}
                                                      // onChange={() => {
                                                      //   showReqAlert(false);
                                                      //   // setEnterd(true);
                                                      // }}
                                                      onChange={() =>
                                                        SelectMatrixCheckbox(
                                                          input.id,
                                                          JSON.parse(option.answer)[0].map((input) => ({
                                                            rowValue: input.checkboxrow,
                                                            id: input.id,
                                                          })),
                                                          JSON.parse(option.answer)[1].map((input) => ({
                                                            columnValue: input.checkboxcolumn,
                                                          })),
                                                          index,
                                                          option.questionid,
                                                          QuestionNumber
                                                        )
                                                      }
                                                      value={martixCheckbox}
                                                      name={`group_row_${option.questionid}${input.id}`}
                                                      type="checkbox"
                                                    />
                                                  </td>
                                                ))}
                                                {JSON.parse(option.answer)[2] === true && (
                                                  <td
                                                    style={{
                                                      textAlign: 'center',
                                                      border: 'none',
                                                      maxWidthwidth: '300px',
                                                      width: '200px',
                                                    }}
                                                    key="1"
                                                  >
                                                    {' '}
                                                    <Form.Check type="checkbox" />
                                                  </td>
                                                )}
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                        {/* <CustomButton btname={okbtn} /> */}
                                        {isAnsSeleted.length > 0 && (
                                          <ReqAlert validator={isAnsSeleted} alertIndex={currentQuestionIndex} />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    }

                    {/* <div
 className='NextPrevbtn'
>
{displayMode === 'One Question at time' ? (
  <>
    {currentQuestionIndex > 0 && (
      <>
        <CustomButton className='setbuttons' btname={prevbtn} /> &nbsp; &nbsp;
      </>
    )}
    {currentQuestionIndex === filteredQuestionList.length - 1 ? (
      <CustomButton className='setbuttons'
        btname={sendbtn}
        disabled={option.compulsory === '1' && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
      />
    ) : (
      <CustomButton className='setbuttons'
        btname={nextbtn}
        disabled={option.compulsory === '1' && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
      />
    )}
  </>
) : (
  <CustomButton
    btname={sendbtn}
    disabled={option.compulsory === '1' && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
  />
)} */}
                    <div className="NextPrevbtn" style={styleObject}>
                      {displayMode === 'One Question at time' ? (
                        <>
                          {currentQuestionIndex > 0 && (
                            <>
                              <Button
                                style={{ color: styleObject.color }}
                                className="btnclass "
                                onClick={handlePrev} // Replace with your previous button handler
                                disabled={false} // Adjust this if necessary
                              >
                                prev
                              </Button>
                              &nbsp; &nbsp;
                            </>
                          )}
                          {currentQuestionIndex === filteredQuestionList.length - 1 ? (
                            <Button
                              style={{ color: styleObject.color }}
                              className="btnclass"
                              onClick={handelpostRes} // Replace with your send button handler
                              disabled={option.compulsory === 1 && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
                            >
                              send
                            </Button>
                          ) : (
                            <Button
                              style={{ color: styleObject.color, marginLeft: 'auto' }}
                              className="btnclass "
                              onClick={(e) => {
                                if (!called) {
                                  checkIsEmptyCondition(currentQuestionId, (isValidFromCheck) => {
                                    setValidate(isValidFromCheck);
                                    setTimeout(() => {
                                      const validateCheck = isValidFromCheck; // Directly capture the value
                                      console.log('Validate state before handling:', validateCheck);
                                      if (validateCheck) {
                                        console.log('Calling handleNextQuestionBranching');
                                        handleNextQuestionBranching(e);
                                      } else {
                                        console.log('Calling handleNextQuestion');
                                        handleNextQuestion(e);
                                      }
                                    }, 0);
                                  });
                                } else {
                                  setTimeout(() => {
                                    const validateCheck = validate; // Capture the current validate state
                                    console.log('Validate state with called before handling:', validateCheck);
                                    if (validateCheck) {
                                      console.log('Calling handleNextQuestionBranching');
                                      handleNextQuestionBranching(e);
                                    } else {
                                      console.log('Calling handleNextQuestion');
                                      handleNextQuestion(e);
                                    }
                                  }, 0);
                                }
                              }}
                              disabled={option.compulsory === 1 && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
                            >
                              Next
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          className="btnclass"
                          onClick={handelpostRes} // Replace with your send button handler
                          disabled={option.compulsory === 1 && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
                        >
                          send
                        </Button>
                      )}
                    </div>

                    {/* <CustomButton btname={prevbtn} /> &nbsp; &nbsp;
                      {currentQuestionIndex === filteredQuestionList.length - 1 ? (
                        <CustomButton
                          btname={sendbtn}
                          disabled={option.compulsory === '1' && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
                        />
                      ) : (
                        <CustomButton
                          btname={nextbtn}
                          disabled={option.compulsory === '1' && !isCompulsoryQuestionAnswered[currentQuestionIndex]}
                        />
                      )} */}
                  </div>
                  // </div>
                )}
                {successscreen && (
                  <div className="position-absolute">
                    {surveyDetails.map((data) => (
                      <>
                        <div
                          style={{ ...questioncss, ...styleObject }}
                          className="w-100 d-flex justify-content-center text-center "
                        >
                          <div className=" col-12 col-md-12  col-lg-6 col-xl-6 col-xxl-5">
                            <div
                              className="image-container"
                              style={{
                                margin: '0px auto 56px',
                                transition: 'object-position 0.3s ease 0s',
                                pointerEvents: 'auto',
                              }}
                            >
                              <img
                                className={isWelcomeVisible ? 'animateimg' : ''}
                                alt=""
                                src={data.success_img}
                                style={{ opacity: 1 }}
                              />
                            </div>
                            <div
                              style={{
                                color: 'white',
                                display: 'block',
                                transition: 'transform 0.8s ease',
                                opacity: isWelcometext ? 1 : 0,
                                transform: isWelcometext ? 'translateY(-16%)' : 'translateY(0)',
                              }}
                            >
                              <h3 className="text-center" style={styleObject}>
                                {data.success_msg}
                              </h3>
                              {/* <p style={styleObject}></p> */}
                              <Stack
                                direction="row"
                                className="d-none"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton aria-label="delete" size="large">
                                  <FaTwitter />
                                </IconButton>
                                <IconButton aria-label="delete" size="large">
                                  <FaInstagram />
                                </IconButton>
                                <IconButton aria-label="delete" size="large">
                                  <FaFacebookF />
                                </IconButton>
                              </Stack>

                              <Button
                                style={{ color: styleObject.color }}
                                // style={styleObject}
                                className="btnclass mt-4"
                                size="large"
                                variant="contained"
                                onClick={handleClicktoHomePage}
                              >
                                Great, thanks!
                              </Button>
                            </div>

                            <div className='SurveySlack-logo-container mt-5'>
                              <p className='m-0'>Powered by</p>
                              <img src={logoImg}  alt="SurveySlack Logo" />
                              <p className='callToActionText '>
                                See how easy it is to
                                <a style={{ textDecoration: 'underline', color: 'inherit',cursor: 'pointer' }}href="https://surveyslack.com"> create surveys and forms</a>.
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            ))}
      </div>
    </div>
    </>
  );
};

export default memo(SurveyForm);
