import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ErrorPopup = ({ message ,position = "top-right" }) => {
  useEffect(() => {
    if (message) {
      toast.error(message, {
        position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [message]); // Show toast when the message prop changes

  return (
    <ToastContainer />
  );
};

export default ErrorPopup;