import { React, useState,useEffect } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Helmet } from 'react-helmet-async';
import validator from 'validator';
import ReactGA from "react-ga4";
import LoginForm from './AuthenticationComponent';
import { BASE_URL } from '../../api_folder/config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const auth = getAuth();
  // const handleChangeEmail=(event)=>{
  //   setEmail(event.target.value);
  //  }
  const triggerResetEmail = async () => {
   
try {
   await sendPasswordResetEmail(auth, email);
    setMailsendalert(true)
    setTimeout(() => {
      setMailsendalert(false) 
    }, 1500);
} catch (error) {
  if (error.code === "auth/user-not-found") {
    setUsernotfound('User not found. Please check your email.');
  } else {
    setUsernotfound ('An error occurred. Please Enter Valid Email.');
  }
}

  };
  const [usernotfound , setUsernotfound] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [mailsendalert, setMailsendalert] = useState(false)
  const loginformdata = [
    {
      commonbtntext: "Click here for",
      commonbtn: "Back to login",
      titletext: "Forgot",
      maintitle: "Password",
      loginbtn: " Forgot Password",
      navLinkforgot: { to: "/forgot" },
      navLinkcommonbtn: { to: "/app/login " },
      onClick: triggerResetEmail,
      handleChangeEmail: onChangeEmail,
      confermerroremail: usernotfound,
      emailvalidator: emailError,
      setopen:mailsendalert  ,
      // disable: isdisable,
    }
  ]

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/forgot", title: "forgot Page" });
  }, []);
  function onChangeEmail(event) {
    setEmail(event.target.value);
    const email = event.target.value

    if (validator.isEmail(email)) {
      setEmailError(false)
      setUsernotfound('')
    } else {
      setEmailError(true)
      setUsernotfound('Please Enter Valid Email.')
    }
  }
  return (  
    <>
    <Helmet>
    <title>SurveySlack Login | Secure Access to Your Surveys</title>
    <meta
      name="description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta
      name="keywords"
      content="SurveySlack Login, Access SurveySlack, Online Survey Platform Login, online surveys, survey tool, feedback platform"
    />
    <meta name="robots" content="index, follow" />
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="language" content="English" />

    <meta property="og:url" content="https://app.surveyslack.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="SurveySlack Login | Secure Access to Your Surveys" />
    <meta
      property="og:description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta property="og:image" content="https://surveyslack.com/wp-content/uploads/2024/07/Featured-Image.jpg" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="app.surveyslack.com" />
    <meta property="twitter:url" content="https://app.surveyslack.com/" />
    <meta name="twitter:title" content="SurveySlack Login | Secure Access to Your Surveys" />
    <meta
      name="twitter:description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta
      name="twitter:image"
      content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v4/Survey%20Slack/Streamline%20your%20survey%20creation%20process%20with%20ease./https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2F56188dc2-e3c3-4ce5-a8b1-1323953e37b9.jpg%3Ftoken%3DhOY-wLL-tV2Wb6eqlpzb3hUOqYMZbXQ3az2flBDqaSs%26height%3D800%26width%3D1200%26expires%3D33251249770/og.png"
    />
  </Helmet>
    <div>
      <LoginForm
        loginformdata={loginformdata}
      // handleChangeEmail={handleChangeEmail}
      />
    </div>
    </>
  )
}

export default ForgotPassword