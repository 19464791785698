import React, { useState, useEffect, useRef } from 'react';
import ReactGA from "react-ga4";
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { showAISurvey ,closeAISurveyTitle,showAISurveyTitle,showQuestionBranchingModal} from '../Redux/actions';
import '../Style/SelectAnswerTypeModal.css'; // Import the CSS file

const QuestionBranchingInfo = ({ isOpen, toggleModal,ruleId  }) => {
    const dispatch = useDispatch();
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);


    const handleOkClick = () => {
        dispatch(showQuestionBranchingModal({ rule_id: ruleId }, true));
        toggleModal();
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname }); // Ensure `location` is correctly referenced
      }, []); // Removed `location` dependency since it's not defined in this scope
    
    

    const handleCheckboxChange = (event) => {
        setDoNotShowAgain(event.target.checked);
    };

     const handleKeyDown = (event, action) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            action();
        }
    };
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            zIndex: 20,
            width: '100%',
            height: '100%',
            background: '#80808059',
            right: 0,
            display: isOpen ? 'block' : 'none',
        }}>
            <div className="modal-container">
                <div className='main-container' style={{
                    position: 'absolute',
                    maxWidth: '403px',
                    minWidth: '200px',
                    padding: '34px 20px 20px 60px',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 15px',
                    backgroundColor: 'rgb(243, 245, 249)',
                    color: 'rgb(56, 63, 80)',
                    right: '9px',
                    top: '100px',
                    opacity: 1,
                }}>
                    <div style={{
                        position: 'absolute',
                        left: '-22px',
                        right: 0,
                        bottom: 0,
                        width: '50px',
                        height: '100%',
                        borderRadius: '3px 0 0 3px',
                        backgroundColor: '#ffc64d',
                    }}>
                        <FontAwesomeIcon icon={faExclamation} style={{ color: 'white', fontSize: '24px', padding: '24px', marginTop: '122px' }} />
                    </div>
                    <h3 style={{
                        fontSize: '20px',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        color: '#333',
                    }}>Edit answer choice?</h3>
                    <p style={{ fontSize: '16px', color: '#333' }}>
                       warning: you might lose response data forever. <a href="https://platform.openai.com/docs/guides/fine-tuning">Learn more</a>
                    </p>
                    <p style={{ fontSize: '14px', color: '#666' }}>
                        things you can edit:
                        <br />
                        • edit question.
                        <br />
                        • edit texts.
                        <br />
                        • edit conditions.
                        <br />
                        • edit actions.
                       
                    </p>
                  
                    <nav style={{ textAlign: 'center' }}>
                        <ul style={{
                            listStyleType: 'none',
                            display: 'flex',
                            justifyContent: 'left',
                            marginTop: '20px',
                            padding: 0,
                        }}>
                            <li>
                                <div
                                    style={{
                                        padding: '15px',
                                        fontSize: '14px',
                                        color: '#fff',
                                        backgroundColor: '#bcbcbc',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        width: '130px',
                                        textAlign: 'center',
                                    }}
                                    onClick={handleOkClick}
                                    onKeyDown={(event) => handleKeyDown(event, toggleModal)}
                                    tabIndex="0"
                                    role="button"
                                    id="cancelDeleteButton"
                                >
                                    Got it!
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default QuestionBranchingInfo;
