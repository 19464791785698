
// src/ReferralHandler.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { saveReferralToCookie } from './cookieUtils';

const ReferralHandler = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ref = queryParams.get('ref');

        if (ref) {
            saveReferralToCookie(ref);
        }
    }, [location]);

    return null; // This component does not render anything
};

export default ReferralHandler;