import React, { useState, useEffect, memo, useRef } from 'react';
import { MdOutlineIncompleteCircle, MdPreview } from 'react-icons/md';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import ReactGA from "react-ga4";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../../Style/ResultTab.css';
import ResultQuestions from '../RsultTabs/ResultQuestions';
import Participants from '../RsultTabs/Participants';

import Tabpanel from '../../components/NavigateTab';
import GeneralScreen from '../Create-Survey-Folder/Update_survey_tabs/GeneralScreen';
import WelComeScreen from '../Create-Survey-Folder/Update_survey_tabs/WelComeScreen';
import NotificationScreen from '../Create-Survey-Folder/Update_survey_tabs/NotificationScreen';
import TeamsScreen from '../Create-Survey-Folder/Update_survey_tabs/TeamsScreen';

const Result = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname }); // Ensure `location` is correctly referenced
  }, []); // Removed `location` dependency since it's not defined in this scope


  return (
    <div>
    <div className='iconcontainer'>
      <MdOutlineIncompleteCircle icon={MdOutlineIncompleteCircle} className='iconOfTab' />
      <h1 className='headingofTab'>Result</h1>
    </div>
    <Box sx={{ width: '100%', typography: 'body1',}}>
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
        <TabList  onChange={handleChange} aria-label="lab API tabs example" >
          <Tab label="Questions" value="1"  />
          <Tab label="Participants" value="2" />
          {/* <Tab label="Insights" value="3" /> */}
        </TabList>
      </Box>
      <TabPanel style={{padding:'0px',marginTop:'25px' }} value="1"><ResultQuestions/></TabPanel>      <TabPanel value="2"><Participants/></TabPanel>
      {/* <TabPanel value="3">sadsa</TabPanel> */}
    </TabContext>
  </Box>
  </div> 
  );
};

export default Result;
