import Cookies from 'js-cookie';

export const saveReferralToCookie = (ref) => {
    // Get the current value of the 'referral' cookie
    const existingReferral = Cookies.get('referral');

    if (ref) {
   
        if (existingReferral) {
            // Split existing referrals by a delimiter (e.g., comma) and add the new one
            const referrals = existingReferral.split('-').map(item => item.trim());
            if (!referrals.includes(ref)) { // Avoid duplicates
                referrals.push(ref);
            }
            // Set the cookie with the updated value
            Cookies.set('referral', referrals.join('-'), { expires: 365 });
        } else {
            // If the cookie does not exist, set it with the new referral value
            Cookies.set('referral', ref, { expires: 365 });
        }
    }
};