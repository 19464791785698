import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { Card, Avatar, Tooltip, IconButton } from '@mui/material';
import { FaTrash, FaStarOfLife } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import { FcMenu } from 'react-icons/fc';
import Loader from '../../../components/component-part/Loader';
import DeleteModal from '../../DeleteModal';
import QuestionBranchingInfo from '../../QuestionBranchingInfo';
import { showQuestionBranchingModal } from '../../../Redux/actions';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';

const QuestionBranching = () => {
  const surveyid = localStorage.getItem('SURVEY_ID');
  const dispatch = useDispatch();
  const isQuestionBranchingModalOpen = useSelector((state) => state.showQuestionBranchingModal);
  const [filteredQuestionBranchingList, setFilteredQuestionBranchingList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const [isQuestionBranchingInfoOpen, setQuestionBranchingInfoOpen] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(null); ; // Initialize state for the question ID to delete
  
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?surveyid=${surveyid}`);
        if (response.ok) {
          const responseData = await response.json();
          setQuestions(responseData.data);
        } else {
          throw new Error('Failed to fetch questions');
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };
    fetchQuestions();
  }, [surveyid]);

  useEffect(() => {
    const fetchQuestionBranching = async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.QuestionBranching}?survey_id=${surveyid}`);
        if (response.ok) {
          const responseData = await response.json();
          console.log('Fetched data:', responseData.data); // Log the fetched data
          setFilteredQuestionBranchingList(responseData.data);
        } else {
          throw new Error('Failed to fetch question branching');
        }
      } catch (error) {
        console.error('Error fetching question branching:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchQuestionBranching();
  }, [surveyid]);

  // Create a lookup object for questions
  const questionTextMap = questions.reduce((map, question) => {
    map[question.questionid] = question.question_text;
    return map;
  }, {});

  if (loading) {
    // return <Loader />;
  }
  
  // const handleEditQuestion = (ruleId) => {
  //   console.log('handleEditQuestion: ruleId', ruleId);
  //   dispatch(showQuestionBranchingModal({ rule_id: ruleId }, true));
  // };
  

  const handleEditQuestion = (ruleId) => {
    // First, call openQuestionBranchingInfo
    isQuestionBranchingInfoOpen();

    // Then, dispatch the action to show the Question Branching Modal
    // dispatch(showQuestionBranchingModal({ rule_id: ruleId }, true));
  };
  

  const handleDeleteQuestion = (ruleId) => {
    
    // Set the state to open the delete modal and store the rule ID
    setQuestionIdToDelete(ruleId);
    setDeleteModalOpen(true); 
  };

  const handleDeleteConfirmation = async () => {
    setDeleteModalOpen(false);
    try {
      const response = await fetch(
        `${BASE_URL}/QuestionBranchingRules.php?rule_id=${questionIdToDelete}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        console.log(`Rule with ID ${questionIdToDelete} was deleted successfully.`);
        // Remove the deleted rule from the state
        setFilteredQuestionBranchingList(prevList =>
          prevList.filter(rule => rule.rule_id !== questionIdToDelete)
        );
      }
    } catch (error) {
      console.error('An error occurred while deleting the rule:', error);
    }
  };

  const openQuestionBranchingInfo = (ruleId) => {
    setSelectedRuleId(ruleId);  // Set the ruleId in state
    setQuestionBranchingInfoOpen(true); // Open the modal
};

const closeQuestionBranchingInfo = () => {
    setQuestionBranchingInfoOpen(false); // Close the modal
    setSelectedRuleId(null);  // Clear the selected ruleId when closing
};

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        handleDelete={handleDeleteConfirmation}
        questionIdToDelete={questionIdToDelete}
      />
 <QuestionBranchingInfo
                isOpen={isQuestionBranchingInfoOpen}
                toggleModal={closeQuestionBranchingInfo}
                ruleId={selectedRuleId}  // Pass ruleId to the modal
            />
      <div className="text-center position-relative" id="question-listpge" style={{ margin: '10px' }}>
        <div className="row align-items-start mt-4">
          {/* Create New Question and Create From Template buttons can be added here if needed */}
        </div>

        {filteredQuestionBranchingList.length === 0 ? (
          <div style={{ padding: '20vh 0' }}>
            <h4>There are no rules for this survey.</h4>
            <p style={{ color: '#42a5f5' }}>
              To create a rule for a question, make sure that the survey has at least two questions.
            </p>
          </div>
        ) : (
          <div className="row mt-3">
            <div className="col-lg-12 question-card" id="questionCard">
              <div>
                {filteredQuestionBranchingList.map((survey, index) => (
                  <div className="my-2" key={survey.rule_id}>
                    <Card style={{ borderRadius: '20px', padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="flex-row">
                      <div className="d-flex align-items-center" style={{ marginLeft: '-20px', overflow: 'visible' }}>
                        <FcMenu
                          className="me-2"
                          style={{ fontSize: '25px', color: 'gray', cursor: 'grab' }}
                        />
                      </div>

                      <div className="d-flex w-100 justify-content-between align-items-center" style={{ padding: '10px' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                          <div className="d-flex align-items-start" style={{ padding: '0px' }}>
                            <Avatar
                              style={{ backgroundColor: '#34897a' }}
                              className="me-2"
                              sx={{
                                width: 24,
                                height: 24,
                                fontSize: '16px',
                              }}
                            >
                              {index + 1}
                            </Avatar>
                            <p className="m-0" style={{ textAlign: 'start', margin: '0', padding: '0', flex: 1 }}>
                              {questionTextMap[survey.question_id] || 'Loading...'}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {/* <Tooltip title="Edit">
                            <IconButton
                              className="deletequestion"
                              onClick={() => openQuestionBranchingInfo(survey.rule_id)}
                              style={{ backgroundColor: '#d2d2d24f', marginLeft: '10px',height:'30px',width :'30px' }}
                              aria-label="edit"
                            >
                              <EditIcon style={{ color: '#34897a' }} />
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title="Delete">
                            <IconButton
                              className="deletequestion"
                              onClick={() => handleDeleteQuestion(survey.rule_id)}
                              style={{ backgroundColor: '#d2d2d24f', marginLeft: '10px' }}
                              aria-label="delete"
                            >
                              <FaTrash style={{ color: '#34897a' }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionBranching;
