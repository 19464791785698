import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Chip from '@mui/material/Chip';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import InputGroup from 'react-bootstrap/InputGroup';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { closecreateSurvey, showBackdropLoader } from '../../Redux/actions';
import '../../Style/createSurvey.css';
import { BASE_URL } from '../../api_folder/config';

function CreateSurvey(props) {
  const [surveytitle, setSurveytitle] = useState('');
  const [surveydes, setSurveydes] = useState('');
  const [emailtype, optionEmail] = useState('');
  const [publicSurvey, setPublicSurvey] = useState('');
  const [desableSyrvey, setDesablesyrveyfunction] = useState('');
  const [selectdate, setSelectdate] = useState('YYYY-MM-DD');
  const [logourl, setLogourl] = useState(null);
  const [checkemail, setCheckemail] = useState('None');
  

  const handelEmailcheck = (event) => {
    setCheckemail(event.target.value);
    setEmailalert(false);
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closecreateSurvey());
    setSurveytitle('');
    setSurveydes('');
    optionEmail('');
    setPublicSurvey('');
    setDesablesyrveyfunction('');
    setSelectdate('');
    setCheckemail('');
    setPersonName([]);
    setDisable(false);
  };
  const openSurvey = useSelector((state) => state.openSurvey);
  const [disable, setDisable] = useState(false);
  const today = dayjs();
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#34897a',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#6c757d' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    })
  );

  const publicSurveyfunction = (event) => {
    setPublicSurvey(event.target.checked);
    setSurveytype(false);
  };

  const desableSyrveyfunction = (event) => {
    setDesablesyrveyfunction(event.target.checked);
    setDisable(!disable);
  };

  const tomorrow = dayjs().add(1, 'day');
  const dateSelect = (date) => {
    setSelectdate(date);
};


  const PreventEnter = (event) => {
    if (event.key === 'Enter') {
       event.preventDefault();
    }
  };

  const handelCreatesurvey = async (event) => {
    event.preventDefault();

    if (surveytitle === '') {
      setQuestiontextalert(true);
      return;
    }
    // if (surveydes === "") {
    //     setQuestiontextdes(true)
    //     return;
    // }

    // if (personName.length < 1) {
    //   setLabelsalert(true);
    //   return;
    // }
    // if (!checkemail) {
    //     setEmailalert(true)
    //     return;
    // }

    // if (!publicSurvey) {
    //     alert('Please select a Survey type ');
    //     return;
    // }
    // if (!publicSurvey) {
    //     setSurveytype(true)
    //     return;
    // }

    // generate surveyid
    function generateId() {
      const id = Math.random().toString().slice(2, 7);
      return id;
    }

    //   generate survey pin
    const createID = generateId();
    function surveyPin() {
      const id = Math.random().toString().slice(2, 6);
      return id;
    }
    const createPin = surveyPin();
    try {
      const getuserid = localStorage.getItem('getuserid');
      const res = await fetch(`${BASE_URL}/surveylist.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userid: getuserid,
          surveyid: createID,
          survey_title: surveytitle,
          survey_display:"One Question at time",
          survey_description: surveydes,
          background: '',
          survey_label: labels,
          survey_categories: "",
          email: checkemail,
          public_survey: publicSurvey,
          survey_pin: createPin,
          disabled: 1,
          fonts:null,
          status: logourl,
          survey_enddate: selectdate,
        }),
      });

      if (res.status === 200) {
        dispatch(showBackdropLoader());
        setSurveytitle('');
        setSurveydes('');
        optionEmail('');
        setPublicSurvey('');
        setDesablesyrveyfunction('');
        setSelectdate('');
        setCheckemail('');
        setPersonName([]);
        setDisable(false);
        dispatch(closecreateSurvey());
        console.log('Survey Data post  successfully');
      }
      // dispatch(updateSurveyList());
    } catch (err) {
      console.log(err);
    }
  };

  // tag editor code
  const [personName, setPersonName] = useState([]);
  const [tagvalue, setTagvalue] = useState('');
  const [validation, setValidation] = useState(false);

  const handleTageditorChange = (event) => {
    setTagvalue(event.target.value);
    // setLabelsalert(false);
  };

  const labels = JSON.stringify(personName);
  const handleKeyDown = (event) => {
    if (personName.length >= 3) {
      setValidation(true);
      setTimeout(() => {
        setValidation(false);
      }, 1000);
    } else if (event.key === 'Enter' && tagvalue.trim() !== '') {
      setPersonName([...personName, tagvalue]);
      setTagvalue('');
    }
  };

  const handleDelete = (name) => {
    setPersonName(personName.filter((n) => n !== name));
  };

  // tag editor code
  const [questiontextalert, setQuestiontextalert] = useState(false);
  const [questiontextdes, setQuestiontextdes] = useState(false);
  const [labelsalert, setLabelsalert] = useState(false);
  const [emailalert, setEmailalert] = useState(false);
  const [surveytype, setSurveytype] = useState(false);

  const handeltitlechange = (e) => {
    setSurveytitle(e.target.value);
    setQuestiontextalert(false);
  };
  const handeldeschange = (e) => {
    setSurveydes(e.target.value);
    setQuestiontextdes(false);
  };
  const todayDisable = new Date();
  const shouldDisableDate = (day) => day < todayDisable;
  //     const formattedDate = dayjs().format('YYYY-MM-DD HH:mm:ss');
  // console.log(formattedDate);


  return (
    <>
      <Modal className="opensurveyModal " show={openSurvey} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='h5Createsurvey'>Create Survey</h5>
          </Modal.Title>
        </Modal.Header>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form onSubmit={handelCreatesurvey} onKeyDown={PreventEnter}>
          <Modal.Body className="cratesurveyModal">
            <div>
              <Form.Label>Title</Form.Label>
              <InputGroup className="">
                <Form.Control
                  value={surveytitle}
                  onChange={handeltitlechange}
                  className="rounded-0"
                  placeholder="Write survey title"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </InputGroup>
              {questiontextalert && <span className="createsurveyalert">Must enter Title of Survey.</span>}
            </div>
            <div>
              <Form.Label>Description</Form.Label>
              {/* <FloatingLabel className="mb-2" controlId="floatingTextarea2" label=" Please provide a brief description of your survey."> */}
              {/* <FloatingLabel className="mb-2" > */}
              <Form.Control
                value={surveydes}
                onChange={handeldeschange}
                className="rounded-0"
                as="textarea"
                placeholder="Please provide a brief description on your survey."
                style={{ height: '100px' }}
              />
              {/* </FloatingLabel> */}
              {/* {questiontextdes && <span style={{ color: 'red', fontSize: "14px" }}>You must enter question Description.</span>} */}
            </div>
            <div>
              <div className="row">
                {/* label div */}
                <FormControl size="small" className="col-12 col-md-6 col-xl-6" style={{ marginBottom: '20px' }}>
                  <Form.Label className="">Labels (Maximum 3 lables are allowed)</Form.Label>
                  <FormControl className=" w-100">
                    <div style={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                      <Form.Control
                        id="demo-multiple-chip"
                        className="mb-2 "
                        value={tagvalue}
                        onChange={handleTageditorChange}
                        onKeyDown={handleKeyDown}
                        fullWidth
                        placeholder="Enter a labels"
                        sx={{ flexGrow: 1, minHeight: 'auto' }}
                      />

                      {labelsalert && (
                        <span className="createsurveyalert" style={{ bottom: '-18px' }}>
                          Please Create Labels for your Survey.
                        </span>
                      )}
                      <br />
                      {validation && (
                        <div className="createsurveyalert" style={{ bottom: '-18px' }}>
                          Maximum limit of labels reached!
                        </div>
                      )}
                      <br />
                      {personName.map((value) => (
                        <Chip
                          style={{ backgroundColor: '#c3c4c4', color: 'black' }}
                          key={value}
                          label={value}
                          onDelete={() => handleDelete(value)}
                          sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                        />
                      ))}
                    </div>
                  </FormControl>
                </FormControl>
              </div>

              {/* email div */}
              <div className="row">
                <div className="col-12 col-md-6 col-xl-6" style={{ marginBottom: '20px' }}>
                  <Form.Label>Email</Form.Label>
                  <br />
                  <FormControl size="small" className="mb-2" style={{ width: '100%' }}>
                    {/* <InputLabel id="demo-select-small-label">Email</InputLabel> */}
                    <Select
                      defaultValue="None"
                      className="rounded-0"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={checkemail}
                      // label="Category"
                      onChange={handelEmailcheck}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value="Optional">Optional</MenuItem>
                      <MenuItem value="Required">Required</MenuItem>
                    </Select>
                    {emailalert && <span className="createsurveyalert">Please Enter Email</span>}
                  </FormControl>
                </div>
              </div>
              {/* public survet checkbox */}
              <div className="row">
                <div className="col-12 col-md-6 col-xl-6 ">
                  <div className="row align-items-center ">
                    <div className="" style={{ display: 'flex' }}>
                      <Form.Label
                        className="col-10 col-md-10 col-xl-10 m-0"
                        style={{ display: 'flex', alignItems: 'center', margin: '0' }}
                      >
                        {' '}
                        Public Survey{' '}
                      </Form.Label>

                      <FormControlLabel
                        className="col-2 col-md-2 col-xl-2 m-0"
                        control={<IOSSwitch sx={{ m: 1 }} color="success" variant="contained" checked={publicSurvey} onChange={publicSurveyfunction} />}
                        label=""
                      />
                    </div>
                    <br />
                    <div style={{ display: 'flex' }}>
                      <Form.Label
                        className=" col-10 col-md-10 col-xl-10 m-0"
                        style={{ display: 'flex', alignItems: 'center', margin: '0' }}
                      >
                        {' '}
                        {disable ? 'End Date' : 'End Date'}{' '}
                      </Form.Label>
                      {/* style={{backgroundColor:'#6c757d!impotant'}} */}
                      <FormControlLabel
                        className="col-2 col-md-2 col-xl-2 m-0"
                    
                        control={<IOSSwitch  sx={{ m: 1 }}  color="success" variant="contained" checked={desableSyrvey} onChange={desableSyrveyfunction} />}
                        label=""
                      />

                      {surveytype && <span className="createsurveyalert">Please Select Survey Type</span>}
                      <br />
                    </div>
                  </div>
                  {/* </div> */}

                  {disable && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          className="date"
                          sx={{ width: '100%', padding: '8.5px 0px !important' }}
                          format="YYYY-MM-DD"
                          minDate={today}
                          shouldDisableDate={shouldDisableDate}
                          disablePast
                          onChange={dateSelect}
                          value={selectdate}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="bgCloseButton" color="secondry" variant="contained"  onClick={handleClose}>
              Close
            </Button>
            <Button onClick={props.onButtonclick} type="submit" className="bgButton" color="success" variant="contained"  >
              Create Survey
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default CreateSurvey;
