import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div
      style={{
        position: 'absolute',
        width: '70%',
        height: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '10%',
        left: '10%',
        zIndex: '9999',
      }}
    >
      <Box 
        width={'100%'} 
        className="align-items-center justify-content-center"  
        sx={{
          backgroundColor: '#000000',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '70px',
          height: '70px',
          opacity: '0.7',
          borderRadius: '15px',
        }}
      >
        <CircularProgress />
      </Box>
    </div>
  );
};

export default LoadingOverlay;