import React, { useState, useEffect, memo, useRef } from 'react';
import { AiFillSetting } from 'react-icons/ai';
import ReactGA from "react-ga4";
import Loader from '../../components/component-part/Loader';
import Tabpanel from '../../components/NavigateTab';
import GeneralScreen from '../Create-Survey-Folder/Update_survey_tabs/GeneralScreen';
import WelComeScreen from '../Create-Survey-Folder/Update_survey_tabs/WelComeScreen';
import NotificationScreen from '../Create-Survey-Folder/Update_survey_tabs/NotificationScreen';
import TeamsScreen from '../Create-Survey-Folder/Update_survey_tabs/TeamsScreen';
import SuccessScreen from '../Create-Survey-Folder/Update_survey_tabs/SuccessScreen';

const tabdata = [
  {
    itemone: <GeneralScreen />,
    itemtwo: <WelComeScreen />,
    itemthree: <NotificationScreen />,
    itemfourth: <TeamsScreen />,
    itemfive: <SuccessScreen/>,
    itemtabone: 'General',
    itemtabtwo: 'Welcome',
    itemtabthree: 'Notification',
    itemtabfourth: 'Teams',
    itemtabfive: 'Success',
  },
];



const Setting = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname }); // Ensure `location` is correctly referenced
  }, []); // Removed `location` dependency since it's not defined in this scope


  const [fetchdata, setFetchdata] = useState(false);

  return (
    <div className=''>
      <div className='iconcontainer'>
        <AiFillSetting className='iconOfTab'  />
        <h1 className='headingofTab'>Settings</h1>
      </div>
      
      {fetchdata && <Loader />}
      
      <Tabpanel tabdata={tabdata} />
    </div>
  );
};

export default Setting;
