import { React, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ReactGA from "react-ga4";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  FacebookAuthProvider,
} from 'firebase/auth';
import { useNavigate, useLocation  } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
// import { createUserWithEmailAndPassword } from "firebase/auth";
import validator from 'validator';
import LoginForm from './AuthenticationComponent';
import AlertError from "../../components/Popup-folder/ToastMessages/ErrorPopup"
import { auth, provider } from '../../sections/auth/login/firebase';
import { showDiv, iscloseError } from '../../Redux/actions';
import '../../Style/LoginForm.css';
import { BASE_URL } from '../../api_folder/config';

function Signup() {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const [isdisable, setDisable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isanimate, setAnimation] = useState('');

  const [email, setEmail] = useState('dipen@gmail.com');
  const [password, setPassword] = useState('');
  const [Confermpassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [ispasswordError, setPasswordError] = useState('');
  const [confirmPasswordstate, setConfirmPasswordstate] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  function checkformdata() {
    if (confirmPasswordstate === false) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }

//  useEffect(() => {
//   try {
//     const urlParams = new URLSearchParams(window?.location?.search || '');
//     const source = urlParams.get('source');
    
//     if (source) {
//       Cookies.set('referralSite', source, { expires: 7 });
//       const cleanUrl = window?.location?.pathname || '/';
//       // Use replaceState instead of redirect
//       window.history.replaceState({}, document.title, cleanUrl);
//     }
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }, []);

   

  useEffect(() => {
    setDisable(true);
  }, []);

  useEffect(() => {
    checkFormValidity();
  }, [email, password, Confermpassword]);



  const checkFormValidity = () => {
    // const isEmailValid = validator.isEmail(email);
    const isEmailValid = validator.isEmail(email) || email.trim() === '';

    const isPasswordValid =
      validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }) || password.trim() === '';
    const isConfirmPasswordValid = password === Confermpassword || Confermpassword.trim() === '';

    setEmailError(!isEmailValid);
    setPasswordError(
      isPasswordValid
        ? ''
        : 'Must contain Minimum 8 characters, one -  number, symbol , uppercase and lowercase letter.'
    );
    setConfirmPasswordError(!isConfirmPasswordValid);

    setDisable(!(isEmailValid && isPasswordValid && isConfirmPasswordValid));
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem('saveEmailid');
    const storedPswd = localStorage.getItem('savePsw');
    setEmail(storedEmail);
    setPassword(storedPswd);
  }, []);

  // const postUserData = async (user, email) => {
  //   try {
  //     const res = await fetch(`${BASE_URL}/user.php`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         userid: user.uid,
  //         username: user.displayName || '',
  //         created_date: user.metadata.creationTime,
  //         modified_date: user.metadata.lastSignInTime,
  //         platform: 'Web',
  //         created_for: 'Personal',
  //         isFree: 'free',
  //         subscription_enddate: '',
  //         teams: '',
  //         last_login: user.metadata.lastSignInTime,
  //         email,
  //         active: 'true',
  //         loginwith: user.providerData[0]?.providerId || 'email',
  //       }),
  //     });

  //     if (!res.ok) {
  //       throw new Error(`Failed to post user data. Status: ${res.status}`);
  //     }
  //     console.log('User data posted successfully');
  //   } catch (err) {
  //     console.error('Error posting user data:', err.message);
  //     throw err; // Re-throw the error to be handled by the caller
  //   }
  // };
 
  const postUserProfileData = async (user) => {
    try {

      let firstName = '';
    let lastName = '';
    
    if (user.displayName) {
      // Split the display name by space and handle potential multiple words
      const nameParts = user.displayName.split(' ');
      firstName = nameParts[0] || ''; // First word is firstName
      lastName = nameParts.slice(1).join(' ') || ''; // Rest of the words combined as lastName
    }
   JSON.stringify({
    userid: user.uid,
    username: `${firstName} ${lastName}` || '',
    first_name: firstName,
    last_name: lastName,
    profile_image: '',
    created_date: user.metadata.creationTime,
    modified_date: user.metadata.lastSignInTime,
    phone_number: '',
    bio: '',
   })
   const referral = Cookies.get('referral');
      const res = await fetch(`${BASE_URL}/userprofile.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userid: user.uid,
          username: `${firstName} ${lastName}` || '',
          first_name: firstName,
          last_name: lastName,
          profile_image: '',
          created_date: user.metadata.creationTime,
          modified_date: user.metadata.lastSignInTime,
          phone_number: '',
          bio: '',
          referral,
        }),
      });
console.log("i am from postuser profile data",user);
      if (!res.ok) {
        // throw new Error(`Failed to post user profile data. Status: ${res.status}`);
        setWarningMessage('You are already signed in with Google. Please go to the login page.');

      }

      const responseJson = await res.json(); // Parse the response as JSON
      console.log('Response from API:', responseJson); // Log the response
      // Navigate to surveys page

      if (responseJson.success) {
        // Navigate to surveys page
        navigate('/app/my-surveys', { replace: true });
        console.log('User  profile data posted successfully');
      } else {
        // Show alert with the message from the response
        setWarningMessage(responseJson.message || 'An error occurred while posting user profile data.');
      }
    } catch (err) {
      console.error('Error posting user profile data:', err.message);
      throw err; // Re-throw the error to be handled by the caller
    }
  };
  let user = null;
  const onSignUp = async (e) => {
    e.preventDefault();
    const useremail = email;

    // Validate inputs (add your validation logic here)
    ValidationFunction(email, password, Confermpassword);

    // Set the signup method
    localStorage.setItem('signupWithEmail', 'email');

    try {
      setWarningMessage('');
     

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      user = userCredential.user;

      // Store user details in local storage
      localStorage.setItem('provider', user.providerId);
      localStorage.setItem('getuserid', user.uid);
      localStorage.setItem('uid', user.uid);
      localStorage.setItem('email', user.email);

      // Navigate to the profile page
      // navigate('/profile');

      // Post user data and profile data
      // await postUserData(user, useremail);
      await postUserProfileData(user);
    } catch (error) {
      // Handle sign-up errors
      const errorCode = error.code;
      const errorMessage = error.message;
    
      console.error(errorCode, errorMessage);
    
      switch (errorCode) {
        case 'auth/email-already-in-use':
          setWarningMessage("An account with this email address already exists. Please use a different email.");
          break;
        case 'auth/invalid-email':
          setWarningMessage("Please Enter email and password.");
          break;
        case 'auth/missing-password':
          setWarningMessage("Please enter a password.");
          break;
        case 'auth/missing-email':
          setWarningMessage("Please enter email.");
          break;
        default:
          setWarningMessage(errorMessage);  // For all other errors
          dispatch(showDiv());  // Show the error popup
          break;
      }
    }
    
  };

  const GoogleSignInComponent = async () => {
    setWarningMessage('');
    if (email && password) {
      // Try signing in with email and password
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        

        // Store user details in local storage
        localStorage.setItem('uid', user.uid);
        localStorage.setItem('getuserid', user.uid);
        localStorage.setItem('email', user.email);
        localStorage.setItem('username', user.displayName);

        // Navigate to surveys page
        // navigate('/my-surveys/survey', { replace: true });

        // Post user data and profile data
        // await postUserData(user, user.email);
       
        
        await postUserProfileData(user);
      } catch (error) {
      setWarningMessage(`Error signing in with email and password`);
        dispatch(showDiv()); // Show error if sign-in fails
      }
    } else {
      // Proceed with Google sign-in if email and password are not filled
      try {
        const data = await signInWithPopup(auth, provider);
        const user = data.user;
      
        // console.log(user);
        // console.log("i am from google sign in",user.firstName,user.lastName, user.displayName);


        // Split the displayName into firstName and lastName
        const nameParts = user.displayName.split(' ');
        const firstName = nameParts[0]; // Get the first part as firstName
        const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

        // Store user details in local storage
        localStorage.setItem('uid', user.uid);
        localStorage.setItem('getuserid', user.uid);
        localStorage.setItem('email', user.email);
        localStorage.setItem('username', user.displayName); 

        // Post user data and profile data
        // await postUserData(user, user.email);


        if(!user.displayName){
          navigate('/app/profile');
        }else{
           postUserProfileData(user);
        }

      } catch (error) {
        // console.error('Error with Google sign-in:', error.message);
        toast.error(`Error with Google sign-in: ${error.message}`, {
          position: "top-right",
          autoClose: 3000,
      });
  
        const {
          customData: { email },
        } = error;
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log('Facebook credential:', credential);
      }
    }
  };

  // // const onSignUp = async (e) => {
  // //   e.preventDefault();
  // //   const useremail = email;
  // //   // ValidationFunction(email)
  // //   ValidationFunction(email, password, Confermpassword);

  // //   // const createusername = localStorage.getItem('username');
  // //   localStorage.setItem('signupWithEmail', 'email');
  // //   await createUserWithEmailAndPassword(auth, email, password)
  // //     .then((userCredential) => {
  // //       const user = userCredential.user;
  // //       localStorage.setItem('provider', user.providerId);
  // //       localStorage.setItem('getuserid', user.uid);
  // //       localStorage.setItem('uid', user.uid);
  // //       localStorage.setItem('email', user.email);
  // //       navigate('/profile');
  // //       try {
  // //         const res = fetch(`${BASE_URL}/user.php`, {
  // //           method: 'POST',
  // //           headers: { 'Content-Type': 'application/json' },
  // //           body: JSON.stringify({
  // //             userid: user.uid,
  // //             username: '',
  // //             created_date: userCredential.user.metadata.creationTime,
  // //             modified_date: userCredential.user.metadata.lastSignInTime,
  // //             platform: 'Web',
  // //             created_for: 'Personal',
  // //             isFree: 'free',
  // //             subscription_enddate: '',
  // //             teams: '',
  // //             last_login: userCredential.user.metadata.lastSignInTime,
  // //             email: useremail,
  // //             active: 'true',
  // //             loginwith: 'email',
  // //           }),
  // //         });
  // //         if (res.status === 200) {
  // //           console.log('user Data post  successfully');
  // //         }
  // //       } catch (err) {
  // //         console.log(err);
  // //       }

  // //       // user profile
  // //       try {
  // //         // const createusername = localStorage.getItem('username');
  // //         const res = fetch(`${BASE_URL}/userprofile.php`, {
  // //           method: 'POST',
  // //           headers: { 'Content-Type': 'application/json' },
  // //           body: JSON.stringify({
  // //             userid: user.uid,
  // //             username: '',
  // //             first_name: '',
  // //             last_name: '',
  // //             profile_image: '',
  // //             created_date: userCredential.user.metadata.creationTime,
  // //             modified_date: userCredential.user.metadata.lastSignInTime,
  // //             phone_number: '',
  // //             bio: '',
  // //           }),
  // //         });
  // //         // const resJson = await res.json();
  // //         if (res.status === 200) {
  // //           console.log(' user profile data post successfully');
  // //         }
  // //         if (!res.ok) {
  // //           // If the response is not ok, throw an error with the message from the API
  // //           alert('Failed to post user profile data');
  // //           // throw new Error(`Error ${res.status}: ${resJson.message || 'Failed to post user profile data'}`);
  // //         }
  // //       } catch (err) {
  // //         console.log(err);
  // //       }
  // //     })
  // //     .catch((error) => {
  // //       const errorCode = error.code;
  // //       const errorMessage = error.message;
  // //       console.log(errorCode, errorMessage);
  // //       if (errorCode === 'auth/email-already-in-use') {
  // //         alert('An account with this email address already exists. Please use a different email.');
  // //       } else {
  // //         alert(errorMessage);
  // //         dispatch(showDiv());
  // //       }
  // //     });
  // // };
  // const GoogleSignInComponent = async () => {
  //   // Check if email and password are filled
  //   if (email && password) {
  //     try {
  //       const userCredential = await signInWithEmailAndPassword(auth, email, password);
  //       const user = userCredential.user;
  //       localStorage.setItem('uid', user.uid);
  //       localStorage.setItem('getuserid', user.uid);
  //       localStorage.setItem('email', user.email);
  //       localStorage.setItem('username', user.displayName);
  //       navigate('/my-surveys/survey', { replace: true });

  //       // Handle profile and user data posting
  //       try {
  //         const res = await fetch(`${BASE_URL}/user.php`, {
  //           method: 'POST',
  //           headers: { 'Content-Type': 'application/json' },
  //           body: JSON.stringify({
  //             userid: user.uid,
  //             username: user.displayName || '',
  //             created_date: user.metadata.creationTime,
  //             modified_date: user.metadata.lastSignInTime,
  //             platform: 'Web',
  //             created_for: 'Personal',
  //             isFree: 'free',
  //             subscription_enddate: '',
  //             teams: '',
  //             last_login: user.metadata.lastSignInTime,
  //             email: user.email,
  //             active: 'true',
  //             loginwith: 'email',
  //           }),
  //         });
  //         if (res.status === 200) {
  //           console.log('User data posted successfully');
  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }

  //       try {
  //         const res = await fetch(`${BASE_URL}/userprofile.php`, {
  //           method: 'POST',
  //           headers: { 'Content-Type': 'application/json' },
  //           body: JSON.stringify({
  //             userid: user.uid,
  //             username: user.displayName || '',
  //             first_name: '',
  //             last_name: '',
  //             profile_image: '',
  //             created_date: user.metadata.creationTime,
  //             modified_date: user.metadata.lastSignInTime,
  //             phone_number: '',
  //             bio: '',
  //           }),
  //         });
  //         if (res.status === 200) {
  //           console.log('User profile data posted successfully');
  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     } catch (error) {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log(errorCode, errorMessage);
  //       dispatch(showDiv()); // Show error if sign-in fails
  //     }
  //   } else {
  //     // Proceed with Google sign-in if email and password are not filled
  //     signInWithPopup(auth, provider)
  //       .then((data) => {
  //         navigate('/my-surveys/survey', { replace: true });
  //         localStorage.setItem('uid', data.user.uid);
  //         localStorage.setItem('getuserid', data.user.uid);
  //         localStorage.setItem('email', data.user.email);
  //         localStorage.setItem('username', data.user.displayName);

  //         // Handle profile and user data posting
  //         try {
  //           const res = fetch(`${BASE_URL}/user.php`, {
  //             method: 'POST',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({
  //               userid: data.user.uid,
  //               username: data.user.displayName,
  //               created_date: data.user.metadata.creationTime,
  //               modified_date: data.user.metadata.lastSignInTime,
  //               platform: 'Web',
  //               created_for: 'Personal',
  //               isFree: 'free',
  //               subscription_enddate: '',
  //               teams: '',
  //               last_login: data.user.metadata.lastSignInTime,
  //               email: data.user.email,
  //               active: 'true',
  //               loginwith: data.user.providerData[0].providerId,
  //             }),
  //           });
  //           if (res.status === 200) {
  //             console.log('User data posted successfully');
  //           }
  //         } catch (err) {
  //           console.log(err);
  //         }

  //         try {
  //           const res = fetch(`${BASE_URL}/userprofile.php`, {
  //             method: 'POST',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({
  //               userid: data.user.uid,
  //               username: data.user.displayName,
  //               first_name: '',
  //               last_name: '',
  //               profile_image: '',
  //               created_date: data.user.metadata.creationTime,
  //               modified_date: data.user.metadata.lastSignInTime,
  //               phone_number: '',
  //               bio: '',
  //             }),
  //           });
  //           if (res.status === 200) {
  //             console.log('User profile data posted successfully');
  //           }
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       })
  //       .catch((error) => {
  //         const errorCode = error.code;
  //         console.log(errorCode);
  //         const errorMessage = error.message;
  //         console.log(errorMessage);
  //         const {
  //           customData: { email },
  //         } = error;
  //         const credential = FacebookAuthProvider.credentialFromError(error);
  //         console.log(credential);
  //       });
  //   }
  // };

  // const GoogleSignInComponent = () => {
  // signInWithPopup(auth, provider, email).then((data) => {
  //   navigate('/dashboard/survey', { replace: true });
  //   // localStorage.setItem('Auth Token', data._tokenResponse.refreshToken);
  //   // sessionStorage.setItem('Auth Token', data._tokenResponse.refreshToken);
  //   localStorage.setItem('uid', data.user.uid);
  //   localStorage.setItem('getuserid', data.user.uid);
  //   localStorage.setItem('email', data.user.email);
  //   localStorage.setItem('username', data.user.displayName);
  //   // const sessionId = 'unique-session-id';
  //   // Cookies.set('sessionId', sessionId, { expires: 0.5 });
  //   // localStorage.setItem('sessionInfo', JSON.stringify(data._tokenResponse.refreshToken));
  //   try {
  //     // const getuserid = localStorage.getItem('uid');
  //     const getuserid = localStorage.getItem('getuserid');
  //     const getemail = localStorage.getItem('email');
  //     const createusername = localStorage.getItem('username');

  //     const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/user.php', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         userid: getuserid,
  //         username: createusername,
  //         created_date: data.user.metadata.creationTime,
  //         modified_date: data.user.metadata.lastSignInTime,
  //         platform: 'Web',
  //         created_for: 'Personal',
  //         isFree: 'free',
  //         subscription_enddate: '',
  //         teams: '',
  //         last_login: data.user.metadata.lastSignInTime,
  //         email: getemail,
  //         active: 'true',
  //         loginwith: data.user.providerData[0].providerId,
  //       }),
  //     });
  //     if (res.status === 200) {
  //       console.log('user Data post  successfully');
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }

  //   try {
  //     const createusername = localStorage.getItem('username');
  //     const res = fetch('https://app.surveyslack.com/api/surveytask/api/v1/userprofile.php', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         userid: data.user.uid,
  //         username: createusername,
  //         first_name: data._tokenResponse.firstName,
  //         last_name: data._tokenResponse.lastName,
  //         profile_image: data.user.photoURL,
  //         created_date: data.user.metadata.creationTime,
  //         modified_date: data.user.metadata.lastSignInTime,
  //         phone_number: data.user.phoneNumber,
  //         bio: '',
  //       }),
  //     });
  //     // const resJson = await res.json();
  //     if (res.status === 200) {
  //       console.log(' user profile data post successfully');
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // });

  // };

  const handelfacebooklogin = () => {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithRedirect(auth, provider)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = provider.credentialFromResult(result);
        const { accessToken } = credential;
        const user = result.user;
        localStorage.setItem('Auth Token', user._tokenResponse.refreshToken);
        localStorage.setItem('user id:', user.uid);
        localStorage.setItem('email', user.email);
        sessionStorage.setItem('Access token', accessToken);
        navigate('/app/my-surveys', { replace: true });
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        const {
          customData: { email },
        } = error;
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(credential);
      });
  };

  const handleAppleLogin = () => {
    console.log('does not authenticate.');
  };

  function onChangeEmail(event) {
    setEmail(event.target.value);
    // const email = event.target.value;
    // checkformdata();
    // const setpassword = password===null?"":password;
    // const ischeckpsw = Confermpassword;
  }

  function onChangeFirstName(event) {
    setFirstName(event.target.value);
  }

  function onChangeLastName(event) {
    setLastName(event.target.value);
  }

  function onChangePassword(e) {
    const setpassword = e.target.value;
    setPassword(setpassword);
    // ValidationFunction(email,setpassword,Confermpassword)
    checkformdata();
  }

  function onCheckPassword(e) {
    const Confermpassword = e.target.value;
    setConfirmPassword(Confermpassword);
    const setpassword = password;
    ValidationFunction(email, Confermpassword, setpassword);
    checkformdata();
  }

  function ValidationFunction(email, setpassword, Confermpassword) {
    if (validator.isEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (
      validator.isStrongPassword(setpassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPasswordError('');
    } else {
      setPasswordError('Must contain Minimum 8 characters, one -  number, symbol , uppercase and lowercase letter.');
    }

    if (setpassword !== Confermpassword) {
      dispatch(showDiv());
      setConfirmPasswordstate(true);
    } else if (setpassword === Confermpassword) {
      setConfirmPasswordstate(false);

      // if (!checked) {
      //   setCheckPsw(true);
      // }
      if (Confermpassword === '') {
        setConfirmPasswordstate(false);
      }
    }
  }

  const checkTerms = (event) => {
    // checkformdata()
    setChecked(event.target.checked);
  };

  const loginformdata = [
    {
      commonbtntext: 'Join us for FREE and create unlimited surveys, questions & answers! Already have an account?',
      commonbtn: 'Back to Login',
      titletext: 'Create Account',
      toastAlert: ' Please Check your Email And Password !',
      loginbtn: 'Signup',
      maintitle: '',
      forgotbtn: 'Forgot password?',
      navLinkcommonbtn: { to: '/app/login' },

      handleChangeFirstName: onChangeFirstName,
      handleChangeLastName: onChangeLastName,
      onClick: onSignUp,
      handleChangeEmail: onChangeEmail,
      handleChangePassword: onChangePassword,
      handleCheckPassword: onCheckPassword,
      faceBook: handelfacebooklogin,
      apple: handleAppleLogin,
      googleLoginin: GoogleSignInComponent,
      // confermerror: 'Please check your password',

      ConfermPasswordError: confirmPasswordstate,
      ConfermEmail: 'Please enter valid Email.',
      passwordError: ispasswordError,
      ConfermPasswordValue: Confermpassword,
      emailvalidator: emailError,
      disable: isdisable,
      checkdata: checked,
      shake: isanimate,
      emailid: email,
      fname: firstName,
      lname: lastName,
      Password: password,
      handleChange: checkTerms,
    },
  ];

  return (
    <>
    <Helmet>
    <title>SurveySlack Login | Secure Access to Your Surveys</title>
    <meta
      name="description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta
      name="keywords"
      content="SurveySlack Login, Access SurveySlack, Online Survey Platform Login, online surveys, survey tool, feedback platform"
    />
    <meta name="robots" content="index, follow" />
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="language" content="English" />

    <meta property="og:url" content="https://app.surveyslack.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="SurveySlack Login | Secure Access to Your Surveys" />
    <meta
      property="og:description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta property="og:image" content="https://surveyslack.com/wp-content/uploads/2024/07/Featured-Image.jpg" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="app.surveyslack.com" />
    <meta property="twitter:url" content="https://app.surveyslack.com/" />
    <meta name="twitter:title" content="SurveySlack Login | Secure Access to Your Surveys" />
    <meta
      name="twitter:description"
      content="Tired of SurveyMonkey? Switch to SurveySlack Login for secure access to your surveys. Create surveys quickly and easily with our user-friendly platform."
    />
    <meta
      name="twitter:image"
      content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v4/Survey%20Slack/Streamline%20your%20survey%20creation%20process%20with%20ease./https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2F56188dc2-e3c3-4ce5-a8b1-1323953e37b9.jpg%3Ftoken%3DhOY-wLL-tV2Wb6eqlpzb3hUOqYMZbXQ3az2flBDqaSs%26height%3D800%26width%3D1200%26expires%3D33251249770/og.png"
    />
  </Helmet>
    <div className="auth-container">
      <div className="auth">
      {warningMessage && <AlertError message={warningMessage} />}
        <LoginForm loginformdata={loginformdata} />
      </div>
    </div>
    </>
  );
}
export default Signup;
