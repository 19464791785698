// final
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Button, TextField, IconButton, CircularProgress, Backdrop, Snackbar } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { borderRadius, padding } from '@mui/system';
import { showBackdropLoader, closeBackdroploader } from '../../Redux/actions'; // Adjust import based on your Redux setup
import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config'; // Adjust import based on your project structure
import '../../Style/AlertToolTip.css';
import AlertError from "./ToastMessages/ErrorPopup";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius:'4px',
  padding:'20px 35px',
  
};

// Function to get the browser details
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown Browser";

  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
    browserName = "Samsung Internet";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browserName = "Opera";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
  }

  return browserName;
};

// Function to get the screen resolution
const getScreenResolution = () => {
  return `${window.screen.width}x${window.screen.height}`;
};

function EmailModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [isBackdropOpen, setIsBackdropOpen] = useState(false); // State to manage backdrop loader
  const [alertOpen, setAlertOpen] = useState(false); // State to manage the alert
  const [warningMessage, setWarningMessage] = useState('');
  

  useEffect(() => {
    // Ensure modal closes if open is false externally (e.g., Redux state update)
    if (!open) {
      setMessage('');
    }
  }, [open]);

  const handleSend = async () => {
    if (!message || message === "") {
      setWarningMessage('Please enter a message');
      
      return; 
    }

    setWarningMessage(""); 
    setIsBackdropOpen(true); // Show backdrop loader when sending email
  
    const email = 'dipen.apptrait@gmail.com';
    const useremail = localStorage.getItem('email');
    const userid = localStorage.getItem('uid');
    const url = window.location.href;
    const browser = getBrowserInfo();
    const resolution = getScreenResolution();
    const date = new Date().toISOString();
    const setDescription = `${message}\nEmail ID: ${useremail}\nUser ID: ${userid}`;

    const payload = {
      userid,
      email,
      URL: url,
      browser,
      resolution,
      description: setDescription,
      date
    };
  
    // ?
    try {
      const response = await fetch(`${BASE_URL}${API_ENDPOINTS.sendEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('email',email);
        console.log('Mail sent successfully:', result);
        setIsBackdropOpen(false); // Hide backdrop loader on successful send
        setAlertOpen(true); // Open alert on successful email send
        handleClose(); // Close modal on successful send
        
        // Close the alert automatically after 2 seconds
        setTimeout(() => {
          setAlertOpen(false);
        }, 1000);
      } else {
        console.error('Failed to send mail:', result.message);
      }
    } catch (error) {
      console.error('Error sending mail:', error);
    } finally {
      setIsBackdropOpen(false); // Hide backdrop loader after send attempt
    }
  };

  


  const handleAlertClose = () => {
    setAlertOpen(false); // Close the alert
  };

  return (
    <>

<Backdrop
  sx={{
    color: '#fff',
    zIndex: (theme) => (isBackdropOpen ? theme.zIndex.drawer + 1 : 'inherit'),
  }}
  open={isBackdropOpen}
>
  <CircularProgress color="inherit" variant="indeterminate" />
</Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={6000} // Adjust as needed
        onClose={handleAlertClose}
        message="Email sent successfully"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="email-modal-title"
        aria-describedby="email-modal-description"
        style={{ zIndex: (theme) => (isBackdropOpen ? 2000 : 'auto')}}
      >
        <Box sx={{ ...style, opacity: isBackdropOpen ? 0.3: 1 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography id="email-modal-title" variant="h6" component="h2">
              Send Us Your Feedback
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="email-modal-description" sx={{ mt: 2 }}>
            Please describe your issues or feedback in the text area below:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Describe your issues or feedback here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mt: 2 }}
          />

          {warningMessage && <AlertError message={warningMessage} position="top-left" />}
          <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="contained" className='bgButton' onClick={handleSend}>
              Send
            </Button>
          </Box>

        </Box>
      </Modal>
    </>
  );
}

EmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EmailModal;


