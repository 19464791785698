import React, { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';
import '../../Style/Participantsdetails.css';

const ParticipantsDetails = () => {
  const [responder, setResponder] = useState([]);
  const [queslist, setQueslist] = useState([]);
  const [responses, setResponses] = useState([]);
  const [responderid, setResponderId] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const url = window.location.href;
  const resId = url.split('/').pop();
  const surveyId = localStorage.getItem('SURVEY_ID');

  const fetchSurveyList = async (resId) => {
    try {
      const endpoint = API_ENDPOINTS.responderdata;
      const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyId}`)
        .then((response) => response.json())
        .then((data) => {
          setResponder(data.responses);
          setResponderId(data.responses.map((getIDs) => getIDs.responderid));
          setCurrentIndex(data.responses.findIndex((getData) => getData.responderid === resId));
        });
      if (response === 200) {
        console.log('Survey data fetched successfully', response);
      }
    } catch (error) {
      console.error('Error fetching survey data:', error);
    }
  };

  const previousParticipantDetail = () => {
    if (currentIndex === null) {
      const index = responderid.indexOf(resId);
      if (index !== -1) {
        const newIndex = Math.max(0, index - 1);
        setCurrentIndex(newIndex);
        fetchSurveyList(responderid[newIndex]);
        fetchAnswers(responderid[newIndex]);
      }
    } else if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      fetchSurveyList(responderid[newIndex]);
      fetchAnswers(responderid[newIndex]);
    }
  };

  const nextParticipantDetail = () => {
    const index = responderid.indexOf(resId);
    if (index !== -1 && currentIndex < responderid.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      fetchSurveyList(responderid[newIndex]);
      fetchAnswers(responderid[newIndex]);
    } else if (index === -1 && currentIndex === null && responderid.length > 0) {
      // If resId is not found and currentIndex is null, move to the next participant
      const newIndex = 0;
      setCurrentIndex(newIndex);
      fetchSurveyList(responderid[newIndex]);
      fetchAnswers(responderid[newIndex]);
    }
  };
  
  useEffect(() => {
    fetchSurveyList(resId);
    fetchAnswers(resId);
  }, []);

  useEffect(() => {
    const previousButton = document.getElementById("previousButton");
    const nextButton = document.getElementById("nextButton");
  
    if (previousButton && nextButton) {
      previousButton.disabled = currentIndex === 0;
      nextButton.disabled = currentIndex === responderid.length - 1;
    }
  }, [currentIndex, responderid]);

  const fetchAnswers = async (resId) => {
    try {
      const endpoint = API_ENDPOINTS.getsurveyresponse;
      const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyId}`);
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.data && Array.isArray(responseData.data)) {
          const surveyData = responseData.data.filter((entry) => entry.responderid === resId);
          console.log("Survey Data:", surveyData); // Log the surveyData to check if it's correct
          setResponses(surveyData);
        } else {
          console.error('Fetched data is not in the expected format:', responseData);
        }
      } else {
        console.error(`Failed to fetch questions. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('An error occurred while fetching questions:', error);
    }
  };
  const renderLocalTime = (dateTimeString) => {
    const date = new Date(dateTimeString); // Parse string into Date object
    const utcTime = date.getTime(); // Get UTC time in milliseconds
    const offset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds for UTC +5:30
    const localTime = new Date(utcTime + offset); // Adjust to local time zone
    const year = localTime.getFullYear();
    const month = String(localTime.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(localTime.getDate()).padStart(2, '0'); // Add leading zero if needed
    const hours = String(localTime.getHours()).padStart(2, '0'); // Add leading zero if needed
    const minutes = String(localTime.getMinutes()).padStart(2, '0'); // Add leading zero if needed
    const seconds = String(localTime.getSeconds()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const endpoint = API_ENDPOINTS.questionListGET;
        const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyId}`);
        if (response.ok) {
          const data = await response.json();
          setQueslist(data.data);
        } else {
          console.error(`Failed to fetch questions. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while fetching questions:', error);
      }
    };
    fetchQuestions();
  }, [surveyId]);

  const filteredQuestionList = queslist.filter((survey) => {
    const filteredResponses = responses.filter((response) => response.questionid === survey.questionid);
    return filteredResponses.some((response) => response.response_text.trim() !== "");
  });

  return (
    <>
      <div>
        <NavLink to={`/app/survey/${surveyId}/result`}>
          <BiArrowBack style={{ fontSize: 'xx-large' }} />
        </NavLink>
      </div>
      {responder
  .filter((responderdata) => responderdata.responderid === resId)
  .map((responderdata, index) => (
    <div key={index} className="card text-center">
      <div className="card-header">Participant Details</div>
      <div className="card-body">
        <p className="card-text PraticipantDeatils">
          Email: {responderdata.responderemail} <br />
          Referrer: {responderdata.referrer} <br />
          Date Taken: {renderLocalTime(responderdata.created_at)} <br />
          Time Spent: {responderdata.time_spent} <br />
          OS: {responderdata.OS} <br />
          Browser: {responderdata.browser} <br />
          Location: {responderdata.location}
        </p>
      </div>
    </div>
  ))}

      {filteredQuestionList.map((question, index) => (
        <div className="card mb-3  responseDisplay" key={index}>
          <div className="row no-gutters  ">
            <div className="col-md-4  backgroundColor ">
              <div className="card-header displayQuestion  ">
                <h5 className="card-title ">Q{index + 1}</h5>
                <p>{question.question_text}</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card-body displayAnswer">
                <h5 className="card-title">Answer</h5>
                {responses.filter((response) => response.questionid === question.questionid)
                  .map((response, idx) => (
                    <p key={idx} className="card-text">
                      {response.response_text}
                    </p>
                  ))}
                  
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='paginationButton'>
        <Button id="previousButton" variant="contained" className='bgButton' onClick={previousParticipantDetail} disabled={currentIndex === 0}>Previous</Button>
        <Button id="nextButton" variant="contained" className='bgButton' onClick={nextParticipantDetail} disabled={currentIndex === responderid.length-1}>Next</Button>
      </div>
    </>
  );
};

export default ParticipantsDetails;
