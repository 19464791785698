import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ReactGA from "react-ga4";
import { BASE_URL } from '../api_folder/config';

export default function CloneSurvey() {
  const navigate = useNavigate();
  const { id, uid } = useParams(); // Extract survey_id and userid from URL
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);

    
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/template", title: "teamplate Page" });
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('uid');

    if (!userId) {
        // Set refid in localStorage if the user is not logged in
        localStorage.setItem('refid', id);
        
        // Redirect to login page with the current survey id as a query parameter
        navigate(`/login`);

        return;
      }
    const cloneSurvey = async () => {
        setLoading(true);
        const payload = { userid: userId, survey_id: id };
      
        try {
          console.log('Request payload:', payload);
      
          const response = await fetch(
            `${BASE_URL}/clonesurvey.php`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            }
          );
      
          console.log('Response status:', response.status);
      
          if (!response.ok) {
            const errorData = await response.json();
            console.error('Error data:', errorData);
            throw new Error(errorData.message || 'Failed to clone the survey');
          }
      
          const data = await response.json();
          console.log('Response data:', data);
      
          setResponseMessage(data.message || 'Survey cloned successfully!');
          localStorage.removeItem('refid');
          navigate(`/my-surveys`);
        } catch (error) {
          console.error('Fetch error:', error);
          setResponseMessage(`Error: ${error.message}`);
        } finally {
          setLoading(false);
        }
      };

    cloneSurvey();
  }, [id, uid, navigate]);

  return (
    <div>
         <Helmet>
        <title> Survey : surveyslack </title>
      </Helmet> 
      {/* <h1>Clone Survey</h1>
      <p>Reference Survey ID: {id}</p> */}
      {loading ?  (
          <div
            style={{
              position: 'absolute',
              width: '70%',
              height: '70%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            { <Box width={'100%'} className="align-items-center justify-content-center" sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box> }
          </div>
        )  : null}
    </div>
  );
}
