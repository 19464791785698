import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Typography, TextField, Button, Backdrop, Fade, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { closeAISurveyTitle, showAISurvey, setSurveyId,showBackdropLoader, closeBackdroploader } from '../../Redux/actions'; // Import actions for showing/hiding loader
import { BASE_URL } from '../../api_folder/config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AISurveyTitle = () => {
  const dispatch = useDispatch();
  const [surveytitle, setSurveytitle] = useState('');
  const [surveydes, setSurveydes] = useState('');
  const open = useSelector((state) => state.showAISurveyTitle);
  const isLoading = useSelector((state) => state.isLoading); // Assuming you have isLoading state in Redux

  const handeltitlechange = (e) => {
    setSurveytitle(e.target.value);
  };

  const handeldeschange = (e) => {
    setSurveydes(e.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    dispatch(showBackdropLoader()); 
    function generateId() {
      const id = Math.random().toString().slice(2, 7);
      return id;
    }

    const createID = generateId();

    function surveyPin() {
      const id = Math.random().toString().slice(2, 6);
      return id;
    }

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const createPin = surveyPin();

    try {
      const getuserid = localStorage.getItem('getuserid');
      const res = await fetch(`${BASE_URL}/surveylist.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userid: getuserid,
          surveyid: createID,
          survey_title: surveytitle,
          survey_display: "One Question at time",
          survey_description: surveydes,
          background: '',
          survey_label: '[]',
          survey_categories: "",
          email: 'None',
          public_survey: 0,
          survey_pin: createPin,
          disabled: 1,
          fonts: null,
          status: null,
          survey_enddate: currentDate.toISOString(),
        }),
      });

      if (res.status === 200) {
   
        dispatch(setSurveyId(createID));
        dispatch(showAISurvey());
        console.log('Survey Data posted successfully',createID);
     
        handleClose();
      }
      dispatch(closeBackdroploader()); // Hide backdrop loader after successful submission
    } catch (err) {
      console.log(err);
      dispatch(closeBackdroploader()); // Hide backdrop loader on error as well
    }
  };

  const handleClose = () => {
    dispatch(closeAISurveyTitle());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="h2">
              Create New Survey
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Survey Title"
            value={surveytitle}
            onChange={handeltitlechange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Survey Description"
            value={surveydes}
            onChange={handeldeschange}
            margin="normal"
            multiline
            rows={4}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={onSubmit}
              disabled={isLoading} 
              className='bgButton'
            >
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AISurveyTitle;
